import { store } from "../redux/store";
import { setIsPushAvailable } from "../redux/settings";
import { isIOS, isSafari, osVersion } from "react-device-detect";

const checkSafariVersion = () => {
  if (isIOS && isSafari) {
    const checkVersion = Number(osVersion) >= 16.4;
    // console.log("Number(osVersion)", Number(osVersion));
    return checkVersion;
  } else {
    return true;
  }
};

export const initIsPushAvailableCheck = () => {
  const dispatch = store.dispatch;
  // console.log("initIsPushAvailableCheck", checkSafariVersion());
  const top_level_check = process.env.NODE_ENV === "production" && "Notification" in window && checkSafariVersion();
  // console.log("top_level_check", top_level_check);
  dispatch(setIsPushAvailable(top_level_check));
  return top_level_check;
};

import { Divider, Typography } from "@mui/material";

export const SubGroup = ({ label, isBlock, isError, isBold }) => (
  <Divider
    textAlign="left"
    sx={{
      "&:before": { width: 16 }
    }}
  >
    <Typography color={!!isError ? isError : isBlock ? "secondary" : "primary"}>{isBold ? <strong>{label}</strong> : label}</Typography>
  </Divider>
);

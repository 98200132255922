import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendNewMessage,
  getChatUserMessages,
  setResetComponent,
  setReplyData,
  setNewMessageBlockHeight,
  createEntityAffix,
  setIsNeedToScrollToStart,
  sendMessagesViewed
} from "../../../redux/chat";
import { Divider, TextField, IconButton, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { checkToken } from "../../../utils/checkToken";

import { ReplyMessageBlock } from "../../../components/ReplyMessageBlock/ReplyMessageBlock";

export const NewMessageBlock = ({ is_comment }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { pm_partition, pub_partition } = useSelector((state) => state.settings);
  const { current_chat_user } = useSelector((state) => state.users);
  const { replyData, loading } = useSelector((state) => state.chat);

  const fieldRef = useRef(null);

  const [fetch, setFetch] = useState(false);

  const [val, setVal] = useState("");
  const handleVal = (e) => {
    setVal(e.target.value);
  };

  const updateMessages = async () => {
    dispatch(setResetComponent(true));
  };

  const createEntityComment = async () => {
    const req_data = {
      entity_uuid: replyData?.params?.entity?.assigned_entity_uuid,
      affix_type: "comment",
      params: {
        system: false,
        comment: val,
        entity: replyData?.params?.entity?.assigned_entity_type,
        reply: {
          actorUUID: current_chat_user?.uuid,
          actorName: `${current_chat_user?.first_name} ${current_chat_user?.last_name}`,
          text: replyData?.message
        }
      },
      sender: current_user?.user_uuid,
      receivers: [current_chat_user?.uuid]
    };
    // console.log("createEntityComment", req_data);
    const entity_data = new FormData();
    entity_data.append("data", JSON.stringify(req_data));
    const data_entity = {
      partition_uuid: replyData?.params?.entity?.partition === "PUBLIC" ? pub_partition?.header_uuid : pm_partition?.header_uuid,
      token: checkToken(current_user?.user_uuid, "entity"),
      req_data: entity_data
    };
    await dispatch(createEntityAffix(data_entity));
  };

  const createNotificationMessage = async (event_name) => {
    const params =
      event_name === "mention"
        ? {
            entity: {
              ...replyData?.params?.entity
            },
            reply: replyData
          }
        : { reply: replyData };
    const req_data = {
      event_name: event_name,
      title: event_name,
      message: val,
      params: {
        ...params
      },
      sender: current_user?.user_uuid,
      receivers: [current_chat_user?.uuid]
    };
    // console.log("createNotificationMessage data", req_data);
    const data_notify = {
      token: checkToken(current_user?.user_uuid, "notify"),
      req_data: req_data
    };
    await dispatch(sendNewMessage(data_notify)).then(async () => {
      setVal("");

      if (!!replyData) {
        const data = {
          token: checkToken(current_user?.user_uuid, "notify"),
          req_data: { messages: [replyData?.uuid] }
        };
        await dispatch(sendMessagesViewed(data));
      }
      updateMessages();
      dispatch(setReplyData(null));
      dispatch(setNewMessageBlockHeight(null));
      dispatch(setIsNeedToScrollToStart(true));
    });
  };

  const onReplySubmit = async () => {
    const checkIsCommentReply = replyData?.params?.entity?.assigned_entity_uuid;
    if (checkIsCommentReply) {
      createEntityComment();
    }
    await createNotificationMessage(checkIsCommentReply ? "mention" : "pwa_message");
  };

  const onSubmit = async () => {
    if (!fetch && !loading) {
      setFetch(true);
      try {
        if (!!replyData) {
          await onReplySubmit();
        } else {
          await createNotificationMessage("pwa_message");
        }
      } catch (e) {
        console.log("e");
      } finally {
        setFetch(false);
      }
    }
  };

  const handleChangeFieldHeight = () => {
    if (!!fieldRef?.current?.offsetHeight) {
      const height = fieldRef?.current?.offsetHeight - 33;
      if (height) {
        dispatch(setNewMessageBlockHeight(height));
      } else {
        dispatch(setNewMessageBlockHeight(null));
      }
    }
  };

  const handleSubmit = (e) => {
    const checkVal = !!e?.target?.value?.trim()?.length;
    if (e.code === "Enter" && checkVal) {
      onSubmit();
    }
    handleChangeFieldHeight();
  };

  const handleOnPaste = (e) => {
    // console.log("handleOnPaste");
    handleChangeFieldHeight();
  };

  // useEffect(() => {
  //   console.log("replyData", replyData);
  // }, [replyData]);

  return (
    <>
      <div className="send_message_block">
        <ReplyMessageBlock />
        {is_comment && <Divider />}
        {/* <Divider sx={{ margin: "0 -16px" }} /> */}
        <div style={{ padding: "3px 8px 4px 8px" }}>
          <TextField
            // onFocus={handleShowKeyboard}
            // onBlur={handleHideKeyboard}
            onPaste={handleOnPaste}
            // onInput={handleOnInput}
            ref={fieldRef}
            multiline={true}
            maxRows={4}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder="Message"
            inputProps={{
              maxLength: 400,
              style: {
                fontSize: "20px"
              }
            }}
            onKeyUp={handleSubmit}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <LoadingButton
                  loading={fetch || loading}
                  disabled={!val?.trim() || fetch || loading}
                  sx={{ marginLeft: "6px", minWidth: "24px", padding: "0 0" }}
                  onClick={onSubmit}
                >
                  <SendIcon />
                </LoadingButton>
              )
            }}
            // sx={{ height: "48px" }}
            value={val}
            onChange={handleVal}
          />
        </div>
        {/* {!is_comment && } */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "8px",
            height: "24px",
            backgroundColor: "whitesmoke",
            color: "#1976d2"
          }}
        >
          {val.trim()?.length} / 400
        </div>
        {/* <Button
          fullWidth
          sx={{ position: "absolute", bottom: !!val?.trim() ? "0" : "-40px", height: "24px", borderRadius: "0 0", zIndex: "10" }}
          variant="contained"
          color="neutral"
        >
          Clear all
        </Button> */}
      </div>
    </>
  );
};

import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";

import { TextField, ListItem, Typography, IconButton, CircularProgress } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SaveIcon from "@mui/icons-material/Save";
import LaunchIcon from "@mui/icons-material/Launch";
import "./index.css";

export const FormTextFieldUrl = ({ fieldLabel, fieldName, isDisabled, isAnyOffices, handleOpenScaner, handleShowOfficesModal, fetchUrlCheck }) => {
  const {
    register,
    getValues,
    setError,
    formState: { errors }
  } = useFormContext();

  const [isAnimate, setIsAnimate] = useState(true);

  const handleClickError = () => {
    // console.log("handleClickError");
    try {
      if (errors?.[`${fieldName}`]?.message.includes("url")) {
        const url = getValues(fieldName);
        window.open(url);
      }
    } catch (e) {
      setError("url", { message: "Invalid url" });
      console.log("e", e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsAnimate(false);
    }, 3000);
  }, []);

  return (
    <>
      <Typography variant="subtitle2">{fieldLabel}</Typography>
      <ListItem key={`${fieldName}_backup`} disablePadding sx={{ marginBottom: "16px", position: "relative" }}>
        <TextField
          multiline={true}
          minRows={1}
          maxRows={6}
          autoFocus={true}
          type="url"
          disabled={isDisabled}
          name={`${fieldName}`}
          {...register(`${fieldName}`)}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <>
                {/* {isAnyOffices && (
                  <IconButton onClick={handleShowOfficesModal}>
                    {<FormatListBulletedIcon color={"primary"} />}
                  </IconButton>
                )} */}

                <IconButton onClick={handleOpenScaner} className={isAnimate ? "border-button active" : "border-button"}>
                  {<QrCodeScannerIcon color="primary" />}
                </IconButton>
              </>
            )
          }}
          error={errors?.[`${fieldName}`]}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: `12px`
              }
            }
          }}
          variant="standard"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: "-32px",
            left: "0",
            fontSize: "14px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: !fetchUrlCheck ? "#d32f2f" : "gray",
              transition: ".3s ease-in-out"
            }}
            onClick={() => (!fetchUrlCheck ? handleClickError() : "")}
          >
            {errors?.[`${fieldName}`]?.message} {errors?.[`${fieldName}`]?.message.includes("url") && <LaunchIcon sx={{ marginLeft: "4px" }} />}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: "-32px",
            right: "0",
            fontSize: "14px",

            zIndex: "5"
          }}
        >
          <div
            style={{
              width: "100%",
              paddingRight: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            {fetchUrlCheck && <CircularProgress size={24} color="neutral" />}
          </div>
        </div>
      </ListItem>
    </>
  );
};

// -----------   react, redux ----------------------------------//
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// -----------   redux methods ----------------------------------//
import { getPublicEntityUuid, getPublicProjectStorageUuid, getWeekPlanningUniqueUuid } from '../../redux/settings';
import {
  getWeekVectorsUuids,
  createDateVector,
  setLoadingIssues,
  setSelectedDay,
  setSelectedDayUuid,
  setCurrentWeekDayOptions,
  setSyncData,
} from '../../redux/weekPlanning';
import {
  getWeekVectors,
  getDateVectorIssuesUuids,
  getDateVectorIssues,
  setPeriodSelectedDay,
  setPeriodDaysData,
} from '../../redux/weekPlanning';

// -----------   custom_components ----------------------------------//
import { Page } from './Page';
import { Modal } from './Modal';

import { ModalAccounts } from '../../components/ModalAccounts/ModalAccounts';
import { ScannerComponent } from '../../components/Scaner/ScannerComponent';
import FullScreenDialog from '../../components/FullScreenDialog/FullScreenDialog';
import { OpenModalBtn } from '../UsersPage/OpenModalBtn';

// -----------   components ----------------------------------//
import { CircularProgress } from '@mui/material';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';

// -----------   icons ----------------------------------//
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// -----------   utils, const ----------------------------------//
import dayjs from 'dayjs';
import { checkToken } from '../../utils/checkToken';
import { createMD5 } from '../../utils/createMD5';
import { scannerType } from '../../utils/constants';
import { detectDayNum } from '../../utils/detectDayNum';
import { orderBy } from 'lodash';
import { openNotify } from '../../components/OpenNotify/openNotify';
import { weekPlanningActions } from '../../Socket/weekPlanningActions';
import { isMobile } from 'react-device-detect';
import { handleResetWmsTokens } from '../../utils/handleResetWmsTokens';

export const WeekPlanningPage = () => {
  const dispatch = useDispatch();
  const daysTitlesArr = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const { current_user } = useSelector((state) => state.auth);
  const { selected_day, openPeriodModal, period_selected_day, period_days_data, sync_data, current_week_day_options } =
    useSelector((state) => state.weekPlanning);
  const { pub_partition, week_planning_unique_uuid } = useSelector((state) => state.settings);

  const entity_token = checkToken(current_user?.user_uuid, 'entity');

  const [fetching, setFetching] = useState(true);
  const [pageFetching, setPageFetching] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [openAccDialog, setOpenAccDialog] = useState(false);

  // const [openSyncModal, setOpenSyncModal] = useState(false);
  // const [resetToken, setResetToken] = useState(false);

  // const syncModalText = "The app has been inactive for a while, download the latest data for the current page?";

  const [swiper, setSwiper] = useState(null);

  const today = dayjs();
  const todaySlideNumber = detectDayNum(today);
  const [initialSlideNumber, setInitialSlideNumber] = useState(todaySlideNumber);
  // const [periodOldDate, setPeriodOldDate] = useState(today);
  // const [fetchingPeriod, setFetchingPeriod] = useState(true);

  const handleEndSlidesOptions = (periodSelectedDate) => {
    return {
      prevWeekStart: periodSelectedDate?.subtract(7, 'day').day(1).format('DD-MM-YYYY'),
      prevWeekEnd: periodSelectedDate?.subtract(7, 'day').day(7).format('DD-MM-YYYY'),
      nextWeekStart: periodSelectedDate?.add(7, 'day').day(1).format('DD-MM-YYYY'),
      nextWeekEnd: periodSelectedDate?.add(7, 'day').day(7).format('DD-MM-YYYY'),
    };
  };

  const defaultOptions = handleEndSlidesOptions(period_selected_day);

  const [endSlidesOptions, setEndSlidesOptions] = useState(defaultOptions);

  const [oldSlideIdx, setOldSlideIdx] = useState(todaySlideNumber);

  // const [currentWeekDatesOptions, setCurrentWeekDatesOptions] = useState([]);
  // const [currentWeekDayOptions, setCurrentWeekDayOptions] = useState([]);

  const [dateButtonsOptions, setDateButtonsOptions] = useState([]);

  //---------------------------------------------------------------//
  // -----------   get uniq uuid ----------------------------------//
  //---------------------------------------------------------------//

  const uuid_1_func = async () => {
    const req_data = {
      token: checkToken(current_user?.user_uuid, 'entity'),
      partition_uuid: pub_partition?.header_uuid,
    };
    const resp = await dispatch(getPublicEntityUuid(req_data)).unwrap();
    return resp?.uuid;
  };

  const uuid_2_func = async (uuid) => {
    const req_data = {
      token: checkToken(current_user?.user_uuid, 'entity'),
      partition_uuid: pub_partition?.header_uuid,
      data: {
        entity_type: 'publicProjectsStorage',
        parent: uuid,
        key: createMD5('publicProjectsStorage' + uuid),
      },
    };
    const resp = await dispatch(getPublicProjectStorageUuid(req_data)).unwrap();
    return resp?.uuid;
  };

  const uuid_3_func = async (uuid) => {
    const req_data = {
      token: checkToken(current_user?.user_uuid, 'entity'),
      partition_uuid: pub_partition?.header_uuid,
      data: {
        entity_type: 'publicProjectsStorage',
        parent: uuid,
        key: createMD5(uuid + 'projectACTIVITIES_USER'),
        params: { title: 'WEEKLY PLANNING', type: 'ACTIVITIES_USER', status: 'systemic' },
      },
    };
    const resp = await dispatch(getWeekPlanningUniqueUuid(req_data)).unwrap();

    return resp?.uuid;
  };

  const initFunc = async () => {
    if (!week_planning_unique_uuid) {
      const uuid_1 = await uuid_1_func();
      const uuid_2 = await uuid_2_func(uuid_1);
      const uuid_3 = await uuid_3_func(uuid_2);
      setFetching(false);
      setTimeout(() => {
        setPageFetching(false);
      }, 300);
      // console.log("uuid_3", uuid_3);
    } else {
      setFetching(false);
      setPageFetching(false);
    }
  };

  // const handleFocusWindow = () => {
  //   if (week_planning_unique_uuid && period_selected_day && pub_partition?.header_uuid && current_user?.user_uuid) {
  //     setTimeout(() => {
  //       setOpenSyncModal(true);
  //     }, 500);
  //   }
  // };

  // const handleSyncModalCallback = (bool) => {
  //   if (bool) {
  //     initPeriodFunc();
  //   }
  // };

  const initFuncWrapper = async (resetTokens) => {
    try {
      await initFunc();
    } catch (e) {
      const stringError = JSON.stringify(e);
      const checkFor401 = stringError.includes('unauthorized') || stringError.includes('401');
      if (checkFor401 && !resetTokens) {
        await handleResetWmsTokens(current_user).then(() => {
          // console.log('initFuncWrapper');
          setTimeout(() => {
            initFuncWrapper(true);
          }, 250);
        });
      }
    }
  };

  useEffect(() => {
    // console.log('pub_partition?.header_uuid', pub_partition?.header_uuid);
    if (pub_partition?.header_uuid) {
      initFuncWrapper();
    }
  }, [pub_partition?.header_uuid, current_user?.user_uuid]);

  // useEffect(() => {
  //   if (week_planning_unique_uuid && period_selected_day && pub_partition?.header_uuid && current_user?.user_uuid && isMobile) {
  //     window.addEventListener("focus", handleFocusWindow);
  //   }
  // }, [week_planning_unique_uuid, period_selected_day, pub_partition?.header_uuid, current_user?.user_uuid]);

  //---------------------------------------------------------------//
  // -----------  get week dates data -----------------------------//
  //---------------------------------------------------------------//

  const handleCreateWeekDatesvectors = async () => {
    const req_body_arr = daysTitlesArr.map((item, idx) => ({
      entity_type: 'vector',
      parent: week_planning_unique_uuid,
      params: {
        status: 'created',
        date: period_selected_day.day(idx + 1).format('YYYY-MM-DD'),
        title: `Day(${period_selected_day.day(idx + 1).format('YYYY-MM-DD')})`,
      },
    }));
    const vector_uuids = await Promise.all(
      daysTitlesArr.map(async (item, idx) => {
        const data = {
          partition_uuid: pub_partition?.header_uuid,
          token: entity_token,
          req_body: req_body_arr[idx],
        };
        const resp = await dispatch(createDateVector(data)).unwrap();
        return resp?.uuid;
      })
    );
    // console.log("create vectors", vector_uuids);
    return vector_uuids;
  };

  const handleGetVectorsUuids = async (date__gte, date__lte) => {
    const req_data = {
      token: entity_token,
      partition_uuid: pub_partition?.header_uuid,
      req_body: {
        entity_type: 'vector',
        params: { date__gte: date__gte, date__lte: date__lte },
        params_fields: { date: 'date' },
        parent: week_planning_unique_uuid,
      },
    };
    const resp = await dispatch(getWeekVectorsUuids(req_data)).unwrap();
    if (!resp?.length && !!entity_token) {
      return handleCreateWeekDatesvectors();
    } else {
      return resp;
    }
  };

  const handleGetVectors = async (entity_uuids) => {
    const req_body = {
      entity_type: 'vector',
      entity_uuids: entity_uuids,
      params_fields: { date: 'date' },
      total: 7,
    };
    const req_data = {
      token: entity_token,
      partition_uuid: pub_partition?.header_uuid,
      req_body: req_body,
    };
    const resp = await dispatch(getWeekVectors(req_data)).unwrap();
    return resp;
  };

  const handleGetIssueData = async (issues_uuids) => {
    const req_body = {
      entity_type: 'issue',
      entity_uuids: issues_uuids,
      order: 'asc',
      order_by_params: 'weight',
      params_fields: { date: 'date', title: 'title', completed: 'completed', weight: 'weight' },
      total: issues_uuids?.length,
    };
    const data = {
      req_body: req_body,
      token: entity_token,
      partition_uuid: pub_partition?.header_uuid,
    };
    // console.log("initFunc data", data);
    if (issues_uuids?.length) {
      const resp = await dispatch(getDateVectorIssues(data)).unwrap();
      return resp;
    } else {
      return [];
    }
  };

  const handleGetVectorIssues = async (vector, update) => {
    try {
      const req_body = {
        entity_type: 'issue',
        order: 'asc',
        order_by_params: 'weight',
        params_fields: { date: 'date', title: 'title', completed: 'completed', weight: 'weight' },
        parent: vector?.uuid,
      };
      const req_data = {
        token: entity_token,
        partition_uuid: pub_partition?.header_uuid,
        req_body: req_body,
      };
      const resp_issues_uuids = await dispatch(getDateVectorIssuesUuids(req_data)).unwrap();
      const resp_issues_data = await handleGetIssueData(resp_issues_uuids);
      const finalData = { ...vector, issues: resp_issues_data };
      // if (update) {
      //   setCurrentDateOptions(finalData);
      //   setDateOptions((prev) => prev.map((item) => (item?.uuid === finalData?.uuid ? finalData : item)));
      // }
      return finalData;
    } catch (e) {
      console.log('e', e);
    } finally {
    }
  };

  const handleGetVectorsIssues = async (date_vectors) => {
    const vectors_with_issues = await Promise.all(
      date_vectors.map(async (vector, idx) => await handleGetVectorIssues(vector))
    );
    return vectors_with_issues;
  };

  const handleGetUniqVector = (vectors_with_issues) => {
    try {
      const uniqArr = [];
      const arr = vectors_with_issues.map((day) => {
        const isAlreadyInArr = uniqArr.some((uniq_day) => uniq_day?.date === day?.date);
        if (!isAlreadyInArr) {
          uniqArr.push(day);
        }
      });
      return uniqArr;
    } catch (e) {
      console.log('e', e);
    }
  };

  const initPeriodFuncContent = async () => {
    setFetching(true);
    const date__gte = period_selected_day.day(1).format('YYYY-MM-DD');
    const date__lte = period_selected_day.day(7).format('YYYY-MM-DD');
    const entity_uuids = await handleGetVectorsUuids(date__gte, date__lte);
    const date_vectors = await handleGetVectors(entity_uuids);
    const vectors_with_issues = await handleGetVectorsIssues(date_vectors);
    const newData = handleGetUniqVector(vectors_with_issues)
      .map((day) => ({ ...day, date: dayjs(day?.date) }))
      .slice(0, 7)
      .sort((day1, day2) => day1?.date.diff(day2?.date));
    dispatch(setPeriodDaysData(newData));
    const newCurrentDateOptions = newData.find((day, idx) => {
      return day?.date?.format('YYYY-MM-DD') === selected_day?.format('YYYY-MM-DD');
    });
    dispatch(setCurrentWeekDayOptions(newCurrentDateOptions));
    const currentDayNum = detectDayNum(newCurrentDateOptions?.date);
    setInitialSlideNumber(currentDayNum);
  };

  const initPeriodFunc = async (resetTokens) => {
    try {
      await initPeriodFuncContent();
    } catch (e) {
      console.log('e', e);
      const stringError = JSON.stringify(e);
      const checkFor401 = stringError.includes('unauthorized') || stringError.includes('401');
      if (checkFor401 && !resetTokens) {
        await handleResetWmsTokens(current_user).then(() => {
          // console.log('initPeriodFunc reset token');
          setTimeout(() => {
            initPeriodFunc(true);
          }, 250);
        });
      }
    } finally {
      setTimeout(() => {
        setFetching(false);
      }, 200);
    }
  };

  const handlePeriodSelectedDate = (val) => {
    const check = val.day() !== 0;
    let newDay;
    if (check) {
      newDay = val;
      dispatch(setPeriodSelectedDay(newDay));
      // setPeriodSelectedDate(newDay);
    } else {
      newDay = val.subtract(1, 'day');
      // console.log("handlePeriodSelectedDate newDay", newDay?.format("ddd - DD/MM/YYYY"));
      dispatch(setPeriodSelectedDay(newDay));
      // setPeriodSelectedDate(newDay);
    }
    // console.log("handlePeriodSelectedDate newDay", newDay?.format("ddd - DD/MM/YYYY"));
    // console.log("handlePeriodSelectedDate val", val);
    // handleChangeCurrentDateOptions(val);
  };

  const handlePreviousWeek = (dayCount) => {
    const prevWeekDate = selected_day.subtract(dayCount || 1, 'day');
    // console.log("prevWeekDay", prevWeekDate.format("ddd / DD-MM-YYYY"));
    handlePeriodSelectedDate(prevWeekDate);
    // const prevWeekDay = selected_day.subtract(1, "day");
    // console.log("prevWeekDay", prevWeekDay.format("YYYY-MM-DD"));
    dispatch(setSelectedDay(prevWeekDate));
    // console.log("detectDayNum(prevWeekDate)", detectDayNum(prevWeekDate));
    setInitialSlideNumber(detectDayNum(prevWeekDate));
    setOldSlideIdx(detectDayNum(prevWeekDate));
  };

  const handleNextWeek = (dayCount) => {
    const nextWeekDate = selected_day.add(dayCount || 1, 'day');
    // console.log("nextWeekDate", nextWeekDate.format("YYYY-MM-DD"));
    handlePeriodSelectedDate(nextWeekDate);
    // const nextWeekDay = selected_day.add(1, "day");
    // console.log("nextWeekDay", nextWeekDay.format("YYYY-MM-DD"));
    dispatch(setSelectedDay(nextWeekDate));
    setInitialSlideNumber(detectDayNum(nextWeekDate));
    setOldSlideIdx(detectDayNum(nextWeekDate));
  };

  const initPeriodFuncWrapper = async () => {
    if (week_planning_unique_uuid) {
      if (!selected_day) {
        dispatch(setSelectedDay(dayjs()));
      } else {
        dispatch(setPeriodDaysData([]));
        // setCurrentWeekDatesOptions([]);
        initPeriodFunc();
        setEndSlidesOptions(handleEndSlidesOptions(period_selected_day));
        handleDateButtonsOptions(period_selected_day);
        // const checkIsNewPeriod = periodOldDate.format("YYYY-MM-DD") !== periodSelectedDate.format("YYYY-MM-DD");
        // if (checkIsNewPeriod) {
        //   setPeriodOldDate(periodSelectedDate);
        // }
      }
    }
  };

  useEffect(() => {
    initPeriodFuncWrapper();
  }, [week_planning_unique_uuid, period_selected_day, entity_token]);

  useEffect(
    () => () => {
      // console.log("useEffect");
      dispatch(setPeriodDaysData([]));
      // setCurrentWeekDatesOptions([]);
      dispatch(setPeriodSelectedDay(today));
      // setPeriodSelectedDate(today);
      dispatch(setSelectedDay(today));
      setInitialSlideNumber(todaySlideNumber);
      setOldSlideIdx(todaySlideNumber);
      // window.removeEventListener("focus", handleFocusWindow);
    },
    []
  );

  //---------------------------------------------------------------//
  // ----------- swipe function -------------------------------------
  //---------------------------------------------------------------//

  const handleSwipe = (e) => {
    if (!fetching) {
      // console.log(">>>>>>>>>> handleSwipe <<<<<<<<<<<<<<<<<<");

      // console.log("e?.realIndex", e?.realIndex);
      // console.log("e?.activeIndex", e?.activeIndex);
      if (e?.activeIndex === 0) {
        handlePreviousWeek();
        return;
      }
      if (e?.activeIndex === 8) {
        handleNextWeek();
        return;
      }
      const slide_idx = e?.activeIndex;
      const diff = oldSlideIdx - slide_idx;
      // console.log("oldSlideIdx", oldSlideIdx);
      // console.log("slide_idx", slide_idx);
      // console.log("oldSlideIdx", oldSlideIdx);
      // console.log("slide_idx", slide_idx);
      if (diff !== 0) {
        const slide_direction = diff < 0 ? 'next' : 'prev';
        let newDay;

        if (slide_direction === 'next') {
          newDay = selected_day.add(Math.abs(diff), 'day');
          // console.log("next", newDay);
          dispatch(setSelectedDay(newDay));
        }
        if (slide_direction === 'prev') {
          newDay = selected_day.subtract(Math.abs(diff), 'day');
          // console.log("prev", newDay);
          dispatch(setSelectedDay(newDay));
        }
        // console.log("newDay", newDay?.format("dd - DD/MM/YYYY"));
      } else {
      }

      setOldSlideIdx(slide_idx);
    }
  };

  const handleDateButtonsOptions = (currentDate) => {
    const newOptions = daysTitlesArr.map((item, idx) => currentDate.day(idx + 1));
    setDateButtonsOptions(newOptions);
  };

  const handleSwipeToSlide = (dayObj) => {
    // console.log("dayObj", dayObj);
    let index;
    period_days_data?.forEach((dateOptions, idx) => {
      const check = dateOptions?.date?.format('YYYY-MM-DD') === dayObj?.date?.format('YYYY-MM-DD');
      if (check) {
        index = idx + 1;
      }
    });
    dispatch(setSelectedDay(dayObj?.date));
    dispatch(setSelectedDayUuid(dayObj?.uuid));
    setOldSlideIdx(index);
    swiper.slideTo(index, 200, false);
  };

  //---------------------------------------------------------------//
  // -----------   open modals --------------------------------------
  //---------------------------------------------------------------//

  const handleOpenSignInScaner = () => {
    setOpenDialog(true);
    setOpenAccDialog(false);
  };

  const handaleOpenAccountsModal = () => {
    setOpenAccDialog(true);
  };

  const updatePeriod = (parent_date, newDayData, withoutNotification) => {
    // console.log("newDayData", newDayData);
    const finalNewDayData = { ...newDayData, issues: orderBy(newDayData?.issues, ['weight'], ['asc']) };
    const newPeriodDaysData = period_days_data
      .slice(0)
      .map((item) => (item?.date?.format('YYYY-MM-DD') === parent_date ? finalNewDayData : item));
    const checkIsCurrentDayUpdate = parent_date === selected_day?.format('YYYY-MM-DD');
    if (checkIsCurrentDayUpdate) {
      // console.log("current_week_day_options", current_week_day_options);
      // console.log("newDayData", newDayData);
      // dispatch(setCurrentWeekDayOptions(newDayData));
    }
    dispatch(setPeriodDaysData(newPeriodDaysData));
    dispatch(setSyncData(null));
    if (!withoutNotification) {
      openNotify('info', 'Synchronization has been completed.');
    }
  };

  const handleWeekPlanningSync = async (dayData, withoutNotification) => {
    const { parent_date, action_type, data } = dayData;
    const day_for_update = period_days_data?.find((item) => item?.date?.format('YYYY-MM-DD') === parent_date);
    switch (action_type) {
      case weekPlanningActions.create: {
        // console.log("create data[0]", data[0]);
        const oldActivitiesArr = day_for_update?.issues?.slice(0);
        const newActivitiesArr = [...oldActivitiesArr, data[0]];
        // console.log("create newActivitiesArr", newActivitiesArr);
        const newDayData = { ...day_for_update, issues: newActivitiesArr };
        // console.log("create newDayData", newDayData);
        updatePeriod(parent_date, newDayData, withoutNotification);
        break;
      }
      case weekPlanningActions.update: {
        const oldActivitiesArr = day_for_update?.issues?.slice(0);
        const newActivitiesData = oldActivitiesArr?.map((activity) => {
          const newActivityData = data?.find((newActivity) => newActivity?.uuid === activity?.uuid) || {};
          return { ...activity, ...newActivityData };
        });
        const newDayData = { ...day_for_update, issues: newActivitiesData };
        updatePeriod(parent_date, newDayData, withoutNotification);
        break;
      }
      case weekPlanningActions.delete: {
        // console.log("delete data[0]", data[0]);
        const oldActivitiesArr = day_for_update?.issues?.slice(0);
        const newActivitiesArr = oldActivitiesArr.filter((issue) => issue?.uuid !== data[0]?.uuid);
        // console.log("delete newActivitiesArr", newActivitiesArr);
        const newDayData = { ...day_for_update, issues: newActivitiesArr };
        updatePeriod(parent_date, newDayData, withoutNotification);
        break;
      }
      case weekPlanningActions.duplicate: {
        const oldPeriodData = period_days_data.slice(0);
        const newPeriodData = oldPeriodData.map((dayOptions) => {
          const checkIssues = data?.find((issue) => issue?.date === dayOptions?.date?.format('YYYY-MM-DD'));
          if (checkIssues) {
            return { ...dayOptions, issues: [...dayOptions?.issues, checkIssues] };
          } else {
            return dayOptions;
          }
        });
        dispatch(setPeriodDaysData(newPeriodData));
        dispatch(setSyncData(null));
        if (!withoutNotification) {
          openNotify('info', 'Synchronization has been completed.');
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    if (!!sync_data?.action_type) {
      handleWeekPlanningSync(sync_data);
      // console.log("sync_data", sync_data);
    }
  }, [sync_data]);

  return (
    <>
      {pageFetching ? (
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '6px',
            left: '0',
            right: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: pageFetching ? '100' : '-2',
            background: 'white',
          }}
        >
          <CircularProgress sx={{ position: 'fixed', top: 'calc(50vh - 20px)' }} />
        </div>
      ) : (
        <>
          <Page
            initialSlideNumber={initialSlideNumber}
            setSwiper={setSwiper}
            // currentWeekDatesOptions={currentWeekDatesOptions}
            // currentWeekDayOptions={currentWeekDayOptions}
            handleSwipe={handleSwipe}
            endSlidesOptions={endSlidesOptions}
            fetching={fetching}
            handleWeekPlanningSync={handleWeekPlanningSync}
          />
          <OpenModalBtn setOpen={handaleOpenAccountsModal} icon={<PeopleAltIcon />} />
          <Modal
            // currentWeekDatesOptions={currentWeekDatesOptions}
            dateButtonsOptions={dateButtonsOptions}
            handlePeriodSelectedDate={handlePeriodSelectedDate}
            handlePrevWeek={handlePreviousWeek}
            handleNextWeek={handleNextWeek}
            handleSwipeToSlide={handleSwipeToSlide}
          />
          <FullScreenDialog
            keepMounted={true}
            children={<ScannerComponent type={scannerType.sign_in} callbackClose={setOpenDialog} />}
            open={openDialog}
            setOpen={setOpenDialog}
          />
          <ModalAccounts
            showModal={openAccDialog}
            setShowModal={setOpenAccDialog}
            handleOpenSignInScaner={handleOpenSignInScaner}
          />
          {/* <ConfirmModal open={openSyncModal} setOpen={setOpenSyncModal} callback={handleSyncModalCallback} text={syncModalText} /> */}
        </>
      )}
    </>
  );
};

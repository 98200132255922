import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PolylineIcon from "@mui/icons-material/Polyline";

import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DescriptionIcon from "@mui/icons-material/Description";

import BorderAllIcon from "@mui/icons-material/BorderAll";
import FlagIcon from "@mui/icons-material/Flag";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

export const entity_data_types = {
  project: {
    title: "project",
    icon: <AssignmentIcon />
  },
  vector: {
    title: "vector",
    icon: <PolylineIcon />
  },
  issue: {
    title: "issue",
    icon: <AccountTreeIcon />
  },
  projectIdea: {
    title: "idea",
    icon: <TipsAndUpdatesIcon />
  },
  projectDocument: {
    title: "document",
    icon: <DescriptionIcon />
  },
  projectSpreadsheet: {
    title: "spreadsheet",
    icon: <BorderAllIcon />
  },
  projectGoal: {
    title: "goal",
    icon: <CrisisAlertIcon />
  }
};

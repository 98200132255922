import { store } from "../../redux/store";
import { getClassicSession } from "../../utils/getClassicSession";
import { getActorData, aboutInfoRequest, addNewUser } from "../../redux/auth";
import { saveToken } from "../../utils/saveToken";

export const handleClassicSignIn = async (url, email, md5_password) => {
  const dispatch = store.dispatch;

  const resp_token = await getClassicSession(url, email, md5_password);
  const req_data = {
    url: url,
    token: resp_token?.session_token
  };
  // console.log("req_data", req_data);
  const resp_data = await dispatch(getActorData(req_data)).unwrap();
  const about_data = await dispatch(aboutInfoRequest({ url: `${url}/about/` })).unwrap();
  const resp_actor = resp_data?.actor;
  const uinfo = resp_actor?.uinfo;
  const newCurrentUserData = {
    user_uuid: resp_actor?.uuid,
    user_priv_key: null,
    password: md5_password,
    actor_type: resp_actor.actor_type,
    created: resp_actor?.created,
    root: false,
    email: uinfo?.email,
    first_name: uinfo?.first_name,
    last_name: uinfo?.last_name,
    groups: Array.isArray(uinfo?.groups) ? uinfo?.groups : [],
    biom: {
      biom_uuid: about_data?.biom_uuid,
      biom_domain: about_data?.biom_domain,
      biom_name: about_data?.biom_name
    }
    // services_uuid: [about_data?.service_uuid]
  };
  saveToken(resp_token?.session_token, resp_token?.expiration, resp_actor?.uuid, about_data?.service_uuid);
  dispatch(addNewUser(newCurrentUserData));
  return newCurrentUserData;
};

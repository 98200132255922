import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { notify_url } from "../config/url";
import { entity_url } from "../config/url";

export const filterDialogTypesArr = ["projects", "issues"];

export const assingedEntityTypes = {
  project: "project",
  entityType: "entityType",
  issue: "issue"
};

export const getAllUsersMessages = createAsyncThunk("chat/getAllMessages", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  // console.log("getAllUsersMessages");
  const { partition_uuid, token } = props;

  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().get(`${notify_url}/notification?&order=created_at_desc&limit=100&offset=0`, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // alert("getAllUsersMessages");
    // console.log("getAllUsersMessages response?.data?.result", response?.data?.result);
    return response?.data?.result;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getChatUserMessages = createAsyncThunk("chat/getChatUserMessages", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { basic_filter_uuids, token, sender_uuid, limit, offset, entity_uuid, project_uuid, message_text } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);
    // sender_uuid=${sender_uuid}
    const basic_filter_string = `&basic_uuid=${basic_filter_uuids.join("&basic_uuid=")}`;
    // console.log("basic_filter_string", basic_filter_string);
    const response = await createAxios().get(
      `${notify_url}/messages/${sender_uuid}/?limit=${limit || 100}&offset=${offset || 0}&project_uuid=${project_uuid || ""}&entity_uuid=${
        entity_uuid || ""
      }&message_text=${message_text || ""}${basic_filter_string}`,
      {
        headers: {
          "Session-token": token
        }
      }
    );
    // console.log("getChatUserMessages response?.data?.result", response?.data?.result);

    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const sendNewMessage = createAsyncThunk("chat/sendNewMessage", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, token, req_data } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${notify_url}/message/`, req_data, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const sendMessagesViewed = createAsyncThunk("chat/sendMessagesViewed", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token, req_data } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${notify_url}/messages_viewed/`, req_data, {
      headers: {
        "Session-token": token
      }
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const createEntityAffix = createAsyncThunk("chat/createEntityAffix", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, token, req_data } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/affix/create`, req_data, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    all_messages: [],
    current_chat_user_messages: [],
    all_comments: [],
    search_filter: null,
    search_filter_value_messages: "",
    project_filter: null,
    issue_filter: null,
    assigned_entity_type: null,
    is_filters_dialog: false,
    filters_dialog_type: filterDialogTypesArr[0],
    resetComponent: false,
    isNeedToScrollToStart: false,
    replyData: null,
    newMessageBlockHeight: null
  },
  reducers: {
    setAssingedEntityType: (state, action) => {
      state.assigned_entity_type = action.payload;
    },
    setResetComponent: (state, action) => {
      state.resetComponent = action.payload;
    },
    setSearchFilterValueMessages: (state, action) => {
      state.search_filter_value_messages = action.payload;
    },

    setSearchFilter: (state, action) => {
      state.search_filter = action.payload;
    },
    setProjectFilter: (state, action) => {
      state.project_filter = action.payload;
    },
    setIssueFilter: (state, action) => {
      state.issue_filter = action.payload;
    },
    setResetFilters: (state) => {
      state.search_filter = null;
      state.project_filter = null;
      state.issue_filter = null;
    },
    setIsFiltersDialog: (state, action) => {
      state.is_filters_dialog = action.payload;
    },
    setFiltersDialogType: (state, action) => {
      state.filters_dialog_type = action.payload;
    },
    setIsNeedToScrollToStart: (state, action) => {
      state.isNeedToScrollToStart = action.payload;
    },
    setReplyData: (state, action) => {
      state.replyData = action.payload;
    },
    setNewMessageBlockHeight: (state, action) => {
      console.log("setNewMessageBlockHeight", typeof action.payload, action.payload);
      state.newMessageBlockHeight = action.payload;
    }
  },
  extraReducers: {
    // ---------- get All messages-----------------
    [getAllUsersMessages.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllUsersMessages.fulfilled]: (state, action) => {
      state.loading = false;
      state.all_messages = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getAllUsersMessages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- get user messages -----------------
    [getChatUserMessages.pending]: (state, action) => {
      state.loading = true;
    },
    [getChatUserMessages.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("current_chat_user_messages", action?.payload?.data);
      state.current_chat_user_messages = Array.isArray(action?.payload?.data) ? action?.payload?.data : [];
    },
    [getChatUserMessages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- sendMessagesViewed -----------------
    [sendMessagesViewed.pending]: (state, action) => {
      state.loading = true;
    },
    [sendMessagesViewed.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("current_chat_user_messages", action?.payload?.data);
      // state.current_chat_user_messages = Array.isArray(action?.payload?.data) ? action?.payload?.data : [];
    },
    [sendMessagesViewed.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- createEntityAffix -----------------
    [createEntityAffix.pending]: (state, action) => {
      state.loading = true;
    },
    [createEntityAffix.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("current_chat_user_messages", action?.payload?.data);
      // state.current_chat_user_messages = Array.isArray(action?.payload?.data) ? action?.payload?.data : [];
    },
    [createEntityAffix.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const {
  setProjectFilter,
  setIssueFilter,
  setResetFilters,
  setSearchFilter,
  setIsFiltersDialog,
  setSearchFilterValueMessages,
  setFiltersDialogType,
  setResetComponent,
  setAssingedEntityType,
  setIsNeedToScrollToStart,
  setReplyData,
  setNewMessageBlockHeight
} = chatSlice.actions;
export default chatSlice.reducer;

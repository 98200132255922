import { CircularProgress } from "@mui/material";
export const PageLoadingComponent = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        bottom: "6px",
        left: "0",
        right: "0",
        minHeight: "320px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "100",
        background: "white"
      }}
    >
      <CircularProgress sx={{ position: "fixed", top: "calc(50vh - 20px + 4px)" }} />
    </div>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url, notify_url } from "../config/url";
import { checkToken } from "../utils/checkToken";

export const getUserIssuesUuids = createAsyncThunk("issues/getUserIssuesUuids", async (props, { rejectWithValue }) => {
  const { partition_uuid, req_body, token } = props;
  try {
    const response = await createAxios().post(`${entity_url}/entity/list`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.entity_uuids;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getUserIssues = createAsyncThunk("issues/getUserIssues", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token, entity_uuids_data } = props;
  try {
    const response = await createAxios().post(`${entity_url}/entity/read/partial`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    // console.log("getUserIssues", response?.data);
    // console.log("entity_uuids_data", entity_uuids_data);
    const new_data = response?.data?.map((item) => ({
      ...item,
      project_uuid: entity_uuids_data?.find((uuid_data) => uuid_data?.issue_uuid === item?.uuid)?.proj_uuid
    }));
    // console.log("new_data", new_data);
    return new_data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getMentionedIssues = createAsyncThunk("projects/getMentionedIssues", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { basic_filter_uuids, token, search_string, limit, offset } = props;
  // console.log('basic_filter_uuids', basic_filter_uuids)
  if (!basic_filter_uuids?.length) {
    return [];
  }
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);
    const basic_filter_string = `basic_uuid=${basic_filter_uuids.join("&basic_uuid=")}`;
    const response = await createAxios().get(
      `${notify_url}/issues_where_mentioned/?limit=${limit ? `${limit}` : "1000"}&offset=${offset ? `${offset}` : "0"}&${
        basic_filter_uuids?.length ? basic_filter_string : ""
      }${search_string ? `&search_string=${search_string}` : ""}`,
      {
        headers: {
          "Session-token": token
        }
      }
    );
    // console.log("response", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const issuesSlice = createSlice({
  name: "issues",
  initialState: {
    issues_uuids: [],
    issues: [],
    mentioned_issues: [],
    loading: false
  },
  reducers: {
    // setCurrentChatUser: (state, action) => {
    //   console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // },
    // setBiomUsersWithMessages: (state, action) => {
    //   // console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // }
  },
  extraReducers: {
    // ---------- proj_uuids -----------------
    [getUserIssuesUuids.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserIssuesUuids.fulfilled]: (state, action) => {
      state.loading = false;
      state.issues_uuids = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getUserIssuesUuids.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- proj_data -----------------
    [getUserIssues.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserIssues.fulfilled]: (state, action) => {
      state.loading = false;
      state.issues = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getUserIssues.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- proj_data -----------------
    [getMentionedIssues.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentionedIssues.fulfilled]: (state, action) => {
      const newArr = Array.isArray(action?.payload?.data) ? action?.payload?.data : [];
      const finalData = newArr.map((item) => ({
        uuid: item?.assigned_entity_uuid,
        title: item?.assigned_entity_title,
        id: item?.assigned_entity_id,
        partition: item?.partition,
        project_data: { uuid: item?.project_uuid, title: item?.project_title, id: item?.project_id, partition: item?.partition }
      }));
      // console.log('finalData', finalData)
      state.loading = false;
      state.mentioned_issues = finalData;
    },
    [getMentionedIssues.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
// export const {  } = usersSlice.actions;
export default issuesSlice.reducer;

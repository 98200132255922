import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { isIOS } from "react-device-detect";

export const OpenModalBtn = ({ icon, setOpen, isTop }) => {
  const { loading: settingsLoading } = useSelector((state) => state.settings);
  const { loading: weekLoading } = useSelector((state) => state.weekPlanning);

  const windowInnerHeight = window.innerHeight;
  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;

  return (
    // <div style={{ position: "fixed", bottom: "8px", right: "8px", borderRadius: "24px", background: "white" }}>
    <>
      {isTop ? (
        <div className={"fixed_switch_btn_bottom_top"} style={{ top: `calc(50vh - 57px + 27px ${isIOS ? "- 64px" : ""})` }}>
          <LoadingButton
            variant="outlined"
            sx={{ padding: "0 0", minWidth: "48px", height: "48px", borderRadius: "24px" }}
            onClick={() => setOpen(true)}
            loading={settingsLoading || weekLoading}
          >
            {icon}
          </LoadingButton>
        </div>
      ) : (
        <div className={"fixed_switch_btn_bottom"} style={{ bottom: checkIsTallFooter ? "144px" : isIOS ? "96px" : "80px" }}>
          <LoadingButton
            variant="outlined"
            sx={{ padding: "0 0", minWidth: "48px", height: "48px", borderRadius: "24px" }}
            onClick={() => setOpen(true)}
            loading={false}
          >
            {icon}
          </LoadingButton>
        </div>
      )}
    </>
  );
};

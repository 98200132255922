import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentChatUser } from "../../redux/users";
import { getChatUserMessages, setReplyData, setNewMessageBlockHeight } from "../../redux/chat";
import { ListItem, ListItemAvatar, ListItemText, Avatar, Typography } from "@mui/material";
import { ListItemButton } from "@mui/material";
import { MessageCard } from "./MessageCard";
import { appRoutes } from "../../utils/constants";
import { checkToken } from "../../utils/checkToken";
import dayjs from "dayjs";

export const UserCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { partitions } = useSelector((state) => state.settings);
  const { current_user } = useSelector((state) => state.auth);

  const { uuid, first_name, last_name, user_idx, last_message, unread_messages, handleFetch } = props;
  // console.log("props", props);
  // const isUnreadMessages = !!messages?.length;
  // console.log("messages", messages);
  // const lastMessage = messages[messages?.length - 1];

  const getChatMessages = async () => {
    const notify_token = checkToken(current_user?.user_uuid, "notify");
    const partition = partitions.find((item) => item?.params?.name === "PM");

    // const uuids = ["e74b16f3-0bc6-447d-82f5-637bec1ae2df", "dfe8d0b5-4516-4961-98f0-3a43f695352f"];

    const data = {
      basic_filter_uuids: current_user?.basic_filter_project_uuids || [],
      token: checkToken(current_user?.user_uuid, "notify"),
      sender_uuid: uuid,
      limit: 100,
      offset: 0
    };

    console.log("data", data);
    const resp = await dispatch(getChatUserMessages(data)).unwrap();

    return resp;
  };

  const updateCurrentChatUser = (all_messages) => {
    // uuid - current chat user (sender)
    // current_user?.user_uuid - current account (receiver)
    // входящие / inbox = item?.receiver === current_user?.user_uuid && item?.sender === uuid
    // отправле / sent = item?.receiver === uuid && item?.sender === current_user?.user_uuid;
    // const inboxMessages = all_messages.filter(
    //   (item) => item?.receiver === current_user?.user_uuid && item?.sender === uuid
    // );
    // const sentMessages = all_messages.filter(
    //   (item) => item?.receiver === uuid && item?.sender === current_user?.user_uuid
    // );
    // const inboxMessagesArr = inboxMessages.map((item) => ({ ...item, isInbox: true }));
    // const sentMessagesArr = sentMessages.map((item) => ({ ...item, isInbox: false }));
    // const chatMessagesArr = inboxMessagesArr
    //   .concat(sentMessagesArr)
    //   .sort((mess1, mess2) => dayjs(mess2?.created_at).diff(dayjs(mess1?.created_at)));
    // const newData = { ...props, messages: chatMessagesArr };
    // // console.log("updateCurrentChatUser", chatMessagesArr);
    // // console.log("updateCurrentChatUser", unreadUserMessages?.length);
  };

  const handleNavigateChat = async () => {
    try {
      handleFetch(true);
      const data = { uuid: uuid, first_name: first_name, last_name: last_name };
      dispatch(setNewMessageBlockHeight(null));
      dispatch(setReplyData(null));
      // console.log("data", data);
      dispatch(setCurrentChatUser(data));
      // await getChatMessages();
      // const messages = await getChatMessages();
      // const newData = updateCurrentChatUser(messages);

      navigate(appRoutes.chat.path);
    } catch (e) {
      console.log("e", e);
    } finally {
      handleFetch(false);
    }
  };

  // useEffect(() => {
  //   const messagesLength = unreadUserMessages?.length;
  //   if (messagesLength) {

  //     console.log("lastMessage", lastMessage);
  //   }
  // }, []);

  return (
    <ListItemButton onClick={handleNavigateChat} sx={{ padding: "6px 0" }}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: unread_messages ? "rgb(25, 118, 210)" : "gray" }}>
          {!!first_name ? first_name[0] : ""}
          {!!last_name ? last_name[0] : ""}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <div className="modal_accounts_backup_info_wrapper">
          <Typography
            variant="subtitle2"
            color="text.primary"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "calc(100% - 2px)",
              overflow: "hidden",
              fontWeight: "600"
            }}
          >
            {first_name} {last_name} {!!unread_messages ? `(${unread_messages})` : ""}
          </Typography>
        </div>
        <MessageCard message={last_message} />
      </ListItemText>
    </ListItemButton>
  );
};

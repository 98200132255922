import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from "./auth";
import officeReducer from "./office";
import notifyReducer from "./notify";
import scanerReducer from "./scaner";
import backHandlerReducer from "./backHandler";
import switcherReducer from "./switcher";
import settingsReducer from "./settings";
import usersReducer from "./users";
import chatReducer from "./chat";
import chatNotifyReducer from "./chatNotify";
import projectsReducer from "./projects";
import issuesReducer from "./issues";
import commentsReducer from "./comments";
import weekPlanningReducer from "./weekPlanning";
import pushSubscriptionReducer from "./pushSubscription";

const reducers = combineReducers({
  auth: authReducer,
  office: officeReducer,
  notify: notifyReducer,
  switcher: switcherReducer,
  scaner: scanerReducer,
  settings: settingsReducer,
  backHandler: backHandlerReducer,
  users: usersReducer,
  chat: chatReducer,
  chatNotify: chatNotifyReducer,
  projects: projectsReducer,
  issues: issuesReducer,
  comments: commentsReducer,
  weekPlanning: weekPlanningReducer,
  pushSubscription: pushSubscriptionReducer
});

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
  devTools: true
});

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button } from "@mui/material";
import { IconButton, Typography, Divider, ListItemButton, ListItemText } from "@mui/material";
import { DialogWindow } from "../DialogWindow/DialogWindow";
import { FormTextField } from "../FormTextField/FormTextField";

import { updateAccountInfo } from "../../redux/auth";
import { handleToken } from "../../utils/handleToken";
import { handleUpdateAccountInfo } from "../../utils/handleUpdateAccountInfo";

import CloseIcon from "@mui/icons-material/Close";

import ApartmentIcon from "@mui/icons-material/Apartment";
import { setPasswordErrorData } from "../../redux/notify";
import { hashMd5 } from "../../utils/hashMd5";
import { openNotify } from "../OpenNotify/openNotify";

const style = {
  position: "absolute",
  top: "10vh",
  left: "16px",
  width: "calc(100vw - 32px)",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const ModalPasswordError = (props) => {
  const dispatch = useDispatch();
  const { selected_user_info } = useSelector((state) => state.auth);
  const { all_offices } = useSelector((state) => state.office);
  const { passwordErrorData } = useSelector((state) => state.notify);

  const [showModal, setShowModal] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const defaultValues = {
    password: ""
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
      .required("Required field")
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful, isDirty }
  } = methods;

  useEffect(() => {
    // console.log("passwordErrorData", !!passwordErrorData);
    setShowModal(!!passwordErrorData);
  }, [!!passwordErrorData]);

  const handleCancel = () => {
    reset({ ...defaultValues });
    dispatch(setPasswordErrorData(null));
  };

  const handleIsInfoReq = async (newMd5Password) => {
    await handleUpdateAccountInfo(selected_user_info, newMd5Password);
  };

  const handleIsTokenReq = async (url, newMd5Password) => {
    const serive_url = new URL(url).origin;
    const service = all_offices.find((office) => office?.address.some((address) => address === serive_url));
    await handleToken(selected_user_info, service?.service_uuid, serive_url, true, newMd5Password);
  };

  const onSubmit = async (values) => {
    try {
    } catch (e) {}
    const errorUrl = passwordErrorData?.url;
    const isUpdateInfoRequest = errorUrl.includes(selected_user_info?.biom?.biom_domain);
    const newMd5Password = hashMd5(values?.password);
    if (isUpdateInfoRequest) {
      await handleIsInfoReq(newMd5Password);
    } else {
      await handleIsTokenReq(errorUrl, newMd5Password);
    }

    // openNotify("success", "Password changed successfully");
    handleCancel();
  };

  const content = (
    <>
      <FormProvider {...methods}>
        <div style={{ padding: "8px 24px 0 24px", display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1">Invalid password</Typography>

          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div style={{ padding: "8px 24px" }}>
          <Typography variant="subtitle2" sx={{ marginBottom: "16px" }}>
            The password for the account has been changed. Enter the correct password.
          </Typography>
          <FormTextField
            fieldLabel={"Password"}
            fieldName={"password"}
            maxLength={36}
            isVisible={showPass}
            callbackVisibility={setShowPass}
            isDisabled={false}
            isAuto={true}
          />
          <div style={{ padding: "48px 0 16px 0", display: "flex" }}>
            <div style={{ width: "50%", padding: "0 8px 0 0" }}>
              <Button onClick={handleCancel} fullWidth variant="contained" color="error">
                Cancel
              </Button>
            </div>
            <div style={{ width: "50%", padding: "0 8px 0 0" }}>
              <Button
                onClick={handleSubmit(onSubmit)}
                fullWidth
                variant="contained"
                color={"success"}
                disabled={!isDirty}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
  return <DialogWindow children={content} open={showModal} setOpen={setShowModal} styleProps={style} />;
};

import { useSelector, useDispatch } from "react-redux";
import { setIssueFilter } from "../../redux/chat";
import { Chip } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";

export const IssueResetChip = () => {
  const dispatch = useDispatch();

  const { issue_filter } = useSelector((state) => state.chat);

  const handleResetFilter = () => {
    // console.log("handleResetProjectFilter");
    dispatch(setIssueFilter(null));
  };

  return (
    <Chip
      icon={<AssignmentIcon sx={{ fontSize: "16px" }} />}
      label={
        <div className="ellipsys_title" style={{ maxWidth: "150px" }}>{`${issue_filter?.id || "t_id"} ${issue_filter?.title || "t_issue"}`}</div>
      }
      sx={{ marginBottom: "6px", fontSize: "12px" }}
      onClick={handleResetFilter}
      onDelete={handleResetFilter}
      color={"info"}
    />
  );
};

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Card, CardHeader, CardContent, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Avatar, IconButton, Collapse } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

export const AssignedProject = () => {
  const { project_filter, issue_filter, entity_full_data } = useSelector((state) => state.comments);

  const [showdata, setShowData] = useState(true);

  const handleShowData = () => {
    setShowData((prev) => !prev);
  };

  const handleLifeTime = () => {
    if (entity_full_data?.params?.lifeTime) {
      const daysToExpire = Math.round(dayjs(entity_full_data?.params?.lifeTime).diff(dayjs(), "day", true));
      // console.log("daysToExpire", daysToExpire);
      const checkIsNoExpire = daysToExpire >= 0;
      if (checkIsNoExpire) {
        return `${dayjs(entity_full_data?.params?.lifeTime).format("DD-MM-YYYY")} (${daysToExpire} days left)`;
      } else {
        return "Expired.";
      }
    } else {
      return "No data.";
    }
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#1976d2" }}>
            <AccountTreeIcon />
          </Avatar>
        }
        title={
          <Typography>
            <div style={{ maxWidth: "230px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
              <strong>{project_filter?.id}: </strong>
              {project_filter?.title}
            </div>
          </Typography>
        }
        action={<IconButton>{showdata ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
        sx={{ paddingBottom: showdata ? "0" : "16px", transition: ".3s all ease-in-out" }}
        onClick={handleShowData}
      />
      <Collapse in={showdata} timeout="auto" sx={{ transition: ".3s" }}>
        <CardContent>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2" style={{ maxWidth: "270px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
              Owner:{" "}
              <strong style={{ color: "black" }}>
                {entity_full_data?.owner_uinfo?.first_name} {entity_full_data?.owner_uinfo?.last_name}
              </strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Status: <strong style={{ color: "black" }}>{entity_full_data?.params?.status}</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Expiration date: <strong style={{ color: "black" }}>{handleLifeTime()}</strong>
            </Typography>
          </ListItem>
        </CardContent>
      </Collapse>
    </Card>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url } from "../config/url";

export const getPartitions = createAsyncThunk("settings/getPartitions", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/partition/get`, null, {
      headers: {
        "Session-token": token
      }
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response);
  }
});

export const getPublicEntityUuid = createAsyncThunk("settings/getPublicEntityUuid", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token, partition_uuid } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/utility/actor/public`, null, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getPublicProjectStorageUuid = createAsyncThunk("settings/getPublicProjectStorageUuid", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token, partition_uuid, data } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/utility/entity/unique/check`, data, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getWeekPlanningUniqueUuid = createAsyncThunk("settings/getWeekPlanningUniqueUuid", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token, partition_uuid, data } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/utility/entity/unique/check`, data, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    all_services: !!localStorage.getItem("services") ? JSON.parse(localStorage.getItem("services")) : [],
    isDebugMode: false,
    partitions: [],
    pm_partition: null,
    pub_partition: null,
    current_account_public_entity_uuid: null,
    public_project_storage_uuid: null,
    week_planning_unique_uuid: null,
    is_notifications_available: false, // is there a notification permission
    is_push_available: false, // is there a ServiceWorker and notification permission
    is_push_subscribe: false, // does the user have a pushSubscription
    push_subscription: null,
    show_footer_in_chat:
      typeof localStorage.getItem("show_footer_in_chat") === "string" ? JSON.parse(localStorage.getItem("show_footer_in_chat")) : true,
    loading: false
  },
  reducers: {
    setServicesData: (state, action) => {
      const checkIsAlreadyInApp = state.all_services.slice(0).some((service) => service?.url === action.payload?.url);

      if (!checkIsAlreadyInApp) {
        const newData = [...state.all_services, action.payload];
        state.all_services = newData;
        localStorage.setItem("services", JSON.stringify(newData));
      }
    },
    deleteService: (state, action) => {
      const newData = state.all_services.slice(0).filter((office) => office?.url !== action?.payload?.url);

      state.all_services = newData;
      localStorage.setItem("services", JSON.stringify(newData));
    },
    setDebugMode: (state, action) => {
      state.isDebugMode = action.payload;
    },
    setIsNotificationsAvailable: (state, action) => {
      state.is_notifications_available = action.payload;
    },
    setIsPushAvailable: (state, action) => {
      state.is_push_available = action.payload;
    },
    setIsPushSubscribe: (state, action) => {
      state.is_push_subscribe = action.payload;
    },
    setPushSubscription: (state, action) => {
      state.push_subscription = action.payload;
    },
    resetPartitions: (state) => {
      state.pm_partition = null;
      state.pub_partition = null;
      state.current_account_public_entity_uuid = null;
      state.public_project_storage_uuid = null;
      state.week_planning_unique_uuid = null;
    },
    setShowFooterInChat: (state, action) => {
      state.show_footer_in_chat = action.payload;
      localStorage.setItem("show_footer", action.payload);
    }
  },
  extraReducers: {
    // ---------- checkPins -----------------
    [getPartitions.pending]: (state, action) => {
      state.loading = true;
    },
    [getPartitions.fulfilled]: (state, action) => {
      state.loading = false;
      state.partitions = Array.isArray(action?.payload) ? action?.payload : [];
      // console.log("partitions", action?.payload);
      const pm_partition = Array.isArray(action?.payload) ? action?.payload.find((item) => item?.params?.name === "PM") : null;
      const pub_partition = Array.isArray(action?.payload) ? action?.payload.find((item) => item?.params?.name === "PUBLIC") : null;
      // console.log("pm_partition", pm_partition);
      state.pm_partition = pm_partition;
      state.pub_partition = pub_partition;
    },
    [getPartitions.rejected]: (state, action) => {
      // console.log("action?.payload", action?.payload);
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getPublicEntityUuid -----------------
    [getPublicEntityUuid.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublicEntityUuid.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getPublicEntityUuid", action?.payload?.uuid);
      state.current_account_public_entity_uuid = action?.payload?.uuid;
    },
    [getPublicEntityUuid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getPublicProjectStorageUuid -----------------
    [getPublicProjectStorageUuid.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublicProjectStorageUuid.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getPublicEntityUuid", action?.payload?.uuid);
      state.public_project_storage_uuid = action?.payload?.uuid;
    },
    [getPublicProjectStorageUuid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getPublicProjectStorageUuid -----------------
    [getWeekPlanningUniqueUuid.pending]: (state, action) => {
      state.loading = true;
    },
    [getWeekPlanningUniqueUuid.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log("getPublicEntityUuid", action?.payload?.uuid);
      state.week_planning_unique_uuid = action?.payload?.uuid;
    },
    [getWeekPlanningUniqueUuid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});

export const {
  setServicesData,
  deleteService,
  setDebugMode,
  setIsPushSubscribe,
  setPushSubscription,
  setIsPushAvailable,
  setIsNotificationsAvailable,
  resetPartitions,
  setShowFooterInChat
} = settingsSlice.actions;

export default settingsSlice.reducer;

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { Typography, ListItem, ListItemText, Popover, Menu, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import dayjs from "dayjs";
import { front_date_with_time } from "../../utils/constants";
import useLongPress from "../../utils/useLongPress";
import { MessageActions } from "../MessageActions/MessageActions";
import "./index.css";
import CheckIcon from "@mui/icons-material/Check";
import { entityDataType } from "../../utils/entityDataType";
import { entity_data_types } from "../../config/entity_data_types";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

export const ChatMessageComponent = ({ message }) => {
  // console.log("message", message);
  const dispatch = useDispatch();

  const { current_user } = useSelector((state) => state.auth);

  const [isViewed, setIsViewed] = useState(message?.is_viewed);
  // const [open, setOpen] = useState(false);

  // console.log("message", message);
  // const isInbox = message?.isInbox;
  const comment_data = !!message?.params?.entity?.project_uuid ? message?.params?.entity : null;
  const entity_data = comment_data
    ? { type: comment_data?.assigned_entity_type, id: comment_data?.assigned_entity_id, title: comment_data?.assigned_entity_title }
    : null;

  const isInbox = message?.message_type === "inbox";
  const isOutboxWithActions = !isInbox && (entity_data?.type === "project" || entity_data?.type === "issue");

  const isMessageActionsAvailable = isInbox ? Object.keys(entity_data_types).some((key) => key === entity_data?.type) : isOutboxWithActions;

  const reply_data = message?.params?.reply;

  useEffect(() => {
    setIsViewed(message?.is_viewed);
  }, [message?.is_viewed]);

  // const parseTimeValue = () => {
  //   const today_date = dayjs().format("DD-MM-YYYY");
  //   const yesterday_date = dayjs().subtract(1, "day").format("DD-MM-YYYY");
  //   const current_date = dayjs(message?.created).format("DD-MM-YYYY");
  //   console.log("yesterday_date", yesterday_date);
  //   console.log("today_date", today_date);
  //   console.log("current_date", current_date);
  //   console.log(current_date === yesterday_date);
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  //   if (today_date === yesterday_date) {
  //     return <span>Yesterday {dayjs(message?.created).format("HH:mm")}</span>;
  //   }
  //   if (today_date === current_date) {
  //     return <span>Today {dayjs(message?.created).format("HH:mm")}</span>;
  //   }
  //   return dayjs(message?.created).format(front_date_with_time);
  // };

  return (
    <>
      <ListItem
        sx={{
          position: "relative",
          padding: isInbox ? "0 0 0 10px" : "0 10px 0 0 ",
          "-webkit-user-select": "none",
          " -moz-user-select": "none",
          "-ms-user-select": "none"
        }}
        // {...longPressEvent}
      >
        {/* <ListItemAvatar>
      <Avatar>
        {first_name[0]}
        {last_name[0]}
      </Avatar>
    </ListItemAvatar> */}
        <div className={isInbox ? "message_block_triangle_inbox" : "message_block_triangle_sent"}></div>
        <ListItemText
          sx={{
            padding: isInbox ? "12px 32px 24px 8px" : "12px 24px 24px 8px",
            // border: "1px solid rgba(128,128,128,.3)",
            minHeight: "64px !important",
            borderRadius: isInbox ? "16px 16px 16px 0" : "16px 16px 0 16px",
            background: isInbox ? "rgb(240, 240, 240)" : "rgb(237, 247, 237)"
          }}
        >
          {comment_data && <EntityTitleComponent type={entity_data?.type} id={entity_data?.id} title={entity_data?.title} />}
          {reply_data?.message && (
            <div
              style={{
                margin: "0 0 6px 6px",
                padding: "4px 4px",
                whiteSpace: "normal",
                wordWrap: "break-word",
                borderLeft: "3px solid #1976d2",
                background: "rgb(210, 210, 210)",
                borderRadius: "0 6px 6px 0"
              }}
            >
              {reply_data?.message}
            </div>
          )}

          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{message?.message}</div>

          {/* {!isViewed && isInbox && (
            <LoadingButton
              // variant="outlined"
              loading={loading}
              sx={{
                position: "absolute",
                top: "6px",
                right: isInbox ? "0" : "6px",
                padding: "0 0",
                minWidth: "36px",
                height: "36px"
              }}
              onClick={handleMarkViewed}
            >
              {!loading ? <MarkChatReadIcon color="primary" /> : ""}
            </LoadingButton>
          )}

          <IconButton sx={{ position: "absolute", bottom: "20px", right: isInbox ? "0" : "6px" }}>
            <ReplyIcon color="primary" />
          </IconButton> */}
          {isMessageActionsAvailable && <MessageActions message={message} setIsViewed={setIsViewed} isViewed={isViewed} />}

          <div style={{ position: "absolute", bottom: "4px", right: isInbox ? "28px" : "24px", fontSize: "14px" }}>
            {dayjs(message?.created).format(front_date_with_time)}
          </div>
          {isViewed && isInbox && (
            <CheckIcon color="success" sx={{ position: "absolute", bottom: "6px", right: isInbox ? "8px" : "12px", fontSize: "18px" }} />
          )}
        </ListItemText>
      </ListItem>
    </>
  );
};

import { useSelector, useDispatch } from "react-redux";
import { setIsNotificationsAvailable } from "../../redux/settings";
import { useEffect, useState } from "react";
import { Alert, Typography, Button } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { isIOS, isSafari, osVersion } from "react-device-detect";

export const PushUnavailableBlock = () => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { is_notifications_available, is_push_available } = useSelector((state) => state.settings);

  const no_push = "Receiving push notifications in this application is not available on this device.";
  const notify_is_blocked = "The display of notifications is blocked for this site.";
  const no_notify = "The browser does not have permission to display the notification.";
  const no_user = "There is no account to subscribe to notifications.";
  const old_safari_version = `Available only for the browser Safari 16.4 and above (your browser version is ${osVersion}).`;

  const defaultCheckStates = [no_push, notify_is_blocked, no_notify, no_user];

  const [checkState, setCheckState] = useState(defaultCheckStates[0]);

  const initCheckFunc = () => {
    if (!current_user?.user_uuid) {
      setCheckState(defaultCheckStates[3]);
      return;
    }
    if (!is_notifications_available) {
      if (!!("Notification" in window)) {
        if (Notification.permission === "denied") {
          setCheckState(defaultCheckStates[1]);
        } else {
          setCheckState(defaultCheckStates[2]);
        }
      }

      return;
    }
    if (!is_push_available) {
      if (isIOS && isSafari) {
        const checkVersion = Number(osVersion) >= 16.4;
        setCheckState(checkVersion ? defaultCheckStates[0] : defaultCheckStates[4]);
      } else {
        setCheckState(defaultCheckStates[0]);
      }

      return;
    }
  };

  useEffect(() => {
    initCheckFunc();
  }, [is_push_available, current_user?.user_uuid, is_notifications_available]);

  const handleNotifyPermission = () => {
    if (!!window?.Notification) {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          dispatch(setIsNotificationsAvailable(true));
        }
      });
    }
  };

  return (
    <>
      <Alert severity="warning" sx={{ "& .MuiAlert-icon": { marginRight: "32px" } }}>
        <Typography>Push-notifications</Typography>
        {checkState}
        {checkState === defaultCheckStates[2] && (
          <div style={{ marginTop: "6px" }}>
            <Button fullWidth variant="contained" onClick={handleNotifyPermission} startIcon={<NotificationsActiveIcon />}>
              Request permission
            </Button>
          </div>
        )}
      </Alert>
    </>
  );
};

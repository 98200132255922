import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMentionedIssues } from "../../../redux/issues";
import { setIssueFilter, setProjectFilter } from "../../../redux/chat";
import { setProjectFilter as setProjectFilterComments, setIssueFilter as setIssueFilterComments } from "../../../redux/comments";

// ------------ validation --------------
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// ------------ component ---------------
import { ProjectResetChip } from "../../ProjectResetChip/ProjectResetChip";
import { ItemComponent } from "../ItemComponent";
import { useForm, FormProvider } from "react-hook-form";
import { DialogTitle, DialogContent, IconButton, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import SearchComponent from "../../../Pages/UsersPage/SearchComponent";
import { SubGroup } from "../../Subgroup/Subgroup";
import { NoData, NO_ISSUES } from "../NoData";
// ------------ icons ------------------
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import { checkToken } from "../../../utils/checkToken";
import { orderBy } from "lodash";

export const IssueFilter = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const {
    project_filter: chat_project_filter,
    issue_filter: chat_issue_filter,
    is_filters_dialog,
    loading: chatLoading
  } = useSelector((state) => state.chat);
  const {
    project_filter: comments_project_filter,
    issue_filter: comments_issue_filter,
    is_open_comment_form
  } = useSelector((state) => state.comments);
  const { mentioned_projects } = useSelector((state) => state.projects);
  const { loading: issuesLoading } = useSelector((state) => state.issues);

  const project_filter = is_open_comment_form ? comments_project_filter : chat_project_filter;
  const issue_filter = is_open_comment_form ? comments_issue_filter : chat_issue_filter;

  const [issuesData, setIssuesData] = useState([]);
  const availableProjects = current_user?.basic_filter_project_uuids?.length
    ? mentioned_projects.filter((project) => current_user?.basic_filter_project_uuids.some((uuid) => uuid === project?.uuid))
    : [];

  const [issueSearchFilterTitle, setIssueSearchFilterTitle] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(!!project_filter?.uuid ? 100 : 20);
  const [total, setTotal] = useState(0);
  const [fetching, setFetching] = useState(true);

  const initFunc = async () => {
    try {
      if (is_filters_dialog) {
        const data = {
          basic_filter_uuids: current_user?.basic_filter_project_uuids || [],
          token: checkToken(current_user?.user_uuid, "notify"),
          search_string: issueSearchFilterTitle || "",
          limit: limit,
          offset: limit * page
        };
        const resp = await dispatch(getMentionedIssues(data)).unwrap();
        if (!project_filter?.uuid) {
          setTotal(resp?.total);
        }

        const newArr = Array.isArray(resp?.data) ? resp?.data : [];

        const newIssues = newArr.map((item) => ({
          uuid: item?.assigned_entity_uuid,
          title: item?.assigned_entity_title,
          id: item?.assigned_entity_id,
          partition: item?.partition,
          project_data: { uuid: item?.project_uuid, title: item?.project_title, id: item?.project_id, partition: item?.partition }
        }));

        const issuesDataArr = page === 0 ? newIssues : issuesData.concat(newIssues);
        const finalIssuesData = issuesDataArr
          .slice(0)
          .filter((iss) => (project_filter?.uuid ? iss?.project_data?.uuid === project_filter?.uuid : iss));
        const sortingData = orderBy(finalIssuesData, ["partition", "project_data.uuid", "id"], ["asc", "desc", "desc"]);
        // .sort((iss1, iss2) => issueSortFunc(iss1, iss2));

        setIssuesData(sortingData);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    initFunc();
  }, [is_filters_dialog, issueSearchFilterTitle, page, limit]);

  useEffect(() => {
    setPage(0);
    if (!!project_filter?.uuid) {
      setLimit(100);
    } else {
      setLimit(20);
    }
    setFetching(true);
  }, [issueSearchFilterTitle, project_filter?.uuid]);

  const handleMore = () => {
    setPage((prev) => prev + 1);
  };

  // useEffect(() => {
  //   // console.log("mentioned_issues", mentioned_issues);

  //   const newData = mentioned_issues

  //   // console.log(newData)
  //   setIssuesData(prev => newData);
  // }, [mentioned_issues?.length, ]);

  const defaultValues = {
    project: project_filter || "default",
    issueType: "default",
    vector: "default"
  };

  const validationSchema = yup.object({
    project: yup.string().required("Required"),
    issueType: yup.string().test("check_activity", "Required", (val) => val !== "default"),
    vector: yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    mode: "onBlur",
    defaultValues: {
      ...defaultValues
    }
  });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSelectIssue = (issue) => {
    if (issue?.uuid !== issue_filter?.uuid) {
      // setValue("issue", issue, { shouldDirty: true, shouldValidate: true });
      if (is_open_comment_form) {
        dispatch(setIssueFilterComments(issue));
      } else {
        dispatch(setIssueFilter(issue));
      }

      // dispatch(setProjectFilter(null));
      // const parent_project = projects.find((proj) => proj?.uuid === issue?.project_uuid);
      // dispatch(setProjectFilter(parent_project));
    } else {
      // setValue("issue", null, { shouldDirty: true, shouldValidate: true });

      if (is_open_comment_form) {
        dispatch(setIssueFilterComments(null));
      } else {
        dispatch(setIssueFilter(null));
      }
      // dispatch(setProjectFilter(null));
    }
  };

  // console.log("issues", issues);

  return (
    <>
      <FormProvider {...methods}>
        <DialogTitle sx={{ padding: "16px 0 0 0" }}>
          <div style={{ padding: "0 16px 0 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>Filter by issue</div>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ position: "relative", transition: "all 0.5s ease-in-out" }}>
          <div
            style={{
              padding: project_filter?.uuid ? "12px 0 12px 0" : "12px 0 0 0",
              width: "100%",
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: "10"
            }}
          >
            <SearchComponent
              fullWidth={true}
              loading={issuesLoading}
              variant={"outlined"}
              filterTitle={issueSearchFilterTitle}
              onFilterTitle={setIssueSearchFilterTitle}
            />
            <Divider sx={{ marginTop: project_filter?.uuid ? "32px" : "12px" }} />
            {project_filter?.uuid && (
              <div className="project_reset_chip_issues">
                <ProjectResetChip isFilters={true} />
              </div>
            )}
          </div>
          <div style={{ height: "12px" }}></div>

          {issuesData?.length ? (
            issuesData.map((issue, idx) => {
              const checkIsNewProject = issuesData[idx - 1]?.project_data?.uuid !== issue?.project_data?.uuid;
              let newProject;
              if (checkIsNewProject) {
                newProject = availableProjects.find((project) => project?.uuid === issue?.project_data?.uuid);
              }
              return (
                <div key={`${issue?.uuid}_${issue?.id}`}>
                  {newProject && !project_filter?.uuid && (
                    <div style={{ position: "sticky", top: "0" }}>
                      <SubGroup
                        label={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <AccountTreeIcon sx={{ marginRight: "4px", fontSize: "18px" }} />
                            <div className="ellipsys_title" style={{ maxWidth: "200px" }}>
                              {newProject?.id} {newProject?.title}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  )}

                  <ItemComponent
                    icon={issue?.id || `I_${idx}`}
                    isSelected={(issue?.uuid || issue) === issue_filter?.uuid}
                    label={issue?.title || `issue_${idx}`}
                    val={issue}
                    callback={handleSelectIssue}
                  />
                </div>
              );
            })
          ) : (
            <NoData type={NO_ISSUES} />
          )}
          {!!issuesData?.length && issuesData?.length < total && (
            <div style={{ position: "relative", margin: "8px 0", textAlign: "center" }}>
              <Divider sx={{ position: "absolute", top: "30px", width: "100%" }} />
              <div style={{ position: "relative", display: "inline-block", padding: "0 6px", backgroundColor: "white", zIndex: "1000" }}>
                <LoadingButton loading={fetching} onClick={handleMore} variant="outlined">
                  {issuesData?.length} / {total} <br />
                  Show more
                </LoadingButton>
              </div>
            </div>
          )}
          {/* <div style={{ height: "100vh" }}></div> */}
        </DialogContent>
      </FormProvider>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import isOnline from "is-online";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    type: "",
    message: "",
    isOpen: false,
    isConnection: true,
    isTrustedurlError: false,
    isTokenError: false,
    passwordErrorData: null,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setIsConnection: (state, action) => {
      state.isConnection = action.payload;
    },
    setIsTokenError: (state, action) => {
      state.isTokenError = action.payload;
    },
    setIsTrustedUrlError: (state, action) => {
      // console.log("isTrustedurlError", action.payload);
      state.isTrustedurlError = action.payload;
    },
    setPasswordErrorData: (state, action) => {
      state.passwordErrorData = action.payload;
    },
    setSwIsInit: (state, action) => {
      state.serviceWorkerInitialized = action.payload;
    },
    setSwIsUpdated: (state, action) => {
      state.serviceWorkerUpdated = action.payload;
    },
    setSwReg: (state, action) => {
      state.serviceWorkerRegistration = action.payload;
    }
  }
});
export const {
  setIsOpen,
  setType,
  setMessage,
  setIsConnection,
  setIsTrustedUrlError,
  setPasswordErrorData,
  setSwIsInit,
  setSwIsUpdated,
  setSwReg,
  setIsTokenError,
  setTokenErrorsArr
} = notificationSlice.actions;
export default notificationSlice.reducer;

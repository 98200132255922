import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PolylineIcon from "@mui/icons-material/Polyline";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DescriptionIcon from "@mui/icons-material/Description";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

export const entityDataType = (type, default_id) => {
  switch (type) {
    case "project":
      return {
        icon: <AccountTreeIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `P${default_id}`
      };
    case "vector":
      return {
        icon: <PolylineIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `V${default_id}`
      };
    case "issue":
      return {
        icon: <AssignmentIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `I${default_id}`
      };
    case "projectIdea":
      return {
        icon: <TipsAndUpdatesIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `I${default_id}`
      };
    case "projectDocument":
      return {
        icon: <DescriptionIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `D${default_id}`
      };
    case "projectSpreadsheet":
      return {
        icon: <BorderAllIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `S${default_id}`
      };
    case "projectGoal":
      return {
        icon: <CrisisAlertIcon color="primary" sx={{ fontSize: "18px" }} />,
        id: typeof default_id === "string" ? default_id : `G${default_id}`
      };
    default:
      return { icon: "", id: default_id };
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url, notify_url } from "../config/url";

export const getSenders = createAsyncThunk("users/getSenders", async (props, { rejectWithValue }) => {
  // console.log("getSenders", props);
  const { basic_filter_uuids, partition_uuid, limit, offset, token } = props;
  try {
    const basic_filter_string = `basic_uuid=${basic_filter_uuids.join("&basic_uuid=")}`;
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().get(`${notify_url}/senders/?limit=${limit || 100}&offset=${offset || 0}&${basic_filter_string}`, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    search_filter_value_users: "",
    senders: [],
    biom_users_with_messages: [],
    current_chat_user: null,
    loading: false
  },
  reducers: {
    setSearchFilterValueUsers: (state, action) => {
      state.search_filter_value_users = action.payload;
    },
    setCurrentChatUser: (state, action) => {
      // console.log(action.payload?.uuid);
      state.current_chat_user = action.payload;
    },
    setBiomUsersWithMessages: (state, action) => {
      // console.log(action.payload?.uuid);
      state.current_chat_user = action.payload;
    }
  },
  extraReducers: {
    // ---------- checkPins -----------------
    [getSenders.pending]: (state, action) => {
      state.loading = true;
    },
    [getSenders.fulfilled]: (state, action) => {
      state.loading = false;
      state.senders = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getSenders.rejected]: (state, action) => {
      // console.log("error action?.payload", action?.payload);
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const { setCurrentChatUser, setBiomUsersWithMessages, setSearchFilterValueUsers } = usersSlice.actions;
export default usersSlice.reducer;

// ---- react, lib, redux -------------
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSenders } from '../../redux/users';
import { setProjectFilter, setIssueFilter } from '../../redux/chat';
import { setSearchFilter } from '../../redux/chat';
import { getMentionedProjects } from '../../redux/projects';
import { setIsOpenCommentForm } from '../../redux/comments';

// ---- components -------------
import { NoData } from '../../components/ChatFiltersDialog/NoData';
import { ModalAccounts } from '../../components/ModalAccounts/ModalAccounts';
import FullScreenDialog from '../../components/FullScreenDialog/FullScreenDialog';
import { ScannerComponent } from '../../components/Scaner/ScannerComponent';
import { PageLoadingComponent } from '../../components/PageLoadingComponent/PageLoadingComponent';
import { OpenModalBtn } from './OpenModalBtn';
import { UserCard } from './UserCard';
import { CircularProgress } from '@mui/material';

// ----- icons -----------------
import EditIcon from '@mui/icons-material/Edit';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// ---- utils ------------------
import { checkToken } from '../../utils/checkToken';
import { scannerType } from '../../utils/constants';
import { NO_CHATS } from '../../components/ChatFiltersDialog/NoData';
import { handleResetWmsTokens } from '../../utils/handleResetWmsTokens';

export const UsersPage = () => {
  const dispatch = useDispatch();
  const [initialFetching, setInitialFetching] = useState(true);
  const [fetchFromCard, setFetchFromCard] = useState(false);
  // const [openMessageDialog, setOpenMessageDialog] = useState(false);

  const { current_user } = useSelector((state) => state.auth);
  const { senders, search_filter_value_users } = useSelector((state) => state.users);

  const [openDialog, setOpenDialog] = useState(false);
  const [openAccDialog, setOpenAccDialog] = useState(false);
  const [sendersData, setSendersData] = useState([]);

  const notify_token = checkToken(current_user?.user_uuid, 'notify');

  const initData = async () => {
    setInitialFetching(true);
    const data = {
      basic_filter_uuids: current_user?.basic_filter_project_uuids || [],
      token: notify_token,
    };
    // console.log("data", data);
    const filtersFuncArr = [getSenders, getMentionedProjects];
    const resp = await Promise.all(filtersFuncArr.map(async (func, idx) => await dispatch(func(data)).unwrap()));

    // console.log("resp", resp);
  };

  const initDataWrapperFunc = async () => {
    try {
      if (notify_token) {
        await initData();
      } else {
        await handleResetWmsTokens(current_user);
      }
    } catch (e) {
      const stringError = JSON.stringify(e);
      const checkFor401 = stringError.includes('unauthorized') || stringError.includes('401');
      // console.log('checkFor401', checkFor401)
      if (checkFor401) {
        await handleResetWmsTokens(current_user);
      }
    } finally {
      setTimeout(() => {
        setInitialFetching(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (!!current_user?.user_uuid) {
      initDataWrapperFunc();
      dispatch(setProjectFilter(null));
      dispatch(setIssueFilter(null));
      dispatch(setSearchFilter(null));
    }
  }, [current_user?.user_uuid, current_user?.basic_filter_project_uuids?.length, notify_token]);

  useEffect(() => {
    const newData = senders.filter((user) =>
      !!search_filter_value_users
        ? `${user?.user_data?.first_name} ${user?.uinfo?.last_name}`
            .toLowerCase()
            .includes(search_filter_value_users?.toLowerCase())
        : user
    );

    setSendersData(newData);
  }, [search_filter_value_users, senders]);

  // useEffect(() => {

  // }, [])

  const handleFetchFromCard = (bool) => {
    setFetchFromCard(bool);
  };

  const handleOpenSignInScaner = () => {
    setOpenDialog(true);
    setOpenAccDialog(false);
  };

  const handaleOpenAccountsModal = () => {
    setOpenAccDialog(true);
  };

  const handleOpenCommentForm = (bool) => {
    dispatch(setIsOpenCommentForm(bool));
  };

  return (
    <>
      {initialFetching ? (
        <PageLoadingComponent />
      ) : (
        <>
          {fetchFromCard && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                bottom: '6px',
                left: '0',
                right: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '100',
                background: 'rgba(128, 128, 128, .15)',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {sendersData?.length ? (
            sendersData.map((user) => (
              <div key={user?.uuid}>
                <UserCard
                  {...user?.user_data}
                  uuid={user?.uuid}
                  last_message={user?.last_message}
                  unread_messages={user?.unread_messages}
                  handleFetch={handleFetchFromCard}
                />
              </div>
            ))
          ) : (
            <NoData type={NO_CHATS} />
          )}
        </>
      )}
      <OpenModalBtn setOpen={handaleOpenAccountsModal} icon={<PeopleAltIcon />} />
      <OpenModalBtn isTop={true} setOpen={handleOpenCommentForm} icon={<EditIcon />} />

      <FullScreenDialog
        children={<ScannerComponent type={scannerType.sign_in} callbackClose={setOpenDialog} />}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <ModalAccounts
        showModal={openAccDialog}
        setShowModal={setOpenAccDialog}
        handleOpenSignInScaner={handleOpenSignInScaner}
      />
    </>
  );
};

import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { setPeriodDaysData } from "../../redux/weekPlanning";

import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ActivityAction } from "./ActivityAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplyIcon from "@mui/icons-material/Reply";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteIcon from "@mui/icons-material/Delete";
import { checkToken } from "../../utils/checkToken";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

export const ActivityActions = ({ duplicateForTomorrow, duplicateTillWeekEnd, handleDeleteIssue }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { selected_day, period_days_data } = useSelector((state) => state.weekPlanning);

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const id = !!anchorEl ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // console.log("handleClose");
    setAnchorEl(null);
  };

  const checkIsSunday = !!selected_day ? selected_day?.day() === 0 : false;
  const checkIsFridayOrWeekends = !!selected_day ? selected_day?.day() === 0 || selected_day?.day() === 5 || selected_day?.day() === 6 : false;

  const handleDuplicateTomorrow = async () => {
    try {
      setLoading(true);
      await duplicateForTomorrow();
      handleClose();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicateTillEnd = async () => {
    try {
      setLoading(true);
      await duplicateTillWeekEnd();
      handleClose();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await handleDeleteIssue();
      handleClose();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const menuData = [
    {
      callback: handleDuplicateTomorrow,
      icon: <ControlPointDuplicateIcon color="primary" />,
      text: "Duplicate for tomorrow",
      disabled: checkIsSunday
    },
    {
      callback: handleDuplicateTillEnd,
      icon: <ControlPointDuplicateIcon color="primary" />,
      text: "Duplicate till end of the week",
      disabled: checkIsFridayOrWeekends
    },
    {
      callback: handleDelete,
      icon: <DeleteIcon color="error" />,
      text: "Delete"
    }
  ];

  return (
    <>
      <IconButton
        // sx={{ position: "absolute", top: "5px", right: isInbox ? "-6px" : "6px" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {menuData?.map((item) => (
          <div key={item?.uuid}>
            <ActivityAction {...item} isSomeFetching={loading} />
          </div>
        ))}

        {/*         
        <MenuItem disabled={checkIsWeekEnd} onClick={duplicateForTomorrow}>
          <ListItemIcon>
            <LoadingButton sx={{ minWidth: "24px", padding: "0 0" }}>
              <ControlPointDuplicateIcon color="primary" />
            </LoadingButton>
          </ListItemIcon>
          <ListItemText>Duplicate for tomorrow</ListItemText>
        </MenuItem>
        <MenuItem sx={{ margin: "8px 0 16px 0" }} disabled={checkIsWeekEnd} onClick={duplicateTillWeekEnd}>
        <LoadingButton sx={{ minWidth: "24px", padding: "0 0" }}>
              <ControlPointDuplicateIcon color="primary" />
            </LoadingButton>
          Duplicate till end of the week
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteIssue}>
        <LoadingButton sx={{ minWidth: "24px", padding: "0 0" }}>
        <DeleteIcon color="error" />
            </LoadingButton>          
          Delete
        </MenuItem> */}
      </Menu>
    </>
  );
};

import { Typography } from "@mui/material";
import { entityDataType } from "../../utils/entityDataType";

export const EntityTitleComponent = ({ type, id, title }) => {
  return (
    <Typography
      variant="subtitle2"
      style={{
        display: "inline-flex",
        alignItems: "center",
        width: "calc(100% - 2px)",
        lineHeight: "1.2",
        marginBottom: "12px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontWeight: "600"
      }}
    >
      <div
        style={{
          marginTop: "-4px",
          // display: "inline-flex",
          // alignItems: "center",
          cursor: "pointer",
          fontSize: "14px"
        }}
        className="ellipsys_title"
      >
        <div style={{ display: "inline-block", position: "relative", top: "4px" }}>{entityDataType(type, id)?.icon}</div>
        <div style={{ display: "inline-block", margin: "0 3px" }}>
          <strong>{entityDataType(type, id)?.id}</strong>
        </div>
        {title}
      </div>
    </Typography>
  );
};

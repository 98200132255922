import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const SuccessQR = ({ isClassicUser }) => {
  return (
    <div style={{ marginTop: "36px", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CheckCircleOutlineIcon color="success" sx={{ marginRight: "16px", fontSize: "30px" }} />
        <Typography color="warning" variant="subtitle1">
          {!isClassicUser ? "Access is allowed" : "Success"}
        </Typography>
      </div>
      <Typography color="warning" variant="subtitle2" sx={{ marginTop: "16px", padding: "0 24px", textAlign: "center" }}>
        {isClassicUser ? "You have successfully accessed the web service." : "The URL was successfully received."}
      </Typography>
    </div>
  );
};

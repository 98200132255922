import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Card, CardHeader, CardContent, ListItem, ListItemIcon, ListItemText, Typography, Chip } from "@mui/material";
import { Avatar, Collapse, IconButton } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import DescriptionIcon from "@mui/icons-material/Description";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const AssignedIssue = () => {
  const { current_user } = useSelector((state) => state.auth);
  const { issue_filter, entity_full_data } = useSelector((state) => state.comments);
  const { current_comments_project_users } = useSelector((state) => state.projects);

  const [showdata, setShowData] = useState(true);
  const [assignedUsers, setAssignedUsers] = useState([]);

  const handleShowData = () => {
    setShowData((prev) => !prev);
  };

  useEffect(() => {
    if (Array.isArray(current_comments_project_users)) {
      const issueUsers = entity_full_data?.params?.usersSearch
        .map((user_uuid) => current_comments_project_users.find((proj_user) => proj_user?.uuid === user_uuid))
        .filter((user) => !!user);

      setAssignedUsers(Array.isArray(issueUsers) ? issueUsers : []);
    }
  }, [issue_filter?.uuid, current_comments_project_users, entity_full_data?.uuid]);

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#1976d2" }}>
            <AssignmentIcon />
          </Avatar>
        }
        title={
          <Typography>
            <div style={{ maxWidth: "230px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
              <strong>{issue_filter?.id}: </strong>
              {issue_filter?.title}
            </div>
          </Typography>
        }
        action={<IconButton>{showdata ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
        sx={{ paddingBottom: showdata ? "0" : "16px", transition: ".3s all ease-in-out" }}
        onClick={handleShowData}
      />
      <Collapse in={showdata} timeout="auto" sx={{ transition: ".3s" }}>
        <CardContent>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Tracker: <strong style={{ color: "black" }}>{entity_full_data?.params?.tracker}</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Priority: <strong style={{ color: "black" }}>{entity_full_data?.params?.priority}</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Estimated time: <strong style={{ color: "black" }}>{entity_full_data?.params?.estimated_time}</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <Typography variant="subtitle2">
              Status: <strong style={{ color: "black" }}>{entity_full_data?.params?.status}</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <div>
              <Typography variant="subtitle2">
                Assigned users: <strong style={{ color: "black" }}>{!assignedUsers?.length ? "no users assinged" : ""}</strong>
              </Typography>
              <div>
                {assignedUsers?.length ? (
                  <>
                    {assignedUsers.map((user) => (
                      <Chip
                        color={user?.uuid === current_user?.user_uuid ? "success" : "default"}
                        label={user?.label}
                        sx={{ margin: "4px 4px", maxWidth: "150px" }}
                        key={`${user?.uuid}_issue_description`}
                      />
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ListItem>
          <ListItem sx={{ padding: "6px 8px" }}>
            <div>
              <Typography variant="subtitle2">What needs to be done:</Typography>
              <Typography>{entity_full_data?.params?.description || "No description."}</Typography>
            </div>
          </ListItem>
        </CardContent>
      </Collapse>
    </Card>
  );
};

import { useSelector, useDispatch } from "react-redux";
import { setSelectedDayUuid, setSelectedDay } from "../../redux/weekPlanning";
import { setPeriodSelectedDay, setPeriodSelectedDayUuid } from "../../redux/weekPlanning";
import { getWeekVectorsUuids, getWeekVectors, createDateVector } from "../../redux/weekPlanning";
import { getDateVectorIssuesUuids, setLoadingIssues, setOpenPeriodModal } from "../../redux/weekPlanning";
import { useState, useEffect } from "react";
import { DialogTitle, DialogContent, DialogActions, IconButton, Divider, Button } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { DayBtn } from "../UsersPage/WeekPlanningForm/DayBtn";
import { DayMenuItem } from "./DayMenuItem";
import { CustomDateCalendar } from "../UsersPage/WeekPlanningForm/CustomDateCalendar";

import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { checkToken } from "../../utils/checkToken";

import dayjs from "dayjs";

export const ModalContent = ({ dateButtonsOptions, handlePeriodSelectedDate, handlePrevWeek, handleNextWeek, handleSwipeToSlide }) => {
  const today = dayjs();
  const dispatch = useDispatch();

  const { current_user } = useSelector((state) => state.auth);
  const { pub_partition, week_planning_unique_uuid } = useSelector((state) => state.settings);
  const { selected_day, period_days_data, period_selected_day, openPeriodModal, loading_list, loading_issues } = useSelector(
    (state) => state.weekPlanning
  );

  const [fetching, setFetching] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);

  const handleCloseDialog = () => {
    dispatch(setOpenPeriodModal(false));
  };

  const handleOpenDateModal = () => {
    setOpenDateModal(true);
  };

  const handleCustomDateCalendar = (dateObj) => {
    // console.log("dateObj", dateObj);
    handlePeriodSelectedDate(dateObj);
    handleSwipeToSlide({ date: dateObj });
  };

  // useEffect(() => {
  //   console.log("loading_list, loading_issues", loading_list, loading_issues);
  // }, [loading_list, loading_issues]);

  return (
    <>
      <DialogTitle sx={{ padding: "8px 24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Week planning</div>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ position: "relative" }}>
        <div style={{ margin: "0 -8px", display: "flex", justifyContent: "space-between", textAlign: "center" }}>
          <Button variant="outlined" sx={{ padding: "0 0", minWidth: "36px" }} onClick={() => handlePrevWeek(7)} disabled={loading_list}>
            <ArrowBackIcon />
          </Button>
          <LoadingButton
            color={today.isBetween(period_selected_day.day(1), period_selected_day.day(7)) ? "primary" : "neutral"}
            variant="contained"
            endIcon={<CalendarTodayIcon />}
            sx={{ fontSize: "16px" }}
            onClick={handleOpenDateModal}
            loading={loading_list}
          >
            {period_selected_day.day(1).format("DD")} - {period_selected_day.day(7).format("DD")} {period_selected_day.day(1).format("MMMM")}{" "}
            {period_selected_day.day(1).format("YYYY")}
          </LoadingButton>
          <Button variant="outlined" sx={{ padding: "0 0", minWidth: "36px" }} onClick={() => handleNextWeek(7)} disabled={loading_list}>
            <ArrowBackIcon sx={{ transform: "rotate(180deg)" }} />
          </Button>
        </div>
        <Divider sx={{ margin: "16px -8px" }} />
        <div style={{ textAlign: "center" }}>
          {dateButtonsOptions.map((item, idx) => (
            <div key={item?.uuid}>
              <DayMenuItem currentDateOptions={period_days_data[idx]} handleCloseDialog={handleCloseDialog} handleSwipeToSlide={handleSwipeToSlide} />
              <Divider sx={{ margin: idx !== dateButtonsOptions?.length - 1 ? "0 0" : "0 -8px" }} />
            </div>
          ))}
        </div>

        <CustomDateCalendar
          open={openDateModal}
          setOpen={setOpenDateModal}
          periodSelectedDate={period_selected_day}
          handleCustomDateCalendar={handleCustomDateCalendar}
        />
      </DialogContent>
    </>
  );
};

import { useSelector, useDispatch } from "react-redux";
import { setIsFiltersDialog } from "../../redux/chat";
import { DialogModal } from "../DialogModal/DialogModal";
import { Content } from "./Content";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ProjectFilter } from "./FilteBlocks/ProjectFilter";
import { IssueFilter } from "./FilteBlocks/IssueFilter";
import { filterDialogTypesArr } from "../../redux/chat";
import { ProjectFilterButton } from "./FilteBlocks/ProjectFilterButton";
import { IssueFilterButton } from "./FilteBlocks/IssueFilterButton";
import { ListItem, Divider, Typography, Paper } from "@mui/material";

export const ChatFiltersDialog = () => {
  const dispatch = useDispatch();
  const { is_filters_dialog, filters_dialog_type } = useSelector((state) => state.chat);
  const { replyData, newMessageBlockHeight } = useSelector((state) => state.chat);
  const { is_open_comment_form } = useSelector((state) => state.comments);
  const { show_footer_in_chat } = useSelector((state) => state.settings);

  const handleClose = () => {
    dispatch(setIsFiltersDialog(false));
  };

  const currentContent =
    (filterDialogTypesArr[0] === filters_dialog_type && <ProjectFilter setOpen={handleClose} />) ||
    (filterDialogTypesArr[1] === filters_dialog_type && <IssueFilter setOpen={handleClose} />);

  return (
    <>
      <Paper
        elevation={2}
        style={{
          position: "fixed",
          top: "61px",
          right: "-4px",
          background: "white",
          borderRadius: "18px 0 0 18px",
          zIndex: "100"
        }}
      >
        <ProjectFilterButton />
      </Paper>
      <Paper
        elevation={2}
        style={{
          position: "fixed",
          top: `calc(50vh - 57px + 27px ${replyData ? "- 34px" : ""} ${newMessageBlockHeight ? `- ${newMessageBlockHeight / 2}px` : ""} ${
            show_footer_in_chat ? "- 32px" : ""
          })`,
          right: "-4px",
          background: "white",
          borderRadius: "18px 0 0 18px",
          zIndex: "100"
        }}
      >
        <IssueFilterButton />
      </Paper>
      <DialogModal open={is_filters_dialog && !is_open_comment_form} setOpen={handleClose} content={currentContent} />
    </>
  );
};

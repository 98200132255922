import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import { Stack, InputAdornment, TextField, Tooltip, Box, Button, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";

import "./SearchComponent.css";
// ----------------------------------------------------------------------

export default function SearchComponent(props) {
  const {
    loading,
    fullWidth,
    filterStartDate,
    filterEndDate,
    filterTitle,
    onFilterTitle,
    initFunc,
    fieldValueFlag,
    onFilterStartDate,
    onFilterEndDate,
    handleResetSearchMode,
    disabledProps,
    isInvoice,
    isLocalSearch,
    variant,
    inDev
  } = props;

  const [title, setTitle] = useState("");

  const handleKey = (e) => {
    // console.log("handleKey", e.code);
    switch (e.code) {
      // case "Enter": {
      //   if (checkSearch) {
      //     onFilterTitle(e.target.value);
      //     break;
      //   }
      // }
      case "Escape":
        handleReset();
        break;
    }
  };

  useEffect(() => {
    if (fieldValueFlag) {
      // console.log("fieldValueFlag");
      setTitle(fieldValueFlag);
    }
  }, [fieldValueFlag]);

  useEffect(() => {
    const timer = setTimeout(() => (!!onFilterTitle ? onFilterTitle(title) : ""), 1000);
    return () => clearTimeout(timer);
  }, [title]);

  const checkSearch = title?.length >= 3 && title !== filterTitle;
  const checkReset = title?.length > 0 || filterTitle?.length > 0;

  const checkWarnInput = title?.length < 3 && title?.length > 0;

  const handleSearch = () => {
    if (checkSearch) {
      onFilterTitle(title);
    }
  };

  const handleReset = () => {
    // console.log("handleReset", checkReset);
    if (checkReset) {
      setTitle("");
      if (!!onFilterTitle) {
        onFilterTitle("");
      }
    }
  };

  const handleRefresh = () => {
    // setTitle("");
    // onFilterTitle("");
    initFunc();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          position: "relative",
          width: "100%"
        }}
      >
        <TextField
          // autoFocus
          disabled={disabledProps || inDev}
          fullWidth={fullWidth}
          size="small"
          placeholder={`Search`}
          variant={variant ? variant : "standard"}
          color="info"
          sx={{
            // background: "rgb(237, 247, 237)",
            width: fullWidth ? "100%" : "auto",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: `16px`
              }
              // "& .MuiInputBase": {
              //   padding: "0 0"
              // }
            }
          }}
          value={title}
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => {
            // console.log(e.target.value);
            e.stopPropagation();
            handleKey(e);
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={(event) => setTitle(event.target.value)}
          inputProps={{
            style: {
              fontSize: variant ? "16px" : "20px"
            }
          }}
          InputProps={{
            autoComplete: "off",
            disableUnderline: true,
            startAdornment: !variant ? "" : <SearchIcon sx={{ marginRight: "6px", color: !!filterTitle ? "#1976d2" : "gray" }} />,
            endAdornment: (
              <div className={checkReset || loading ? "reset_icon_wrapper active" : "reset_icon_wrapper"}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{
                    margin: "0 0 0px 6px",
                    padding: "0 0",
                    minWidth: "28px",
                    height: "28px",
                    borderRadius: "14px"
                  }}
                  onClick={handleReset}
                  disabled={disabledProps}
                  loading={loading}
                >
                  <CloseIcon />
                </LoadingButton>
              </div>
            )
          }}
        />
      </div>
    </>
  );
}

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedDay, setSelectedDayUuid } from "../../redux/weekPlanning";
import { ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import dayjs from "dayjs";

export const DayMenuItem = ({ currentDateOptions, handleCloseDialog, handleSwipeToSlide }) => {
  const dispatch = useDispatch();
  const { selected_day, selected_day_uuid, loading_issues, loading_list } = useSelector((state) => state.weekPlanning);

  const [activities, setActivities] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [isSelected, setIsSelected] = useState(false);

  const [options, setOptions] = useState(null);

  const handleSetSelectedDay = () => {
    // console.log("handleSetSelectedDay");
    // dispatch(setSelectedDay(currentDateOptions?.date));
    // dispatch(setSelectedDayUuid(currentDateOptions?.uuid));
    handleSwipeToSlide(currentDateOptions);
    setTimeout(() => {
      handleCloseDialog();
    }, 250);
  };

  useEffect(() => {
    if (selected_day) {
      // console.log("currentDateOptions", currentDateOptions?.date.day());
      // console.log("selected_day", selected_day.day());
      setIsSelected(currentDateOptions?.date.day() === selected_day.day());
    }
  }, [selected_day, currentDateOptions?.uuid]);

  useEffect(() => {
    setActivities(currentDateOptions?.issues);
  }, [currentDateOptions?.uuid]);

  const isToday = dayjs().format("DD-MM-YYYY") === currentDateOptions?.date.format("DD-MM-YYYY");
  const currentDayDate = currentDateOptions?.date.format("DD");
  const currentDayShortName = currentDateOptions?.date.format("dddd");
  const isWeekend = currentDateOptions?.date.day() === 6 || currentDateOptions?.date.day() === 0;

  return (
    <ListItemButton onClick={handleSetSelectedDay} sx={{ bgcolor: isSelected ? "rgb(229, 246, 253)" : "transparent" }} disableGutters>
      <ListItem
        secondaryAction={
          <span>
            {activities?.length ? (
              <>
                {/* {activities.filter((issue) => !!issue?.completed)?.length} / {activities?.length} */}
                {activities?.length}
              </>
            ) : (
              "empty"
            )}
          </span>
        }
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: isToday ? "#0288d1" : isWeekend ? "#2e7d32" : "gray" }}>
            {loading_issues || loading_list ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : (
              currentDayDate || <CircularProgress size={16} sx={{ color: "white" }} />
            )}
            {/* {loading_issues || loading_list ? <CircularProgress size={16} sx={{ color: "white" }} /> : currentDayDate} */}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>{currentDayShortName}</ListItemText>
      </ListItem>
    </ListItemButton>

    // <Button
    //   fullWidth
    //   sx={{ marginBottom: "16px" }}
    //   color={isToday ? "primary" : isWeekend ? "success" : "neutral"}

    //   onClick={handleSetSelectedDay}
    //   // disabled={parentFetching}
    // >

    //   {currentDateOptions?.issues?.length ? (
    //     <>
    // {currentDateOptions?.issues.filter((issue) => !!issue?.completed)?.length} /{" "}
    // {currentDateOptions?.issues?.length}
    //     </>
    //   ) : (
    //     "empty"
    //   )}
    // </Button>
  );
};

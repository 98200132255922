import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

import { Typography, ListItem, ListItemText, Popover, Avatar, ListItemAvatar, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import dayjs from "dayjs";
import { front_date_with_time } from "../../utils/constants";
import useLongPress from "../../utils/useLongPress";
import { MessageActions } from "../MessageActions/MessageActions";
import "./index.css";
import CheckIcon from "@mui/icons-material/Check";
import { entityDataType } from "../../utils/entityDataType";
import { entity_data_types } from "../../config/entity_data_types";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

export const ChatCommentComponent = ({ message }) => {
  // console.log("message", message);
  const dispatch = useDispatch();

  const { current_user } = useSelector((state) => state.auth);

  const [isViewed, setIsViewed] = useState(message?.is_viewed);

  const comment_data = !!message?.params?.entity?.project_uuid ? message?.params?.entity : null;
  const entity_data = comment_data
    ? { type: comment_data?.assigned_entity_type, id: comment_data?.assigned_entity_id, title: comment_data?.assigned_entity_title }
    : null;

  const isMessageActionsAvailable = Object.keys(entity_data_types).some((key) => key === entity_data?.type) && isInbox;

  const reply_data = message?.params?.reply;
  const senderData = message?.uinfo;

  const isInbox = message?.uinfo?.email !== current_user?.email;

  const wrapper = (text, color) => <div style={{ whiteSpace: "normal", wordWrap: "break-word", color: color }}>{text}</div>;

  const messageText = () => {
    let color = "black";
    let text = message?.message;

    if (typeof text === "string") {
      if (!text?.length && !!message?.files?.length) {
        color = "rgb(25, 118, 210)";
        text = "An empty message with attached files.";
      }
    } else {
      color = "#d32f2f";
      text = "Error when displaying the message.";
    }
    return wrapper(text, color);
  };

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: isInbox ? "flex-start" : "flex-end", alignItems: "flex-end" }}>
        {isInbox && (
          <div style={{ marginBottom: "6px" }}>
            <Avatar sx={{ bgcolor: "rgb(25, 118, 210)" }}>
              {senderData?.first_name?.[0] || ""}
              {senderData?.last_name?.[0] || ""}
            </Avatar>
          </div>
        )}
        <ListItem
          sx={{
            position: "relative",
            padding: isInbox ? "0 0 0 10px" : "0 10px 0 0 ",
            "-webkit-user-select": "none",
            " -moz-user-select": "none",
            "-ms-user-select": "none"
          }}
          // {...longPressEvent}
        >
          <div className={isInbox ? "message_block_triangle_inbox" : "message_block_triangle_sent"}></div>
          <ListItemText
            sx={{
              padding: isInbox ? "12px 32px 24px 8px" : "12px 24px 24px 8px",
              // border: "1px solid rgba(128,128,128,.3)",
              minHeight: "64px !important",
              borderRadius: isInbox ? "16px 16px 16px 0" : "16px 16px 0 16px",
              background: isInbox ? "rgb(240, 240, 240)" : "rgb(237, 247, 237)"
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "250px",
                color: isInbox ? "rgb(25, 118, 210)" : "#2e7d32",
                fontWeight: "600"
              }}
            >
              {senderData?.first_name} {senderData?.last_name}
            </Typography>
            {/* {comment_data && <EntityTitleComponent type={entity_data?.type} id={entity_data?.id} title={entity_data?.title} />} */}
            {reply_data?.text && (
              <div
                style={{
                  margin: "0 0 6px 6px",
                  padding: "4px 4px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  borderLeft: "3px solid #1976d2",
                  background: "rgb(210, 210, 210)",
                  borderRadius: "0 6px 6px 0"
                }}
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "200px",
                    color: reply_data?.actorUUID !== current_user?.user_uuid ? "black" : "#2e7d32",
                    fontWeight: "600"
                  }}
                >
                  {reply_data?.actorName}
                </Typography>
                {reply_data?.text}
              </div>
            )}
            {messageText()}

            {isMessageActionsAvailable && <MessageActions message={message} />}
            <div style={{ position: "absolute", bottom: "4px", right: isInbox ? "12px" : "16px", fontSize: "14px" }}>
              {dayjs(message?.created).format(front_date_with_time)}
            </div>
            {isViewed && isInbox && (
              <CheckIcon color="success" sx={{ position: "absolute", bottom: "6px", right: isInbox ? "8px" : "12px", fontSize: "18px" }} />
            )}
          </ListItemText>
        </ListItem>
        {/* {!isInbox && (
          <div style={{ marginBottom: "6px" }}>
            <Avatar sx={{ bgcolor: "#2e7d32" }}>
              {senderData?.first_name[0]}
              {senderData?.last_name[0]}
            </Avatar>
          </div>
        )} */}
      </div>
    </>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReplyData } from "../../redux/chat";

import { ListItem, IconButton, Typography, Alert } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PolylineIcon from "@mui/icons-material/Polyline";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

export const ReplyMessageBlock = () => {
  const dispatch = useDispatch();

  const { replyData } = useSelector((state) => state.chat);

  const handleResetReplyData = () => {
    dispatch(setReplyData(null));
  };

  useEffect(() => {
    // console.log("replyData", replyData);
  }, [replyData]);

  const entityContent = (
    <>
      {replyData?.params?.entity?.assigned_entity_type === "project" && <AccountTreeIcon color="primary" sx={{ fontSize: "18px" }} />}
      {replyData?.params?.entity?.assigned_entity_type === "vector" && <PolylineIcon color="primary" sx={{ fontSize: "18px" }} />}
      {replyData?.params?.entity?.assigned_entity_type === "issue" && <AssignmentIcon color="primary" sx={{ fontSize: "18px" }} />}
      {replyData?.params?.entity?.assigned_entity_id} {replyData?.params?.entity?.assigned_entity_title}
    </>
  );

  return (
    <>
      {replyData && (
        <Alert severity="info" icon={false} style={{ padding: "6px 16px" }} onClose={handleResetReplyData}>
          <EntityTitleComponent
            type={replyData?.params?.entity?.assigned_entity_type}
            id={replyData?.params?.entity?.assigned_entity_id}
            title={replyData?.params?.entity?.assigned_entity_title}
          />
          <div className="title_truncate">{replyData?.message}</div>
        </Alert>
      )}
    </>
  );
};

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { aboutInfoRequest } from '../../redux/auth';
import { setIsTokenError } from '../../redux/notify';
import { useState, useEffect } from 'react';

import { Alert, Typography, ListItem, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { notify_url } from '../../config/url';
import { appRoutes } from '../../utils/constants';

export const WrongAccountBiom = ({ setWrongAccountBiom }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { current_user } = useSelector((state) => state.auth);

  const [biomData, setBiomData] = useState(null);

  const handleBiomData = async () => {
    const about_data = await dispatch(aboutInfoRequest({ url: `${notify_url}/about/` })).unwrap();
    setBiomData(about_data);
  };

  const handleNavigate = () => {
    setWrongAccountBiom(false);
    dispatch(setIsTokenError(false));
    navigate(appRoutes.accounts.path);
  };

  useEffect(() => {
    handleBiomData();
  }, []);

  return (
    <Alert severity='error' sx={{ marginTop: '200px' }} icon={<ErrorOutlineIcon sx={{ marginTop: '6px' }} />}>
      <Typography variant='subtitle1' color='error'>
        Wrong account biom
      </Typography>
      <ListItem sx={{ padding: '6px 0' }}>
        Current account biom: <strong> {current_user?.biom?.biom_name}</strong>
      </ListItem>
      <ListItem sx={{ padding: '6px 0' }}>
        Ecosystem W54 biom: <strong> {biomData?.biom_name}</strong>
      </ListItem>
      <div style={{ marginTop: '16px', textAlign: 'center' }}>
        <Button fullWidth variant='contained' onClick={handleNavigate}>
          To the Accounts
        </Button>
      </div>
    </Alert>
  );
};

import { MessagesBlock } from "./Blocks/MessagesBlock";
import { ChatFiltersDialog } from "../../components/ChatFiltersDialog/ChatFiltersDialog";
import "./index.css";

export const ChatPage = () => {
  return (
    <>
      <MessagesBlock />
      <ChatFiltersDialog />
    </>
  );
};

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DialogModal } from "../../../components/DialogModal/DialogModal";
import { DialogTitle, DialogContent, DialogActions, IconButton, Divider, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "dayjs/locale/en-au";

export const CustomDateCalendar = ({ open, setOpen, periodSelectedDate, handleCustomDateCalendar }) => {
  const handleChangeDatePicker = (val) => {
    handleCustomDateCalendar(val);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const content = (
    <>
      <DialogTitle sx={{ padding: "8px 24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>Choose a week</div>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ marginTop: "16px", padding: "0 0" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
          <StaticDatePicker
            value={periodSelectedDate}
            onChange={handleChangeDatePicker}
            displayWeekNumber={true}
            orientation="landscape"
            sx={{
              width: "100%",
              "& .MuiPickersToolbar-root": { display: "none" },
              "& .MuiDialogActions-root": { display: "none" },
              "& .MuiPickersDay-today": {
                background: "#1976d2"
              },
              "& .MuiDayCalendar-weekContainer:has(> .Mui-selected)": {
                "& .MuiPickersDay-root": {
                  background: "rgba(205, 218, 228, 0.945)"
                }
              }
            }}
          />
        </LocalizationProvider>
      </DialogContent>
    </>
  );

  return <DialogModal open={open} setOpen={setOpen} content={content} />;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url, notify_url } from "../config/url";
import { checkToken } from "../utils/checkToken";

export const getVAPIDPublicKey = createAsyncThunk("pushSubscription/getVAPIDPublicKey", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().get(`${notify_url}/subscription-key/`, {
      headers: {
        "Session-token": token
      }
    });
    // console.log("response", response);
    return response?.data?.public_key;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const sendPushSubscription = createAsyncThunk("pushSubscription/sendPushSubscription", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { subscription_data, token, url } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("props", props);

    const response = await createAxios().post(
      `${url}/subscription-data/`,
      { subscription_data: subscription_data },
      {
        headers: {
          "Session-token": token
        }
      }
    );
    // console.log("response", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const pushSubscriptionSlice = createSlice({
  name: "pushSubscription",
  initialState: {
    public_key: null,
    push_subscription: null,
    loading: false
  },
  reducers: {
    setPushSubscription: (state, action) => {
      state.push_subscription = action.payload;
    }
    // setBiomUsersWithMessages: (state, action) => {
    //   // console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // }
  },
  extraReducers: {
    // ---------- getVAPIDPublicKey -----------------
    [getVAPIDPublicKey.pending]: (state, action) => {
      state.loading = true;
    },
    [getVAPIDPublicKey.fulfilled]: (state, action) => {
      // console.log("getVAPIDPublicKey", action?.payload);
      state.loading = false;
      state.public_key = action?.payload;
    },
    [getVAPIDPublicKey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- sendPushSubscription -----------------
    [sendPushSubscription.pending]: (state, action) => {
      state.loading = true;
    },
    [sendPushSubscription.fulfilled]: (state, action) => {
      state.loading = false;
      // state.push_subscription = action?.payload;
    },
    [sendPushSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
// export const {  } = usersSlice.actions;
export default pushSubscriptionSlice.reducer;

import { ListItem, ListItemIcon, ListItemButton, ListItemText, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export const ItemComponent = ({ isSelected, val, label, icon, callback, noBackground, isMaxProjects }) => {
  const handleCallback = () => {
    callback(val);
  };

  return (
    <ListItemButton disableGutters sx={{ padding: "0 0" }} disabled={!!isMaxProjects && !isSelected}>
      <ListItem
        onClick={handleCallback}
        color={isSelected ? "primary" : "default"}
        secondaryAction={
          <div style={{ paddingTop: "6px" }}>{isSelected ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}</div>
        }
        sx={{
          background: isSelected && !noBackground ? "rgba(25, 118, 210, .1)" : "transparent"
        }}
      >
        <ListItemIcon>
          <div
            style={{
              color: isSelected ? "#1976d2" : "gray",
              fontWeight: isSelected ? "600" : "500"
            }}
          >
            {icon}
          </div>
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "calc(100% - 2px)",
              overflow: "hidden"
            }}
          >
            {label}
          </Typography>
        </ListItemText>
      </ListItem>
    </ListItemButton>
  );
};

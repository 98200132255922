import dayjs from "dayjs";
import { front_date_with_time } from "../utils/constants";

export const checkUpdateTime = () => {
  const last_push_subscription_update = localStorage.getItem("last_push_subscription_update");
  if (!!last_push_subscription_update) {
    const date1 = dayjs(last_push_subscription_update);
    const date2 = dayjs();
    const difference = date2.diff(date1);
    console.log("difference", difference);
    return difference > 1000 * 60 * 60 * 2; // update subscription every 2 hours when start the app
  } else {
    return true;
  }
};

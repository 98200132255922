import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

// ------------ API --------------
import { getAllEntityCommentsUuids, getAssignedEntityFullData } from "../../../redux/comments";
import { getAllEntityComments } from "../../../redux/comments";
import { setComments, setEntityData } from "../../../redux/comments";
import { getCurrentCommentsProject, setCurrentCommentProjectData, setCurrentCommentProject } from "../../../redux/projects";
import { checkEntityPerms, getCurrentCommentsProjectUsers } from "../../../redux/projects";
import { setIsFiltersDialog } from "../../../redux/chat";

// ------------ component --------------
import { AssignedEntityBlock } from "./AssignedEntityBlock/AssignedEntityBlock";
import { ChatCommentComponent } from "../../../components/ChatMessageComponent/ChatCommentComponent ";
import { ChatMessageComponent } from "../../../components/ChatMessageComponent/ChatMessageComponent";
import { NoData, NO_MESSAGES } from "../../../components/ChatFiltersDialog/NoData";
import { NewCommentBlock } from "./NewCommentBlock";
import { SubGroup } from "../../../components/Subgroup/Subgroup";
import { ModalBlock } from "../../../components/NewMessageFormBlocks/ModalBlock";
import { DialogModal } from "../../../components/DialogModal/DialogModal";

import { DialogTitle, DialogContent, Typography, IconButton, Divider } from "@mui/material";

import { ListItem, ListItemIcon, ListItemText, CircularProgress, Button } from "@mui/material";
import { EntityTitleComponent } from "../../../components/EntityTitleComponent/EntityTitleComponent";

// ------------ validation --------------
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// ------------ icons -------------------
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";

// ------------ const -------------------
import { checkToken } from "../../../utils/checkToken";
import { newMessageModalTypes } from "../../../components/NewMessageFormBlocks/ModalBlock";
import { LoadingButton } from "@mui/lab";

export const FormContent = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);

  const { project_filter, issue_filter } = useSelector((state) => state.comments);

  const { pm_partition, pub_partition } = useSelector((state) => state.settings);

  const { comments, entity_data, loading, newMessageBlockHeight: newCommentBlockHeight } = useSelector((state) => state.comments);
  const { current_comment_project_data, current_comments_project_users } = useSelector((state) => state.projects);

  const [openModal, setOpenModal] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [fetching, setFetching] = useState(false);
  const [commentsData, setCommentsData] = useState([]);

  const firstMessageRef = useRef(null);
  const wrapperRef = useRef(null);
  const observerRef = useRef(null);

  const previousY_Ref = useRef(0);
  const previousRatio_Ref = useRef(0);

  const [showToStartBtn, setShowToStartBtn] = useState(false);

  const entity_token = checkToken(current_user?.user_uuid, "entity");

  const defaultValues = {
    project: "default",
    issueType: "default",
    vector: "default"
  };

  const validationSchema = yup.object({
    project: yup.string().required("Required"),
    issueType: yup.string().test("check_activity", "Required", (val) => val !== "default"),
    vector: yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    mode: "onBlur",
    defaultValues: {
      ...defaultValues
    }
  });

  const { watch } = methods;
  const handleCloseDialog = () => {
    // console.log("handleCloseDialog");
    previousY_Ref.current = 0;
    previousRatio_Ref.current = 0;
    firstMessageRef.current = null;
    wrapperRef.current = null;
    setOpen(false);
  };

  const handleOpenModal = (bool) => {
    setOpenModal(bool);
    dispatch(setIsFiltersDialog(bool));
  };

  const getCommentsUuids = async (entity_data, bool) => {
    const data = {
      affix_type: "comment",
      entity_uuid: entity_data?.uuid,
      parent: entity_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      limit: limit,
      offset: page * limit,
      params: {},
      with_files: false
    };

    const req_data = {
      req_body: data,
      partition_uuid: entity_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: entity_token
    };

    const resp = await dispatch(getAllEntityCommentsUuids(req_data)).unwrap();
    setTotal(resp?.total);
    // console.log("resp", resp);
    // console.log("getCommentsUuids resp", resp);
    return resp;
  };

  const getComments = async (entity_uuid, uuids_arr, bool) => {
    // console.log("entity_uuid", entity_uuid);
    // console.log("uuids_arr", uuids_arr);

    const req_data = {
      req_body: {
        entity_uuid: entity_uuid,
        affix_type: "comment",
        affix_uuids: uuids_arr,
        with_files: false
        // total: 2
      },
      partition_uuid: entity_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: entity_token
    };
    if (uuids_arr?.length) {
      const resp = await dispatch(getAllEntityComments(req_data)).unwrap();
      // console.log("resp", resp);
      const modifiedData = resp.map((comment) => ({
        ...comment,
        created_at: comment?.created,
        message: comment?.params?.comment
      }));
      if (!bool) {
        setCommentsData((prev) => [...prev, ...modifiedData]);
      } else {
        setCommentsData(modifiedData);
      }
    }

    // console.log("getComments resp ", resp);
  };

  const getCurrentAssignedProjectData = async () => {
    const req_data = {
      req_body: {
        entity_uuid: current_comment_project_data?.uuid,
        entity_type: "project"
      },
      partition_uuid: current_comment_project_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: entity_token
    };
    const project_data = await dispatch(getCurrentCommentsProject(req_data)).unwrap();
    return project_data;
  };

  const checkCurrentAssignedProjectPerms = async () => {
    const req_data = {
      req_body: {
        entity_uuid: current_comment_project_data?.uuid,
        perm: []
      },
      partition_uuid: current_comment_project_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: entity_token
    };
    dispatch(checkEntityPerms(req_data));
  };

  useEffect(() => {
    if (current_comment_project_data?.uuid) {
      try {
        checkCurrentAssignedProjectPerms();
        getCurrentAssignedProjectData()
          .then(async (resp) => {
            const assigned_users_uuid_arr = Array.isArray(resp?.params?.usersSearch) ? resp?.params?.usersSearch : [];
            const req_data = {
              req_body: {
                uuids: assigned_users_uuid_arr
              },
              partition_uuid: current_comment_project_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
              token: entity_token
            };
            // console.log("assigned_users_uuid_arr", assigned_users_uuid_arr);
            dispatch(getCurrentCommentsProjectUsers(req_data));
          })
          .catch((e) => console.log("error", e));
      } catch (e) {
        console.log("e", e);
      }
    }
  }, [current_comment_project_data?.uuid]);

  useEffect(() => {
    if (issue_filter?.uuid) {
      dispatch(setEntityData({ uuid: issue_filter?.uuid, partition: issue_filter?.partition, type: "issue" }));
      dispatch(setCurrentCommentProjectData(issue_filter?.project_data));
    } else {
      if (project_filter?.uuid) {
        dispatch(setEntityData({ uuid: project_filter?.uuid, partition: project_filter?.partition, type: "project" }));
        dispatch(setCurrentCommentProjectData(project_filter));
      } else {
        dispatch(setEntityData(null));
        dispatch(setCurrentCommentProjectData(null));
        dispatch(setCurrentCommentProject(null));
      }
    }
    // console.log("issue_filter", issue_filter);
    // -----------------
  }, [project_filter?.uuid, issue_filter?.uuid]);

  const initCommentsFunction = async (bool) => {
    // if (bool) {
    //   setPage(0);
    // }
    // console.log("entity_data", entity_data);
    try {
      setFetching(true);
      await getCommentsUuids(entity_data, bool).then(async (resp) => {
        await getComments(entity_data, resp?.affix_uuids, bool);
      });
    } catch (e) {
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!!entity_data) {
      initCommentsFunction();
    } else {
      setCommentsData([]);
      dispatch(setComments([]));
    }
  }, [entity_data, page]);

  const getAssignedEntityData = () => {
    const req_data = {
      req_body: {
        entity_uuid: entity_data?.uuid,
        entity_type: entity_data?.type
      },
      partition_uuid: entity_data?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: entity_token
    };
    return dispatch(getAssignedEntityFullData(req_data));
  };

  useEffect(() => {
    if (entity_data?.uuid) {
      getAssignedEntityData();
    }
    setPage(0);
    setCommentsData([]);
  }, [entity_data?.uuid]);

  useEffect(() => {
    // console.log("current_comments_project_users", current_comments_project_users);
  }, [current_comments_project_users]);

  const scrollTo = () => {
    // const wrapper = wrapperRef?.current;
    // const wrapper = document.querySelector("#comment_wrapper");
    const wrapper = wrapperRef?.current;
    // console.log("wrapper", wrapper);
    // console.log("scrollTop", wrapper?.scrollTop);
    if (!!wrapper) {
      setShowToStartBtn(false);
      wrapper?.scrollTo(0, 0);
      // setTimeout(() => {
      //   wrapper.scrollIntoView({
      //     behavior: "smooth",
      //     block: "start"
      //   });
      // }, 50);
    }
  };

  useLayoutEffect(() => {
    if (wrapperRef?.current && firstMessageRef?.current && commentsData?.length) {
      // console.log("observer initialize");
      // const wrapper = document.querySelector("#comment_wrapper");
      const wrapper = wrapperRef?.current;
      // console.log("scrollTop", wrapper?.scrollTop);
      // console.log("firstMessageRef?.current", firstMessageRef?.current);
      const options = {
        root: wrapper,
        rootMargin: "0px",
        threshold: [0, 0.25, 0.5, 0.75, 1]
      };

      const callback = function (entries, observer) {
        const currentY = entries[0].boundingClientRect.y;
        const currentRatio = entries[0].intersectionRatio;
        const isIntersecting = entries[0].isIntersecting;
        // console.log("currentY", currentY);
        // console.log("currentRatio", currentRatio);
        // console.log("isIntersecting", isIntersecting);
        // Scrolling down/up
        if (currentY < previousY_Ref?.current) {
          // console.log("isIntersecting", isIntersecting);
          if (currentRatio > previousRatio_Ref.current && isIntersecting) {
            // state.textContent = "Scrolling down enter";
            // console.log("Scrolling down enter");
          } else {
            // console.log("Scrolling down leave TRUE");
            setShowToStartBtn(true);
            // state.textContent = "Scrolling down leave";
          }
        } else if (currentY > previousY_Ref?.current && isIntersecting) {
          if (currentRatio < previousRatio_Ref.current) {
            // state.textContent = "Scrolling up leave";
            // console.log("Scrolling up leave");
          } else {
            // console.log("Scrolling up enter FALSE");
            setShowToStartBtn(false);
            // state.textContent = "Scrolling up enter";
          }
        }

        previousY_Ref.current = currentY;
        previousRatio_Ref.current = currentRatio;
        /* Content excerpted, show below */
        // console.log("entries", entries);

        // const { time, rootBounds, boundingClientRect, intersectionRect, target, isIntersecting, isVisible, intersectionRatio } = entries[0];
        // console.log("isVisible", { time, rootBounds, boundingClientRect, intersectionRect, target, isIntersecting, isVisible, intersectionRatio });
        // console.log("isIntersecting", isIntersecting);

        // console.log("intersectionRatio", intersectionRatio);
        // console.log("oldIntersectionRatio.current", oldIntersectionRatio.current);
        // console.log("currentIntersectionRatio.current", currentIntersectionRatio.current);

        // oldIntersectionRatio.current = currentIntersectionRatio.current;
        // currentIntersectionRatio.current = intersectionRatio;
        // setShowToStartBtn(!isIntersecting);
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(firstMessageRef?.current);
    } else {
      previousY_Ref.current = 0;
      previousRatio_Ref.current = 0;
    }
  }, [wrapperRef?.current, firstMessageRef?.current, commentsData]);

  const handleMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <>
      <FormProvider {...methods}>
        <DialogTitle sx={{ padding: "8px 24px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>New comment</div>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Divider />
        <div
          style={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: loading ? "100" : "-5"
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <DialogContent sx={{ position: "relative", paddingBottom: "0", opacity: loading ? ".7" : "1" }} ref={wrapperRef} id={"comment_wrapper"}>
          <SubGroup label={"Options"} isBold={true} />

          {Object.keys(newMessageModalTypes).map((key) => (
            <ModalBlock type={newMessageModalTypes[key]} setCurrentContent={setCurrentContent} handleOpenModal={handleOpenModal} />
          ))}

          <SubGroup label={"Assigned to"} isBold={true} />

          <AssignedEntityBlock />

          {!project_filter?.uuid && !issue_filter?.uuid && (
            <div style={{ margin: "16px 16px", textAlign: "center" }}>
              Select a <AccountTreeIcon color="primary" sx={{ position: "relative", top: "3px", fontSize: "18px" }} /> project or{" "}
              <AssignmentIcon color="info" sx={{ position: "relative", top: "3px", fontSize: "18px" }} /> issue to send a comment to
            </div>
          )}
          <div style={{ margin: "8px 0" }}>
            <SubGroup label={`Recent comments`} isBold={true} />
          </div>

          {!project_filter?.uuid && !issue_filter?.uuid ? (
            <div style={{ margin: "16px 16px", textAlign: "center" }}>
              Select a <AccountTreeIcon color="primary" sx={{ position: "relative", top: "3px", fontSize: "18px" }} /> project or{" "}
              <AssignmentIcon color="info" sx={{ position: "relative", top: "3px", fontSize: "18px" }} /> issue to see the latest comments
            </div>
          ) : !!commentsData?.length ? (
            commentsData.map((comment, idx) => {
              const isInbox = comment?.uinfo?.email !== current_user?.email;
              return (
                <div
                  ref={idx === 0 ? firstMessageRef : null}
                  key={comment?.uuid}
                  style={{
                    margin: isInbox ? "0 48px 0 0" : "0 0 0 48px"
                  }}
                >
                  <ChatCommentComponent message={{ ...comment, isInbox: isInbox }} />
                </div>
              );
            })
          ) : (
            <div style={{ margin: "32px 0", width: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <MessageIcon color="primary" />
              </div>
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {"No comments to display"}
              </Typography>
            </div>
          )}
          {!!commentsData?.length && commentsData?.length < total && (
            <div style={{ position: "relative", margin: "8px 0", textAlign: "center" }}>
              <Divider sx={{ position: "absolute", top: "30px", width: "100%" }} />
              <div style={{ position: "relative", display: "inline-block", padding: "0 6px", backgroundColor: "white", zIndex: "1000" }}>
                <LoadingButton loading={fetching} onClick={handleMore} variant="outlined">
                  {commentsData?.length} / {total} <br />
                  Show more
                </LoadingButton>
              </div>
            </div>
          )}

          <div
            style={{
              position: "fixed",
              right: showToStartBtn ? "8px" : "-64px",
              bottom: `calc(196px ${newCommentBlockHeight ? `+ ${newCommentBlockHeight}px` : ""})`,
              display: "flex",
              justifyContent: "flex-end",
              zIndex: "20",
              transition: ".3s all ease-in-out"
            }}
          >
            <div style={{ backgroundColor: "white", borderRadius: "25px" }}>
              <Button variant="outlined" sx={{ padding: "0 0", minWidth: "50px", height: "50px", borderRadius: "25px" }} onClick={scrollTo}>
                <KeyboardArrowDownIcon sx={{ transform: "rotate(180deg)" }} />
              </Button>
            </div>
          </div>
        </DialogContent>
        {(project_filter?.uuid || issue_filter?.uuid) && <NewCommentBlock initCommentsFunction={initCommentsFunction} />}
      </FormProvider>
      <DialogModal open={openModal} setOpen={handleOpenModal} content={currentContent} />
    </>
  );
};

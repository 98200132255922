import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url } from "../config/url";
import dayjs from "dayjs";

export const getWeekVectorsUuids = createAsyncThunk("weekPlanning/getVectorsUuids", async (props, { rejectWithValue }) => {
  const { partition_uuid, req_body, token } = props;
  // console.log("getWeekVectorsUuids", req_body);
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/list`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.entity_uuids;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getWeekVectors = createAsyncThunk("weekPlanning/getWeekVectors", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/read/partial`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const createDateVector = createAsyncThunk("weekPlanning/createDateVector", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/create`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data[0];
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getDateVectorIssuesUuids = createAsyncThunk("weekPlanning/getDateVectorIssuesUuids", async (props, { rejectWithValue }) => {
  const { partition_uuid, req_body, token } = props;
  // console.log("getDateVectorIssuesUuids", req_body);
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/list`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data?.entity_uuids;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getDateVectorIssues = createAsyncThunk("weekPlanning/getDateVectorIssues", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/read/partial`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const createDateIssue = createAsyncThunk("weekPlanning/createDateIssue", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/create`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data[0];
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const updateDateIssue = createAsyncThunk("weekPlanning/updateDateIssue", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/update`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("updateDateIssue response", response);
    return response?.data[0];
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const deleteDateIssue = createAsyncThunk("weekPlanning/deleteDateIssue", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token, date_string } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/delete`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return { uuid: response?.data?.deleted[0], parent_date: date_string };
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const weekPlanningSlice = createSlice({
  name: "weekPlanning",
  initialState: {
    selected_day: dayjs(),
    selected_day_uuid: null,
    period_selected_day: dayjs(),
    period_selected_day_uuid: null,
    current_week_day_options: null,
    period_days_data: [],
    openPeriodModal: false,
    vectors_uuids: [],
    vectors: [],
    loading: false,
    loading_list: false,
    loading_issues: false,
    duplicate_for_tomorrow: null,
    duplicate_till_week_end: null,
    sync_data: null
  },
  reducers: {
    setLoadingIssues: (state, action) => {
      // console.log(action.payload?.uuid);
      state.loading_issues = action.payload;
    },
    setSelectedDay: (state, action) => {
      // console.log("setSelectedDay", action.payload?.format("DD-MM-YYYY"));
      state.selected_day = action.payload;
    },
    setPeriodSelectedDay: (state, action) => {
      // console.log(action.payload?.uuid);
      state.period_selected_day = action.payload;
    },
    setSelectedDayUuid: (state, action) => {
      // console.log(action.payload?.uuid);
      state.selected_day_uuid = action.payload;
    },
    setPeriodSelectedDayUuid: (state, action) => {
      // console.log(action.payload?.uuid);
      state.period_selected_day_uuid = action.payload;
    },
    setOpenPeriodModal: (state, action) => {
      // console.log(action.payload?.format("DD-MM-YYYY"));
      state.openPeriodModal = action.payload;
    },
    setPeriodDaysData: (state, action) => {
      // console.log(action.payload?.format("DD-MM-YYYY"));
      state.period_days_data = action.payload;
    },
    setCurrentWeekDayOptions: (state, action) => {
      // console.log("current_week_day_options", action.payload);
      state.current_week_day_options = action.payload;
    },
    setDuplicateTillWeekEnd: (state, action) => {
      // console.log(action.payload?.format("DD-MM-YYYY"));
      state.duplicate_till_week_end = action.payload;
    },
    setSyncData: (state, action) => {
      // console.log("setSyncData", action.payload);
      state.sync_data = action.payload;
    },
    setPeriodDateActivities: (state, action) => {
      // console.log(action.payload?.format("DD-MM-YYYY"));
      const new_period_date_data = action.payload;
      console.log("new_period_date_data", new_period_date_data);
      // state.period_days_data = action.payload;
    }

    // setBiomUsersWithMessages: (state, action) => {
    //   // console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // }
  },
  extraReducers: {
    // ---------- vector_uuids -----------------
    [getWeekVectorsUuids.pending]: (state, action) => {
      state.loading = true;
      state.loading_list = true;
    },
    [getWeekVectorsUuids.fulfilled]: (state, action) => {
      state.loading = false;

      state.vectors_uuids = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getWeekVectorsUuids.rejected]: (state, action) => {
      state.loading = false;
      state.loading_list = false;
      state.error = action?.payload?.message;
    },
    // ---------- vector_data -----------------
    [getWeekVectors.pending]: (state, action) => {
      state.loading = true;
      state.loading_list = true;
    },
    [getWeekVectors.fulfilled]: (state, action) => {
      state.loading = false;
      state.loading_list = false;
      state.vectors = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getWeekVectors.rejected]: (state, action) => {
      state.loading = false;
      state.loading_list = false;
      state.error = action?.payload?.message;
    },
    // ---------- vector_data -----------------
    [createDateVector.pending]: (state, action) => {
      state.loading = true;
    },
    [createDateVector.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createDateVector.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- vector_data -----------------
    [getDateVectorIssuesUuids.pending]: (state, action) => {
      state.loading = true;
      state.loading_issues = true;
    },
    [getDateVectorIssuesUuids.fulfilled]: (state, action) => {
      state.loading = false;
      const checkIsNoUuuids = !action?.payload?.length;
      if (checkIsNoUuuids) {
        state.loading_issues = false;
      }
    },
    [getDateVectorIssuesUuids.rejected]: (state, action) => {
      state.loading = false;
      state.loading_issues = false;
      state.error = action?.payload?.message;
    },
    // ---------- vector_data -----------------
    [getDateVectorIssues.pending]: (state, action) => {
      state.loading = true;
      state.loading_issues = true;
    },
    [getDateVectorIssues.fulfilled]: (state, action) => {
      // console.log("getDateVectorIssues", action?.payload);
      const issues = action?.payload;
      if (!!issues?.length) {
        const currentDayData = state.period_days_data.find((day) => day?.date.format("YYYY-MM-DD") === issues[0]?.date);
        const newDayData = { ...currentDayData, issues: issues };
        state.period_days_data = state.period_days_data.map((day) => (day?.date.format("YYYY-MM-DD") === issues[0]?.date ? newDayData : day));
      }
      state.loading = false;
      state.loading_issues = false;
    },
    [getDateVectorIssues.rejected]: (state, action) => {
      state.loading = false;
      state.loading_issues = false;
      state.error = action?.payload?.message;
    },
    // ---------- update -----------------
    [createDateIssue.fulfilled]: (state, action) => {
      // console.log("createDateIssue", action?.payload);
      const newIssueEntityData = action.payload;
      const { uuid: issue_uuid, parent: parent_uuid, params } = newIssueEntityData;
      const { completed, date, title, weight } = params;
      const currentDayData = state.period_days_data.find((day) => day?.uuid === parent_uuid);
      const { issues } = currentDayData;
      const newIssuesData = [...issues, { uuid: issue_uuid, completed, date, title, weight }];
      const newDayData = { ...currentDayData, issues: newIssuesData };
      const newPeriodDaysData = state.period_days_data.map((day) => (day?.uuid === parent_uuid ? newDayData : day));
      state.period_days_data = newPeriodDaysData;
    },

    // ---------- update -----------------
    [updateDateIssue.fulfilled]: (state, action) => {
      const newIssueEntityData = action.payload;
      const { uuid: issue_uuid, parent: parent_uuid, params } = newIssueEntityData;
      const { completed, date, title, weight } = params;
      const currentDayData = state.period_days_data.find((day) => day?.uuid === parent_uuid);
      const { issues } = currentDayData;
      const newIssuesData = issues.map((issue) => (issue?.uuid === issue_uuid ? { uuid: issue_uuid, completed, date, title, weight } : issue));
      const newDayData = { ...currentDayData, issues: newIssuesData };
      const newPeriodDaysData = state.period_days_data.map((day) => (day?.uuid === parent_uuid ? newDayData : day));
      // console.log("newPeriodDaysData", newPeriodDaysData);
      state.period_days_data = newPeriodDaysData;
    },
    // ---------- update -----------------
    [deleteDateIssue.fulfilled]: (state, action) => {
      const { uuid, parent_date } = action.payload;
      const currentDayData = state.period_days_data.find((day) => day?.date.format("YYYY-MM-DD") === parent_date);

      const { issues } = currentDayData;
      const newIssuesData = issues.filter((issue) => issue?.uuid !== uuid);
      const newDayData = { ...currentDayData, issues: newIssuesData };
      state.period_days_data = state.period_days_data.map((day) => (day?.date.format("YYYY-MM-DD") === parent_date ? newDayData : day));
    }
  }
});
export const {
  setLoadingIssues,
  setSelectedDay,
  setPeriodSelectedDay,
  setSelectedDayUuid,
  setPeriodSelectedDayUuid,
  setOpenPeriodModal,
  setPeriodDaysData,
  setCurrentWeekDayOptions,
  setSyncData,
  setPeriodDateActivities
} = weekPlanningSlice.actions;
export default weekPlanningSlice.reducer;

import { useEffect, useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MessageIcon from "@mui/icons-material/Message";
import SmsIcon from "@mui/icons-material/Sms";
import { Typography } from "@mui/material";

export const NO_PROJECTS = "NO_PROJECTS";
export const NO_MENTIONED_PROJECTS = "NO_MENTIONED_PROJECTS";
export const NO_ISSUES = "NO_ISSUES";
export const NO_MESSAGES = "NO_MESSAGES";
export const NO_CHATS = "NO_CHATS";

export const NoData = ({ type }) => {
  const [currentType, setCurrentType] = useState(type);
  const noProjects = "No projects to display";
  const noMentionedProjects =
    "No mentioned projects to display.\n To display the list of projects, you must be mentioned in the comments to the projects in the WMS application.";
  const noIssues = "No issues to display";
  const noMessages = "No messages to display";
  const noChats = "No chats to display";

  useEffect(() => {
    // console.log("type", type);
    setCurrentType(type);
  }, [type]);

  return (
    <>
      {currentType === NO_PROJECTS && (
        <div style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <AccountTreeIcon color="primary" />
          </div>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {noProjects}
          </Typography>
        </div>
      )}
      {currentType === NO_ISSUES && (
        <div style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <AssignmentIcon color="primary" />
          </div>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {noIssues}
          </Typography>
        </div>
      )}
      {currentType === NO_MESSAGES && (
        <div style={{ width: "100%", marginTop: "calc(50vh - 200px)" }}>
          <div style={{ textAlign: "center" }}>
            <MessageIcon color="primary" />
          </div>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {noMessages}
          </Typography>
        </div>
      )}
      {currentType === NO_CHATS && (
        <div style={{ width: "100%", marginTop: "calc(50vh - 200px)" }}>
          <div style={{ textAlign: "center" }}>
            <SmsIcon color="primary" />
          </div>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {noChats}
          </Typography>
        </div>
      )}

      {currentType === NO_MENTIONED_PROJECTS && (
        <div style={{ marginTop: "32px", width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <AccountTreeIcon color="primary" />
          </div>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {noMentionedProjects}
          </Typography>
        </div>
      )}
    </>
  );
};

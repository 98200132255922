import { store } from "../redux/store";
import { sendPushSubscription } from "../redux/pushSubscription";
import { setIsPushSubscribe } from "../redux/settings";
import { updateSubscriptionOnServer } from "./updateSubscriptionOnServer";
import { updateSubscriptionTime } from "./updateSubscriptionTime";

export const handleUnsubscribe = async (notify_token, fetchingCallback, without_notify, url) => {
  const dispatch = store.dispatch;
  try {
    if (!!fetchingCallback) {
      fetchingCallback(true);
    }
    return await navigator.serviceWorker.ready.then(
      async (registration) =>
        await Notification.requestPermission(
          async () =>
            await registration.pushManager
              .getSubscription()
              .then(async function (subscription) {
                if (subscription) {
                  // alert(`handleUnsubscribe type of pushSubscription: ${typeof {}}`);
                  return await updateSubscriptionOnServer({}, notify_token, without_notify, url)
                    .then(async () => {
                      updateSubscriptionTime("isReset");
                      dispatch(setIsPushSubscribe(false));
                      return await subscription.unsubscribe();
                    })
                    .catch((e) => console.log("handleUnsubscribe cant update subscription", e));
                }
              })
              .catch(function (error) {
                console.log("Error unsubscribing", error);
              })
        )
    );
  } catch (e) {
    console.log("e", e);
  } finally {
    if (!!fetchingCallback) {
      fetchingCallback(false);
    }
  }
};

import { useEffect, useState } from 'react';

// @mui
import { Alert, Button, Card, CardContent, Container, Collapse, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// routing
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../utils/constants';

// utils
import {
  isAndroid,
  isBrowser,
  isChrome,
  isChromium,
  isDesktop,
  isEdge,
  isEdgeChromium,
  isIOS,
  isMacOs,
  isMobileSafari,
  isSafari,
  isSamsungBrowser,
} from 'react-device-detect';
import getPWADisplayMode from '../../utils/getPWADisplayMode';
import { app_version } from '../../utils/constants';
// import { PWA_URL } from "../config";

export const InstallPage = ({ promptable, promptToInstall, isInstalled }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const ShareIcon = (props) => (
    <SvgIcon {...props}>
      <path d='M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z' />
    </SvgIcon>
  );

  const PlusBoxIcon = (props) => (
    <SvgIcon {...props}>
      <path
        fill='currentColor'
        d='M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7Z'
      />
    </SvgIcon>
  );

  const [installStatus, setInstallStatus] = useState('Initializing the installation');

  const PWADisplayMode = getPWADisplayMode();

  useEffect(() => {
    if (isInstalled && PWADisplayMode !== 'standalone') {
      // console.log("isInstalled", isInstalled);
      // console.log("PWADisplayMode", PWADisplayMode);
      navigate(appRoutes.accounts.path);
    }
  }, [isInstalled, PWADisplayMode]);

  useEffect(() => {
    setTimeout(() => {
      setInstallStatus('Install the app');
    }, 10000);
  }, [promptable]);

  return (
    <Container maxWidth={'md'}>
      <h1 style={{ textAlign: 'center', color: '#1976d2' }}>
        Ecosystem W54
        <br />v {app_version}
      </h1>
      {(isDesktop && isBrowser && isChrome) ||
      isChromium ||
      isEdgeChromium ||
      (isMacOs && isDesktop && isBrowser && isChrome) ||
      isChromium ||
      isEdgeChromium ||
      (isAndroid && isChrome) ||
      isSamsungBrowser ||
      isEdge ||
      (isIOS && isSafari) ||
      isMobileSafari ? (
        <Card sx={{ my: 4 }} elevation={0}>
          {(installStatus || promptable) && PWADisplayMode === 'browser' ? (
            <CardContent>
              {!isIOS ? (
                <>
                  <Typography variant='h5' mb={1}>
                    Install the PWA application on a smartphone with iOS or Android
                  </Typography>
                  <Typography variant='body1' color='text.primary' mb={2}>
                    The installation time depends on the speed of your Internet connection, but usually takes no more
                    than 1-2 minutes.
                  </Typography>
                  <Collapse
                    in={installStatus === 'Initializing the installation'}
                    timeout='auto'
                    sx={{ transition: '.3s' }}
                  >
                    <Alert severity='info' sx={{ alignItems: 'center' }}>
                      Please wait for the initialization of the installation to finish.
                    </Alert>
                  </Collapse>

                  <LoadingButton
                    fullWidth
                    variant='contained'
                    onClick={promptToInstall}
                    loading={installStatus === 'Initializing the installation'}
                    disabled={installStatus === 'Initializing the installation'}
                    sx={{ marginTop: '16px' }}
                  >
                    {installStatus}
                  </LoadingButton>
                </>
              ) : (
                <>
                  <Typography variant='h5' mb={1}>
                    Install the PWA application on a smartphone with iOS
                  </Typography>
                  <Alert severity={'warning'} sx={{ mb: 2 }}>
                    Available only for the browser Safari 11.3 and above.
                  </Alert>
                  <Stack spacing={2} direction={'row'} sx={{ mb: 1 }}>
                    <ShareIcon color={'primary'} />
                    <Typography variant='body1' color='text.primary' mb={1}>
                      1. Click the "Share" button in the menu line below.
                    </Typography>
                  </Stack>

                  <Stack spacing={2} direction={'row'}>
                    <PlusBoxIcon color={'primary'} />
                    <Typography variant='body1' color='text.primary' mb={1}>
                      2. Click "Add to Home Screen".
                    </Typography>
                  </Stack>
                </>
              )}
            </CardContent>
          ) : (
            <CardContent>
              <Typography variant='h5' mb={1}>
                The app is already installed
              </Typography>
              <Typography variant='body1' color='text.primary' mb={2}>
                Wait for notifications about the successful installation and start the installed application on the home
                screen.
              </Typography>
              <Stack spacing={2}>
                {PWADisplayMode === 'standalone' ? (
                  <Button variant='contained' component={NavLink} to={'/login'}>
                    Enter the application
                  </Button>
                ) : (
                  <Button variant='contained' component={Link} href={'https://wms-pwa.wms54.com'} target={'_blank'}>
                    Open the application
                  </Button>
                )}
              </Stack>
            </CardContent>
          )}
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Typography variant='h5' mb={1}>
              Installation of the PWA app is not supported by your device or browser
            </Typography>
            <Alert severity={'warning'}>
              <Typography variant={'h6'}> Installation Requirements:</Typography>

              <Typography mt={1}>
                -{' '}
                {`Android-smartphone with one of the installed browsers: Chrome(>105), Edge, Yandex, Samsung Internet, Opera Mobile(>64)`}
                ;
              </Typography>
              <Typography mt={1}>- {`iOS -Martphone with the installed browser Safari (>11.3).`}.</Typography>
            </Alert>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

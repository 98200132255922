import { useDispatch, useSelector } from "react-redux";
import { setSelectedDay } from "../../redux/weekPlanning";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import dayjs from "dayjs";

export const ControlHeader = ({ fetching }) => {
  const dispatch = useDispatch();
  const { selected_day, openPeriodModal, loading } = useSelector((state) => state.weekPlanning);

  const handleNextDayClick = () => {
    if (selected_day) {
      const newDay = selected_day.add(1, "day");
      dispatch(setSelectedDay(newDay));
    }
  };

  const handlePrevDayClick = () => {
    if (selected_day) {
      const newDay = selected_day.subtract(1, "day");
      dispatch(setSelectedDay(newDay));
    }
  };

  return (
    <div
      style={{
        marginBottom: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <LoadingButton
        id="swiper-button-prev"
        variant="contained"
        sx={{ padding: "0 0", minWidth: "50px", height: "41px", borderRadius: "16px 0 0 16px" }}
        // onClick={handlePrevDayClick}
        loading={fetching}
      >
        <KeyboardArrowLeftIcon />
      </LoadingButton>

      <Alert icon={false} severity="info" sx={{ padding: "0 0", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="subtitle2" sx={{ fontWeight: "600" }}>
          Click or swipe left \ right
        </Typography>
      </Alert>

      <LoadingButton
        id="swiper-button-next"
        variant="contained"
        sx={{ padding: "0 0", minWidth: "50px", height: "41px", borderRadius: "0 16px 16px 0" }}
        // onClick={handleNextDayClick}
        loading={fetching}
      >
        <KeyboardArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
      </LoadingButton>
    </div>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url, notify_url } from "../config/url";

export const getUserProjectsUuids = createAsyncThunk("projects/getUserProjects", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/list`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });

    return response?.data?.entity_uuids;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getUserProjects = createAsyncThunk("projects/getUserProjects", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/read/partial`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getMentionedProjects = createAsyncThunk("projects/getMentionedProjects", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().get(`${notify_url}/projects_where_mentioned/`, {
      headers: {
        "Session-token": token
      }
    });
    // console.log("response",  response?.data?.data);
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const checkEntityPerms = createAsyncThunk("comments/checkEntityPerms", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/utility/eperm/check`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("getCurrentCommentsProject", response?.data[0]);
    return response?.data[0];
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getCurrentCommentsProject = createAsyncThunk("comments/getCurrentCommentsProject", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/read`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("getCurrentCommentsProject", response?.data[0]);
    return response?.data[0];
  } catch (error) {
    // console.log("getCurrentCommentsProject", error);
    return rejectWithValue(error?.response?.data);
  }
});

export const getCurrentCommentsProjectUsers = createAsyncThunk("comments/getCurrentCommentsProjectUsers", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/utility/actor/get`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("getCurrentCommentsProjectUsers", response?.data);
    return response?.data;
  } catch (error) {
    // console.log("getCurrentCommentsProject", error);
    return rejectWithValue(error?.response?.data);
  }
});

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects_uuids: [],
    projects: [],
    mentioned_projects: [],
    basic_filter_projects: !!localStorage.getItem("basic_filter_projects") ? JSON.parse(localStorage.getItem("basic_filter_projects")) : [],
    current_comment_project_data: null,
    current_comments_project: null,
    current_comments_project_users: [],
    loading: false
  },
  reducers: {
    setBasicFilterProjects: (state, action) => {
      // console.log(action.payload?.uuid);
      state.basic_filter_projects = action.payload;
    },
    setCurrentCommentProjectData: (state, action) => {
      // console.log(action.payload?.uuid);
      state.current_comment_project_data = action.payload;
    },
    setCurrentCommentProject: (state, action) => {
      // console.log(action.payload?.uuid);
      state.current_comments_project = action.payload;
    }
    // setBiomUsersWithMessages: (state, action) => {
    //   // console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // }
  },
  extraReducers: {
    // ---------- proj_uuids -----------------
    [getUserProjectsUuids.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserProjectsUuids.fulfilled]: (state, action) => {
      state.loading = false;
      state.projects_uuids = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getUserProjectsUuids.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- proj_data -----------------
    [getUserProjects.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.projects = Array.isArray(action?.payload) ? action?.payload : [];
    },
    [getUserProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getMentionedProjects -----------------
    [getMentionedProjects.pending]: (state, action) => {
      state.loading = true;
    },
    [getMentionedProjects.fulfilled]: (state, action) => {
      state.loading = false;
      const newArr = Array.isArray(action?.payload) ? action?.payload : [];
      const finalData = newArr
        .map((item) => ({ uuid: item?.project_uuid, title: item?.project_title, id: item?.project_id, partition: item?.partition }))
        .filter((proj) => !!proj?.uuid);
      // console.log('finalData', finalData)
      state.mentioned_projects = finalData;
    },
    [getMentionedProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getMentionedProjects -----------------
    [getCurrentCommentsProject.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentCommentsProject.fulfilled]: (state, action) => {
      // console.log("getCurrentCommentsProject", action.payload);
      state.loading = false;
      state.current_comments_project = action.payload;
    },
    [getCurrentCommentsProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getMentionedProjects -----------------
    [checkEntityPerms.pending]: (state, action) => {
      state.loading = true;
    },
    [checkEntityPerms.fulfilled]: (state, action) => {
      // console.log("checkEntityPerms", action.payload);
      state.loading = false;
    },
    [checkEntityPerms.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getMentionedProjects -----------------
    [getCurrentCommentsProjectUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentCommentsProjectUsers.fulfilled]: (state, action) => {
      // console.log("getCurrentCommentsProjectUsers", action.payload?.data);
      state.loading = false;
      const final_data = Array.isArray(action.payload?.data)
        ? action.payload?.data?.slice(0).map((item) => ({
            uuid: item?.uuid,
            email: item?.uinfo?.email,
            first_name: item?.uinfo?.first_name,
            last_name: item?.uinfo?.last_name,
            id: item?.uuid,
            label: `${item?.uinfo?.first_name} ${item?.uinfo?.last_name}`,
            display: `${item?.uinfo?.first_name} ${item?.uinfo?.last_name}`
          }))
        : [];
      state.current_comments_project_users = final_data;
    },
    [getCurrentCommentsProjectUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const { setBasicFilterProjects, setCurrentCommentProjectData, setCurrentCommentProject } = projectsSlice.actions;
export default projectsSlice.reducer;

import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setResetComponent, setIsNeedToScrollToStart } from "../../../redux/chat";
import { getChatUserMessages } from "../../../redux/chat";

import { LoadingButton } from "@mui/lab";
import { Button, Divider, Typography, CircularProgress } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ChatMessageComponent } from "../../../components/ChatMessageComponent/ChatMessageComponent";
import { ProjectResetChip } from "../../../components/ProjectResetChip/ProjectResetChip";
import { IssueResetChip } from "../../../components/IssueResetChip/IssueResetChip";
import { CommentForm } from "../../UsersPage/CommentForm/CommentForm";

import { NO_MESSAGES, NoData } from "../../../components/ChatFiltersDialog/NoData";
import { checkToken } from "../../../utils/checkToken";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import { isIOS } from "react-device-detect";

export const MessagesBlock = () => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);

  const { current_chat_user } = useSelector((state) => state.users);
  const { show_footer_in_chat } = useSelector((state) => state.settings);
  const {
    current_chat_user_messages,
    project_filter,
    issue_filter,
    search_filter_value_messages,
    resetComponent,
    replyData,
    newMessageBlockHeight,
    isNeedToScrollToStart
  } = useSelector((state) => state.chat);

  const windowInnerHeight = window.innerHeight;
  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;

  const wrapperRef = useRef(null);
  const firstMessageRef = useRef(null);
  const btnRef = useRef(null);

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [fetching, setFetching] = useState(true);

  // console.log("current_chat_user", current_chat_user);

  const [userMessages, setUserMessages] = useState([]);

  const [showToStartBtn, setShowToStartBtn] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);

  useEffect(() => {
    if (resetComponent) {
      initFunc();
      dispatch(setResetComponent(false));
    }
  }, [resetComponent]);

  const handleMore = () => {
    // console.log("handleMore");
    setPage((prev) => prev + 1);
  };

  const initFunc = async () => {
    try {
      // setFetching(true);
      if (current_chat_user?.uuid) {
        const data = {
          basic_filter_uuids: Array.isArray(current_user?.basic_filter_project_uuids) ? current_user?.basic_filter_project_uuids : [],
          token: checkToken(current_user?.user_uuid, "notify"),
          sender_uuid: current_chat_user?.uuid,
          limit: 25,
          offset: 25 * page,
          project_uuid: issue_filter?.uuid ? "" : project_filter?.uuid,
          entity_uuid: issue_filter?.uuid || "",
          message_text: search_filter_value_messages || ""
        };
        const newMessagesResp = await dispatch(getChatUserMessages(data)).unwrap();
        const newMessages = newMessagesResp?.data;

        setTotal(newMessagesResp?.total);

        const finalData = page === 0 ? newMessages : userMessages.concat(newMessages);
        // .slice(0).sort((mess1, mess2) => {
        //   const date1 = dayjs(mess1?.created);
        //   const date2 = dayjs(mess2?.created);
        //   // console.log(date1.diff(date2));
        //   return date1.diff(date2);
        // });
        // console.log("finalData", finalData);
        setUserMessages(finalData);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setFetching(false);
      setTimeout(() => {
        if (page === 0) {
          scrollTo();
        }
      }, 300);
    }
  };

  useEffect(() => {
    // console.log("page", page);
    initFunc();
    // if (project_filter?.uuid|| issue_filter?.uuid|| search_filter_value_messages) {
    //   setSearchMode(true)
    // } else {
    //   setSearchMode(false)
    // }
  }, [page, project_filter?.uuid, issue_filter?.uuid, search_filter_value_messages]);

  useEffect(() => {
    // console.log("project_filter?.uuid, issue_filter?.uuid", project_filter?.uuid, issue_filter?.uuid);
    // dispatch(setIsNeedToScrollToStart(true));

    setFetching(true);
    setPage(0);
  }, [project_filter?.uuid, issue_filter?.uuid, search_filter_value_messages]);

  // useEffect(() => {
  //   console.log("page", page);
  // }, [page]);
  // useEffect(() => {
  //   console.log("total", total);
  // }, [total]);

  const scrollTo = () => {
    const wrapper = document.querySelector(".infinite-scroll-component__outerdiv");

    // console.log("ref", wrapper);
    if (!!wrapper) {
      wrapper.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  };

  const options = {
    root: document.querySelector("#scrollableDiv"),
    rootMargin: "0px",
    threshold: 1.0
  };
  const callback = function (entries, observer) {
    /* Content excerpted, show below */
    // console.log("entries", entries);
    const { isIntersecting } = entries[0];
    // console.log("isVisible", { time, rootBounds, boundingClientRect, intersectionRect, target, isIntersecting, isVisible, intersectionRatio });
    // console.log("observer", observer);
    setShowToStartBtn(!isIntersecting);
  };
  const observer = new IntersectionObserver(callback, options);

  useLayoutEffect(() => {
    if (firstMessageRef?.current) {
      // console.log("firstMessageRef?.current", firstMessageRef?.current);
      observer.observe(firstMessageRef?.current);
    }
  }, [firstMessageRef?.current]);

  useLayoutEffect(() => {
    if (isNeedToScrollToStart) {
      dispatch(setIsNeedToScrollToStart(false));
      setPage(0);
      // scrollTo();
    }
  }, [isNeedToScrollToStart]);

  return (
    <>
      {(project_filter || issue_filter) && (
        <div
          style={{
            width: "100%",
            position: "fixed",
            padding: "6px 48px 0 0",
            top: "57px",
            zIndex: "11",
            background: "white"
          }}
        >
          {project_filter && <ProjectResetChip />}
          {issue_filter && <IssueResetChip />}
          <Divider />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: "0",
          bottom: "2px",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: fetching && !userMessages?.length ? "15" : "-2",
          background: "white"
        }}
      >
        {fetching && !userMessages?.length && <CircularProgress />}
      </div>

      {current_chat_user?.uuid && Array.isArray(current_chat_user_messages) && (
        <>
          {current_chat_user_messages?.length ? (
            <>
              <InfiniteScroll
                ref={wrapperRef}
                dataLength={userMessages?.length}
                next={handleMore}
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  paddingTop: project_filter || issue_filter ? "45px 0 16px 0" : "16px 0 16px 0"
                }} //To put endMessage and loader to the top.
                inverse={true}
                hasMore={Number(userMessages?.length) < Number(total)}
                loader={
                  <div
                    style={{
                      position: "absolute",
                      top: project_filter || issue_filter ? "45px" : "0",
                      left: "-16px",
                      width: "calc(100% + 16px)",
                      textAlign: "center"
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                  <Typography
                    variant="subtitle2"
                    sx={{ margin: showToStartBtn && (project_filter || issue_filter) ? "64px 0 8px 0" : "16px 0 8px 0", textAlign: "center" }}
                  >
                    {dayjs(userMessages[userMessages?.length - 1]?.created).format("DD-MM-YYYY")}
                  </Typography>
                }
              >
                {userMessages.map((message, idx) => (
                  <div
                    ref={idx === 0 ? firstMessageRef : null}
                    key={`${current_chat_user?.uuid}_${idx}_message`}
                    style={{
                      margin: "0 48px 0 0"
                      // border: `1px solid ${idx === 0 ? "gray" : "transparent"}`,
                      // boxShadow: idx === 0 ? "0px 5px 10px 0px rgba(0, 0, 0, 0.7)" : ""
                    }}
                  >
                    <ChatMessageComponent message={message} />
                  </div>
                ))}
              </InfiniteScroll>

              <div
                ref={btnRef}
                className="fixed_switch_btn_bottom"
                style={{
                  right: showToStartBtn ? "8px" : "-100px",
                  bottom: `calc(80px ${newMessageBlockHeight ? `+ ${newMessageBlockHeight}px` : ""} ${replyData ? "+ 79px" : ""} ${
                    isIOS ? (!checkIsTallFooter ? "+ 34px" : "+ 64px") : ""
                  }  ${show_footer_in_chat ? "+ 64px" : ""})`
                }}
              >
                <Button variant="outlined" sx={{ padding: "0 0", minWidth: "50px", height: "50px", borderRadius: "25px" }} onClick={scrollTo}>
                  <KeyboardArrowDownIcon />
                </Button>
              </div>
            </>
          ) : (
            <div style={{ display: "flex", alignContent: "stretch", alignItems: "center", height: "100vh" }}>
              <NoData type={NO_MESSAGES} />
            </div>
          )}
        </>
      )}
      <CommentForm open={openMessageDialog} setOpen={setOpenMessageDialog} />
    </>
  );
};

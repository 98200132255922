import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function DialogModal({ open, setOpen, callback, text, content, width }) {
  const handleCancel = () => {
    // console.log("handleCancel");
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          margin: "0 0",
          width: width || "calc(100% - 16px)",
          borderRadius: "16px",
          transition: ".3s ease-in-out"
        }
      }}
      sx={{ transition: ".3s ease-in-out" }}
    >
      {content}
    </Dialog>
  );
}

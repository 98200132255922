import axios from "axios";

import { setIsTokenError, setPasswordErrorData } from "../redux/notify";
import { setType, setMessage, setIsOpen } from "../redux/notify";
import { checkToken } from "../utils/checkToken";
import { currentUserUrls } from "../utils/currentUserUrls";
import { entity_url, notify_url } from "../config/url";

let store;

export const injectStore = (_store) => {
  store = _store;
};

function getRelativeURL(the_url) {
  // remove the :// and split the string by '/'
  const the_arr = the_url.replace("://", "").split("/");

  // remove the first element (the domain part)
  the_arr.shift();

  // join again the splitted parts and return them with a '/' preceding
  return "/" + the_arr.join("/");
}

const createUrl = (url, current_user) => {
  const urls_data = currentUserUrls(current_user);
  // console.log("url", url);
  // console.log("urls_data", urls_data);
  // console.log("url", url);
  const checkIsEntity = url?.includes(entity_url);
  // console.log("checkIsEntity", checkIsEntity);
  const checkIsNotification = url?.includes(notify_url);
  // console.log("checkIsNotification", checkIsNotification);
  const relative_path = getRelativeURL(url);
  if (checkIsEntity) {
    // console.log("url?.split(urls_data?.entity)[1]", url?.split(urls_data?.entity));
    return `${urls_data?.entity}${relative_path}`;
  }
  if (checkIsNotification) {
    return `${urls_data?.notification}${relative_path}`;
  }
  return url;
};

export const createAxios = (props) => {
  // console.log("props", props);

  // const headers = !!props ? { "Session-token": props } : {};

  const newInstance = axios.create({
    headers: props,
  });
  // console.log("newInstance", newInstance);

  newInstance.interceptors.request.use(
    (config) => {
      // !!!!!!!
      // important condition:
      // at this point, a dynamic base url is generated from the
      // biome of the current account
      // !!!!!!!
      const current_user = store?.getState()?.auth?.current_user;
      if (current_user?.user_uuid) {
        const current_url = config?.url?.slice(0);
        // console.log("createUrl(current_url)", createUrl(current_url, current_user));
        config["url"] = createUrl(current_url, current_user);
        return config;
      } else {
        return config;
      }
    },
    (error) => {
      // console.log("error1", error);

      return Promise.reject(error);
    }
  );
  newInstance.interceptors.response.use(
    (config) => {
      // console.log("success2");
      return config;
    },
    (error) => {
      // if (error?.response && error?.response.status >= 400 && error?.response.status < 500) {

      //   return Promise.reject(new Error("Bad status code"));
      // } else if (error?.response && error?.response.status >= 500 && error?.response.status < 600) {
      //   return Promise.reject(new Error("Internal server error"));
      // } else {
      //   if (!!error?.response?.data && !(error?.code === "ERR_NETWORK")) {
      //     return Promise.reject(error);
      //   }
      // }
      // console.log("401 401", error);
      const checkFor401 = JSON.stringify(error?.message).includes("401");
      // console.log("401 401", checkFor401);
      if (error?.response?.data?.error_message === "Password verification failed.") {
        const dispatch = store.dispatch;
        const url = error?.response?.config?.url;
        const payload = JSON.parse(error?.response?.config?.data);
        const data = {
          url: url,
          req_body: payload,
        };
        dispatch(setPasswordErrorData(data));
        return;
      }
      if (error?.response.status === 401 || checkFor401) {
        const user_uuid = store?.getState()?.auth?.current_user?.user_uuid;
        const checkIsToken = checkToken(user_uuid, "notify");
        if (!checkIsToken) {
          const dispatch = store.dispatch;
          // console.log("401 401 checkIsToken", error);
          dispatch(setIsTokenError(true));
          return;
        } else {
          return Promise.reject(error);
        }
      }
      const message =
        error?.response?.status === 403
          ? "You don't have the permission to access the requested resource."
          : `Error ${error?.response?.status}: ${
              error?.response?.data?.message || error?.response?.data?.error_message || ""
            }`;
      console.log("createAxios", message);
      const dispatch = store.dispatch;
      dispatch(setType("error", message));
      dispatch(setMessage(message));
      dispatch(setIsOpen(true));
      return Promise.reject(error);
    }
  );

  return newInstance;
};

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowAllOffices, setShowAllAccounts } from "../../redux/switcher";
import { setSearchFilter, setResetFilters, setSearchFilterValueMessages } from "../../redux/chat";
import { setCurrentChatUser, setSearchFilterValueUsers } from "../../redux/users";

import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, IconButton, Divider, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { HeaderPopover } from "../ChatPopover/ChatPopover";

import "./index.css";

import { appRoutes } from "../../utils/constants";

import { HeaderSearch } from "./HeaderSearch";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selected_user_info } = useSelector((state) => state.auth);
  const { selected_office_info, current_office } = useSelector((state) => state.office);
  const { current_chat_user } = useSelector((state) => state.users);
  const { search_filter } = useSelector((state) => state.chat);

  const [currentPage, setCurrentPage] = useState("");

  const detectPage = () => {
    const currentLoc = location.pathname;
    switch (currentLoc) {
      case appRoutes.account.path: {
        const fn = selected_user_info?.first_name;
        const ln = selected_user_info?.last_name;
        return `${fn} ${ln}`;
      }
      case appRoutes.accounts.path: {
        return appRoutes.accounts.title;
      }
      case appRoutes.users.path: {
        return appRoutes.users.title;
      }
      case appRoutes.chat.path: {
        const fn = current_chat_user?.first_name;
        const ln = current_chat_user?.last_name;
        // console.log("current_chat_user", current_chat_user);
        return `${appRoutes.chat.title} with ${fn} ${ln}`;
      }
      case appRoutes.doors.path: {
        return `${selected_office_info?.name_comment || selected_office_info?.name || current_office?.name} doors`;
      }
      case appRoutes.settings.path: {
        return appRoutes.settings.title;
      }
      case appRoutes.planning.path: {
        return appRoutes.planning.title;
      }
      default:
        return ``;
    }
  };

  useLayoutEffect(() => {
    setCurrentPage(detectPage());
  }, [location.pathname]);

  const handleBack = () => {
    if (location.pathname === appRoutes.users.path && search_filter) {
      dispatch(setSearchFilter(false));
      dispatch(setSearchFilterValueUsers(""));
    }
    if (location.pathname === appRoutes.account.path) {
      navigate(appRoutes.accounts.path);
    }
    if (location.pathname === appRoutes.chat.path) {
      if (search_filter) {
        dispatch(setSearchFilter(false));
        dispatch(setSearchFilterValueMessages(""));
      } else {
        dispatch(setCurrentChatUser(null));
        navigate(appRoutes.users.path);
      }
    }
  };

  const handleSearch = () => {
    dispatch(setSearchFilter(true));
  };

  const handleResetFilters = () => {
    dispatch(setResetFilters(true));
  };

  // const forToday =
  //   location.pathname === appRoutes.planning.path && selected_day.format("dd-mm-yyyy") !== dayjs().format("dd-mm-yyyy");

  const isBackButton =
    location.pathname === appRoutes.account.path ||
    location.pathname === appRoutes.chat.path ||
    (location.pathname === appRoutes.users.path && search_filter);

  return (
    <>
      <div className="page_header">
        <Box
          maxWidth="sm"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%"
          }}
        >
          <div
            className="header_inner"
            style={{
              paddingLeft: isBackButton ? "0" : "16px",
              width: search_filter ? "calc(100% - 16px)" : "calc(100% - 80px)"
            }}
          >
            {isBackButton && (
              <IconButton onClick={handleBack}>
                <ArrowBackIcon color="primary" />
              </IconButton>
            )}
            {/* onClick={handleBack} */}

            {search_filter ? (
              <HeaderSearch />
            ) : (
              <div className="header_title">
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "36px",
                    margin: "0 0",
                    padding: "0 0",
                    lineHeight: "1"
                  }}
                >
                  <div className="title_truncate">{currentPage}</div>
                </Typography>
              </div>
            )}
          </div>
          {(location.pathname === appRoutes.users.path || location.pathname === appRoutes.chat.path) && (
            <>
              {!search_filter && (
                <div
                  style={{
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton onClick={handleSearch}>
                    <SearchIcon color="primary" />
                  </IconButton>
                </div>
              )}
            </>
          )}

          <Divider sx={{ width: "100%" }} />
        </Box>
      </div>
    </>
  );
};

export const entity_url = "<entity>";
export const notify_url = "<notification>";

// export const entity_url = process.env.REACT_APP_ENTITY_URL || "https://entity.09releasetest.54origins.com";
// export const notify_url = process.env.REACT_APP_NOTIFICATION_URL || "https://notification.09releasetest.54origins.com";

// const biom_domain_url = store?.getState()?.auth?.current_user?.biom?.biom_domain;
// const biom_domain = !!biom_domain_url ? biom_domain_url.split(".").slice(1).join(".") : "09releasetest.54origins.com";

// export const entity_url = !!biom_domain ? `https://entity.${biom_domain}` : "https://entity.09releasetest.54origins.com";
// export const notify_url = !!biom_domain ? `https://notification.${biom_domain}` : "https://notification.09releasetest.54origins.com";
// const default_domain = "09releasetest.54origins.com";

// export const url_data = {
//   entity: "",
//   notification: ""
// };
// export const entity_url = url_data?.entity;
// export const notify_url = url_data?.notification;

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDateVectorIssuesUuids,
  getDateVectorIssues,
  createDateIssue,
  setOpenPeriodModal,
} from "../../../redux/weekPlanning";

import { DayCardIssue } from "./DayCardIssue";
import { DialogActions, Button } from "@mui/material";
import { CircularProgress, Typography, Avatar } from "@mui/material";
import { Card, CardHeader, CardContent, Collapse } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AddIcon from "@mui/icons-material/Add";

import SyncIcon from "@mui/icons-material/Sync";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";

import dayjs from "dayjs";
import { checkToken } from "../../../utils/checkToken";
import { weekPlanningActions } from "../../../Socket/weekPlanningActions";
import { Socket } from "../../../Socket/socket";

export const DayCard = ({ currentDayData, onlyFetching, handleWeekPlanningSync }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { pub_partition, week_planning_unique_uuid } = useSelector((state) => state.settings);
  const { selected_day } = useSelector((state) => state.weekPlanning);

  const [fetching, setFetching] = useState(false);
  // const [fetchingSubmit, setFetchingSubmit] = useState(false);

  const [someIssueEditing, setSomeIssueEditing] = useState(false);
  const [newIssueAdding, setNewIssueAdding] = useState(false);

  const lastActivityRef = useRef(null);

  const today = dayjs();
  const currentDayDate = currentDayData?.date || currentDayData;

  const defaultOptions = {
    title: !!currentDayDate ? currentDayDate?.format("dddd") : today.format("dddd"),
    subTitle: !!currentDayDate ? currentDayDate?.format("MMMM DD, YYYY") : today.format("MMMM DD, YYYY"),
    currentDayDate: !!currentDayDate ? currentDayDate?.format("DD") : today.format("DD"),
    isToday: !!currentDayDate ? currentDayDate?.format("MMM DD, YYYY") === today.format("MMM DD, YYYY") : true,
    isYesterday: !!currentDayDate
      ? currentDayDate?.format("MMM DD, YYYY") === today.subtract(1, "day").format("MMM DD, YYYY")
      : false,
    isTomorrow: !!currentDayDate
      ? currentDayDate?.format("MMM DD, YYYY") === today.add(1, "day").format("MMM DD, YYYY")
      : false,
    isWeekend: !!currentDayDate
      ? currentDayDate.day() === 6 || currentDayDate.day() === 0
      : today.day() === 6 || today.day() === 0,
  };

  const [options, setOptions] = useState(defaultOptions);
  const [activitiesArr, setActivitiesArr] = useState(currentDayData?.issues);

  const token = checkToken(current_user?.user_uuid, "entity");

  const handleGetVectorIssues = async () => {
    try {
      // console.log("parent: selected_day_uuid", selected_day_uuid);
      // setFetching(true);
      const req_body = {
        entity_type: "issue",
        order: "asc",
        order_by_params: "weight",
        params_fields: { date: "date", title: "title", completed: "completed", weight: "weight" },
        parent: currentDayData?.uuid,
      };
      const req_data = {
        token: token,
        partition_uuid: pub_partition?.header_uuid,
        req_body: req_body,
      };
      const resp_issues_uuids = await dispatch(getDateVectorIssuesUuids(req_data)).unwrap();

      return resp_issues_uuids;
    } catch (e) {
      console.log("e", e);
    } finally {
      // setFetching(false);
    }
  };

  const initFunc = async () => {
    try {
      const issues_uuids = await handleGetVectorIssues();
      // console.log("new_entity_uuids", new_entity_uuids);
      // console.log("entity_uuids", entity_uuids);
      if (issues_uuids?.length) {
        setFetching(true);
        const req_body = {
          entity_type: "issue",
          entity_uuids: issues_uuids,
          order: "asc",
          order_by_params: "weight",
          params_fields: { date: "date", title: "title", completed: "completed", weight: "weight" },
          total: issues_uuids?.length,
        };
        const data = {
          req_body: req_body,
          token: token,
          parent_uuid: currentDayData?.uuid,
          partition_uuid: pub_partition?.header_uuid,
        };
        // console.log("initFunc data", data);
        const resp = await dispatch(getDateVectorIssues(data)).unwrap();

        // console.log("initFunc resp", resp);
        setActivitiesArr(resp);
        // return resp;
      } else {
        // console.log("empty");
        setActivitiesArr([]);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setFetching(false);
    }
  };

  const calculateActivityWeight = () => {
    if (!activitiesArr?.length) {
      return (activitiesArr?.length + 1) * 1000;
    }
    const weightArr = activitiesArr.map((item) => item?.weight);
    const maxWeight = Math.max.apply(null, weightArr);

    return maxWeight + 1000;
  };

  useEffect(() => {
    if (!someIssueEditing && newIssueAdding) {
      handleAddActivity().then(() => setNewIssueAdding(false));
    }
  }, [someIssueEditing, newIssueAdding]);

  const handleAddActivityWrapper = async () => {
    if (someIssueEditing) {
      setFetching(true);
      setNewIssueAdding(true);
    } else {
      await handleAddActivity();
    }
  };

  const handleAddActivity = async () => {
    try {
      setFetching(true);
      // console.log("selected_day", selected_day);
      const req_body = {
        entity_type: "issue",
        parent: currentDayData?.uuid,
        params: {
          date: currentDayData?.date?.format("YYYY-MM-DD"),
          title: "",
          completed: false,
          weight: calculateActivityWeight(),
          typeForReport: "activity",
          status: "created",
          tracker: "ticket",
          priority: "low",
          project: week_planning_unique_uuid,
        },
      };
      const data = {
        req_body: req_body,
        token: token,
        partition_uuid: pub_partition?.header_uuid,
      };
      const resp = await dispatch(createDateIssue(data)).unwrap();
      const newData = {
        uuid: resp?.uuid,
        date: req_body?.params.date,
        title: resp?.params?.title,
        completed: resp?.params?.completed,
        weight: resp?.params?.weight,
      };
      const req_data = { action_type: weekPlanningActions.create, parent_date: newData?.date, data: [newData] };
      syncChanges(req_data);
      // if (someIssueEditing) {
      //   console.log("someIssueEditing", someIssueEditing);
      //   newIssueBeforeOldUpdating.current = newData;
      //   // setNewIssueBeforeOldUpdating();
      // } else {
      //   // setActivitiesArr((prev) => [...prev, newData]);
      // }
      setActivitiesArr((prev) => [...prev, newData]);
    } catch (e) {
      console.log("e", e);
    } finally {
      setFetching(false);
    }
  };

  const handleUpdateParentState = () => {
    initFunc();
  };

  const handleOpenPeriodModal = () => {
    dispatch(setOpenPeriodModal(true));
    // setOpen(true);
  };

  useEffect(() => {
    if (currentDayData?.uuid) {
      // console.log("currentDayData?.uuid", currentDayData?.date?.format("DD-MM-YYYY"));
      // console.log("is equal", currentDayData?.date?.format("DD-MM-YYYY") === selected_day?.format("DD-MM-YYYY"));

      const currentDayDate = currentDayData?.date;
      const options = {
        title: currentDayDate?.format("dddd"),
        subTitle: currentDayDate?.format("MMMM DD, YYYY"),
        currentDayDate: currentDayDate?.format("DD"),
        isToday: currentDayDate?.date() === dayjs().date(),
        isYesterday: currentDayDate?.date() === dayjs().date() - 1,
        isTomorrow: currentDayDate?.date() === dayjs().date() + 1,
        isWeekend: currentDayDate?.day() === 6 || currentDayDate?.day() === 0,
      };
      setOptions(options);
    }
  }, [currentDayData?.uuid]);

  useEffect(() => {
    if (currentDayData?.uuid) {
      // lastActivityRef.current = false;
      // console.log("currentDayData?.issues", currentDayData?.issues);
      setActivitiesArr(currentDayData?.issues);
    }
  }, [JSON.stringify(currentDayData?.issues)]);

  const syncChanges = (req_data) => {
    const token = checkToken(current_user?.user_uuid, "notify");
    handleWeekPlanningSync(req_data, true);
    // console.log("req_data", req_data);
    const data = JSON.stringify({ token: token, params: req_data });
    Socket.send("update_vector_activities", data);
    // if (isDuplicate) {
    //   setDuplicateData([]);
    // }
  };

  // useEffect(() => {
  //   console.log('lastActivityRef?.current', lastActivityRef?.current)
  // }, [lastActivityRef?.current])

  return (
    <div style={{ marginBottom: "65px", minHeight: "calc(100vh - 280px)" }}>
      <Card
        elevation={0}
        key={options?.title}
        sx={{
          position: "relative",
          // marginBottom: "64px",
          border: `1px solid ${
            options?.isToday
              ? "rgba(160, 217, 255, 0.9)"
              : options?.isWeekend
              ? "rgba(46, 125, 50, .3)"
              : "rgba(128, 128, 128, .3)"
          }`,

          boxShadow: `inset 0px 10px 20px 2px ${
            options?.isToday
              ? "rgba(160, 217, 255, 0.63)"
              : options?.isWeekend
              ? "rgba(46, 125, 50, .15)"
              : "rgba(128, 128, 128, .15)"
          }`,
        }}
      >
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "#0288d1" }}>{options?.title ? options?.title.slice(0, 2) : ""}</Avatar>}
          title={
            <Typography
              variant={options?.isToday ? "subtitle2" : "body1"}
              sx={{ color: options?.isWeekend ? "green" : "auto", fontWeight: "600" }}
            >
              {options?.title} {options?.isYesterday && "(yesterday)"} {options?.isToday && "(today)"}{" "}
              {options?.isTomorrow && "(tomorrow)"}
            </Typography>
          }
          subheader={
            <Typography variant="body1" sx={{ color: "gray" }}>
              {options?.subTitle}
              {/* {activitiesArr?.length ? "Activity for the day" : "There are no activities yet..."} */}
            </Typography>
          }
          action={
            <Button
              variant="contained"
              // color="info"
              sx={{ margin: "8px 8px 0 0", padding: "0 0", minWidth: "40px", height: "40px", borderRadius: "20px" }}
              onClick={handleOpenPeriodModal}
              disabled={fetching}
            >
              <DateRangeOutlinedIcon />
            </Button>
          }
        />
        <div
          style={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: onlyFetching ? "100" : "-2",
            background: "white",
          }}
        >
          {onlyFetching && <CircularProgress />}
        </div>

        <Collapse
          in={
            !onlyFetching &&
            !!activitiesArr?.length &&
            currentDayData?.date?.format("DD-MM-YYYY") === selected_day?.format("DD-MM-YYYY")
            // isLastRender
            // !!lastActivityRef?.current
          }
          timeout="auto"
          sx={{ transition: ".2s all ease-in-out" }}
        >
          <CardContent
            sx={{
              paddingTop: "16px",
              transition: ".2s all ease-in-out",
              // maxHeight: !!selected_day
              //   ? currentDayData?.date?.format("DD-MM-YYYY") === selected_day?.format("DD-MM-YYYY")
              //     ? "auto"
              //     : "150px"
              //   : "150px"
            }}
          >
            {Array.isArray(activitiesArr) &&
              activitiesArr.map((activity, idx) => {
                const checkIsLast = idx === activitiesArr?.length - 1;
                return (
                  <div key={activity?.uuid} ref={checkIsLast ? lastActivityRef : null}>
                    <DayCardIssue
                      activity={activity}
                      // handleUpdateParentState={handleUpdateParentState}
                      parentFetching={fetching}
                      setActivitiesArr={setActivitiesArr}
                      syncChanges={syncChanges}
                      setSomeIssueEditing={setSomeIssueEditing}
                    />
                  </div>
                );
              })}
          </CardContent>
        </Collapse>
        <DialogActions sx={{ padding: "8px 16px" }}>
          <LoadingButton
            fullWidth
            variant="contained"
            startIcon={<SyncIcon />}
            onClick={initFunc}
            loading={fetching}
            sx={{ height: "56px" }}
            // disabled={someIssueEditing}
          >
            Update
          </LoadingButton>
          <LoadingButton
            fullWidth
            variant="contained"
            color="success"
            startIcon={<AddIcon />}
            onClick={handleAddActivityWrapper}
            // loading={fetchingSubmit}
            disabled={fetching}
            sx={{ height: "56px" }}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Card>
    </div>
  );
};

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getMentionedProjects, setBasicFilterProjects } from "../../redux/projects";
import { updateAccountInfo, setCurrentUser } from "../../redux/auth";

import { Divider, CircularProgress } from "@mui/material";
import { ItemComponent } from "../../components/ChatFiltersDialog/ItemComponent";
import SearchComponent from "../UsersPage/SearchComponent";
import { SubGroup } from "../../components/Subgroup/Subgroup";
import { checkToken } from "../../utils/checkToken";

import { NoData, NO_MENTIONED_PROJECTS, NO_PROJECTS } from "../../components/ChatFiltersDialog/NoData";
import { orderBy } from "lodash";

export const BasicFiltrationBlock = () => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { mentioned_projects } = useSelector((state) => state.projects);

  const [projectSearchFilterTitle, setProjectSearchFilterTitle] = useState("");
  const [projectsData, setProjectsData] = useState([]);
  const [fetching, setFetching] = useState(true);

  const initProjectsFunc = async () => {
    try {
      if (current_user?.user_uuid) {
        setFetching(true);
        const notify_token = checkToken(current_user?.user_uuid, "notify");
        const data = { token: notify_token };
        const resp = await dispatch(getMentionedProjects(data)).unwrap();
        const checkIsUserSelection = current_user?.checkIsUserSelection;
        // const finalData = resp.map((uuid) => ({ uuid: uuid, isSelected: true }));
        // dispatch(setBasicFilterProjects(finalData));
        // setFilterProjects(finalData);
      }
    } catch (e) {
    } finally {
    }
  };

  const handleSelectProj = (project) => {
    // console.log("callback_proj", callback_proj);
    const basic_filter_project_uuids = Array.isArray(current_user?.basic_filter_project_uuids) ? current_user?.basic_filter_project_uuids : [];

    let newSelection = [];
    const checkIsSelected = basic_filter_project_uuids.find((proj_uuid) => proj_uuid === project?.uuid);

    if (checkIsSelected) {
      newSelection = basic_filter_project_uuids.slice(0).filter((proj_uuid) => proj_uuid !== project?.uuid);
    } else {
      newSelection = [...basic_filter_project_uuids.slice(0), project?.uuid];
    }
    // console.log("newSelection", newSelection);
    // setFilterProjects(newSelection);

    const newUserData = { ...current_user, basic_filter_project_uuids: newSelection };
    dispatch(updateAccountInfo(newUserData));
    dispatch(setCurrentUser(newUserData));
  };

  useEffect(() => {
    initProjectsFunc();
  }, []);

  useEffect(() => {
    const newData = mentioned_projects.filter((project) =>
      !!projectSearchFilterTitle ? project?.title.includes(projectSearchFilterTitle) : project
    );
    const finalData = orderBy(newData, ["partition", "id"], ["asc", "asc"]);
    setProjectsData(finalData);
    setFetching(false);
  }, [mentioned_projects?.length, projectSearchFilterTitle]);

  // useEffect(() => {
  //   console.log("projectsData?.length", projectsData?.length);
  // }, [projectsData?.length]);

  return (
    <>
      {fetching ? (
        <div style={{ marginTop: "32px", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {mentioned_projects?.length ? (
            <>
              <div style={{ position: "sticky", top: "0", padding: "12px 16px 0 16px", backgroundColor: "white", zIndex: "10" }}>
                <SearchComponent
                  fullWidth={true}
                  variant={"outlined"}
                  filterTitle={projectSearchFilterTitle}
                  onFilterTitle={setProjectSearchFilterTitle}
                />
                <Divider sx={{ marginTop: "12px" }} />
              </div>

              <>
                {!projectsData?.length && (
                  <div style={{ marginTop: "32px" }}>
                    <NoData type={NO_PROJECTS} />
                  </div>
                )}
                {projectsData.map((project, idx) => {
                  const basic_filter_project_uuids = current_user?.basic_filter_project_uuids;
                  const checkIsSelected = Array.isArray(basic_filter_project_uuids)
                    ? basic_filter_project_uuids.find((basic_proj_uuid) => basic_proj_uuid === project?.uuid)
                    : false;
                  const checkIsNewPartition = projectsData[idx - 1]?.partition !== project?.partition;
                  // console.log("proj", proj);

                  return (
                    <div key={project?.uuid}>
                      {checkIsNewPartition && (
                        <div style={{ padding: "8px 16px" }}>
                          <SubGroup
                            label={
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="ellipsys_title" style={{ maxWidth: "200px", fontWeight: "600" }}>
                                  {project?.partition === "PM" ? "General projects" : "Personal projects"}
                                </div>
                              </div>
                            }
                          />
                        </div>
                      )}
                      <ItemComponent
                        noBackground={true}
                        isSelected={checkIsSelected}
                        val={project}
                        label={project?.title}
                        icon={`P_${project?.id}`}
                        callback={handleSelectProj}
                        isMaxProjects={basic_filter_project_uuids?.length >= 10 || 0}
                      />
                    </div>
                  );
                })}
                {/* <div style={{ height: "500px" }}></div> */}
              </>
            </>
          ) : (
            <NoData type={!mentioned_projects?.length ? NO_MENTIONED_PROJECTS : !projectsData?.length ? NO_PROJECTS : ""} />
          )}
        </>
      )}
    </>
  );
};

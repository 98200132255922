import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setCurrentUserByUUID } from "../../redux/auth";
import { resetPartitions } from "../../redux/settings";
import { setSelectedDayUuid } from "../../redux/weekPlanning";
import "./index.css";

import { List, ListItem, Button, ListItemAvatar, Avatar, Chip, ListItemIcon } from "@mui/material";
import { IconButton, Typography, Divider, ListItemButton, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DialogWindow } from "../DialogWindow/DialogWindow";

import StarRateIcon from "@mui/icons-material/StarRate";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PublicIcon from "@mui/icons-material/Public";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { openNotify } from "../OpenNotify/openNotify";
import { initIsPushAvailableCheck } from "../../serviceWorkerUtils/initIsPushAvailableCheck";
import { checkCurrentUserTokens } from "../../utils/checkCurrentUserTokens";

import { handleUnsubscribe } from "../../serviceWorkerUtils/handleUnsubscribeForPush";
import { currentUserUrls } from "../../utils/currentUserUrls";

const style = {
  position: "absolute",
  top: "10vh",
  left: "16px",
  width: "calc(100vw - 32px)",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const ModalAccounts = (props) => {
  const dispatch = useDispatch();
  const { showModal, setShowModal, handleOpenSignInScaner } = props;
  const { all_users, current_user } = useSelector((state) => state.auth);
  const { is_push_subscribe } = useSelector((state) => state.settings);

  const [fetching, setFetching] = useState(false);

  // const availableUsers = all_users.filter(
  //   (user) => (user?.biom?.biom_uuid || user?.biom?.uuid) === selected_office_info?.biom_uuid
  // );

  // const default_account = all_users.find((user) => user?.user_uuid === selected_office_info?.default_account);

  const unsubscribeOldUser = async () => {
    const checkIsAvailable = initIsPushAvailableCheck();
    if (is_push_subscribe && checkIsAvailable) {
      const tokens = await checkCurrentUserTokens(current_user);
      const urls = currentUserUrls(current_user);
      return await handleUnsubscribe(tokens?.notify_token, false, true, urls.notification);
    }
  };

  const handleMakeActive = async (user) => {
    try {
      setFetching(true);
      if (user?.user_uuid !== current_user?.user_uuid) {
        dispatch(resetPartitions());
        dispatch(setSelectedDayUuid(null));
        await unsubscribeOldUser().then(() => {
          dispatch(setCurrentUserByUUID(user?.user_uuid));
          const fn = user?.first_name;
          const ln = user?.last_name;
          openNotify("success", `Account ${fn} ${ln} is selected as active`);
        });
      }
    } catch (e) {
    } finally {
      setFetching(false);
    }
  };

  // const handleNewDefaultAccount = (user) => {
  //   if (user?.user_uuid !== selected_office_info?.default_account) {
  //     const data = { user_uuid: user?.user_uuid, service_uuid: selected_office_info?.service_uuid };
  //     dispatch(setDefaultAccount(data));
  //     const fn = user?.first_name;
  //     const ln = user?.last_name;
  //     openNotify("success", `Account ${fn} ${ln} is selected as the default account`);
  //   }
  // };

  const handleCloseDialog = () => {
    setShowModal(false);
  };

  const handleSignIn = () => {
    handleOpenSignInScaner(current_user);
  };

  const content = (
    <>
      <div style={{ padding: "8px 10px 0 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle1">Available accounts</Typography>

        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />

      <List sx={{ position: "relative", maxHeight: "calc(80vh - 60px)", overflow: "auto" }}>
        {all_users.map((user) => {
          // console.log("user", user);

          const email = user?.email;
          const biom_name = user?.biom?.name || user?.biom?.biom_name;
          // const biom_domain = domain;
          const fn = user?.first_name?.[0] || "";
          const ln = user?.last_name?.[0] || "";
          const checkIsActive = user?.user_uuid === current_user?.user_uuid;
          return (
            <ListItem
              key={`item_${user?.user_uuid}`}
              secondaryAction={
                <div style={{ marginRight: "-8px" }}>
                  <IconButton onClick={() => handleMakeActive(user)}>
                    {checkIsActive ? (
                      <CheckCircleIcon color="primary" sx={{ fontSize: "36px" }} />
                    ) : (
                      <RadioButtonUncheckedIcon color="primary" sx={{ fontSize: "36px" }} />
                    )}
                  </IconButton>
                </div>
              }
            >
              <ListItemAvatar>
                {checkIsActive ? (
                  <LoadingButton
                    loading={fetching}
                    variant="contained"
                    sx={{ padding: "0 0", minWidth: "40px", height: "40px", borderRadius: "20px" }}
                    onClick={handleSignIn}
                  >
                    <QrCodeScannerIcon />
                  </LoadingButton>
                ) : (
                  <Avatar sx={{ background: "#bdbdbd" }}>
                    {fn}
                    {ln}
                  </Avatar>
                )}
              </ListItemAvatar>
              <div>
                <div className="modal_accounts_info_wrapper">
                  <AlternateEmailIcon color="neutral" sx={{ marginRight: "6px" }} />
                  <div className="modal_accounts_text_wrapper">{email}</div>
                </div>
                <div className="modal_accounts_info_wrapper">
                  <PublicIcon color="neutral" sx={{ marginRight: "6px" }} />
                  <div className="modal_accounts_text_wrapper"> {biom_name}</div>
                </div>
              </div>
            </ListItem>
          );
        })}
        {/* <div style={{ height: "200px" }}></div> */}
        {/* <div style={{ height: "100vh" }}></div> */}
      </List>
    </>
  );
  return <DialogWindow children={content} open={showModal} setOpen={setShowModal} styleProps={style} />;
};

import io from "socket.io-client";
import { prop, isNil } from "ramda";
import Cookies from "universal-cookie";

class _Socket {
  constructor() {
    this._socket = io();
    // this._url = url
  }

  connect(url, params, customHeaders) {
    this._socket = io.connect(
      url,
      {
        forceNew: true,
        path: "/socket",
        query: params,
        transportOptions: {
          polling: {
            extraHeaders: {
              ...customHeaders
            }
          }
        }
      },
      {
        secure: true,
        transports: ["flashsocket", "polling", "websocket"]
      }
    );
  }

  subscribe(event, callback) {
    const cookies = new Cookies();

    // this._socket.on("handle_event_1", async (msg) => {
    //   console.log('GOOD', msg);
    // });

    this._socket.on(event, async (socket) => {
      const msg = await socket;

      // console.log("socket.on", `${event}: `);
      switch (event) {
        // case  'get_new_notification': {
        //   if (msg && callback) {
        //     callback(JSON.parse(msg));
        //   }
        //   break;
        // }
        case "connect":
        case "new_notification":
        case "authorization":
        case "auth status":
        case "verification_result":
        case "notification_authorization":
        case "planning_changes":
        case "planning_errors":
        case "pwa_message":
        case "get_new_notification":
        case "update_vector_activities":
          if (!isNil(msg)) {
            // console.log("msg", msg);
            callback(msg);
          }
          break;
        case "authorization response":
          console.log("msg", msg);
          // eslint-disable-next-line camelcase
          const session_token = prop("session_token", msg);
          // eslint-disable-next-line camelcase
          const notification_session_token = prop("notification_session_token", msg);

          // console.log('session_token', session_token);

          // eslint-disable-next-line camelcase
          if (session_token) {
            const arrayOfCookies = cookies.getAll();

            cookies.set("Entity", session_token);
            cookies.set("Notification", notification_session_token);

            for (const name in arrayOfCookies) {
              // eslint-disable-next-line max-depth
              if (name.indexOf("temporary") !== -1) {
                cookies.remove(name, { path: "/" });
              }
            }

            callback(msg);
          }
          break;
        default:
          break;
      }
    });
  }

  send(key, param) {
    // console.log('socket.send', key, param);
    this._socket.emit(key, param);
  }

  disconnect() {
    try {
      this._socket.close();
      // console.log('socket disconnect');
      // this._socket.on('disconnect', () => {
      //   console.log('disconnect callback');
      //   console.log('disconnect', this._socket.connected);
      // });
    } catch (e) {
      // console.log('socket disconnect error');
    }
  }
}

export const Socket = new _Socket();

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import dayjs from "dayjs";

export const chatNotifySlice = createSlice({
  name: "chatNotify",
  initialState: {
    all_unread_notify: [],
    notify_length: 0
  },
  reducers: {
    setAllUnreadNotify: (state, action) => {
      // console.log("setAllUnreadNotify");
      const newArr = Array.isArray(action.payload) ? action.payload : [...state.all_unread_notify, action.payload];
      state.all_unread_notify = newArr.sort((mess1, mess2) => dayjs(mess2?.created_at).diff(dayjs(mess1?.created_at)));
      // console.log("newArr", newArr?.length);
      // state.notify_length = newArr?.length;
    },
    setNotifyLength: (state, action) => {
      state.notify_length = action?.payload;
    }
  },
  extraReducers: {
    // ---------- checkPins -----------------
    // [getBiomUsers.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getBiomUsers.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.biom_users = Array.isArray(action?.payload) ? action?.payload : [];
    // },
    // [getBiomUsers.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action?.payload?.message;
    // }
  }
});
export const { setAllUnreadNotify, setNotifyLength } = chatNotifySlice.actions;
export default chatNotifySlice.reducer;

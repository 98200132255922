import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setIsNotificationsAvailable } from "../../redux/settings";

export function ModalNotification({ open, setOpen }) {
  const dispatch = useDispatch();

  const handleCancel = () => {
    // console.log("handleCancel");
    setOpen(false);
  };

  const handleConfirm = () => {
    // console.log("handleConfirm");
    setOpen(false);
    initBrowserPushFunc();
  };

  const initBrowserPushFunc = () => {
    // Let's check if the browser supports notifications
    // console.log("navigator.serviceWorker", navigator.serviceWorker);

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    } else {
      if (Notification.permission === "granted") {
        dispatch(setIsNotificationsAvailable(true));
        // If it's okay let's create a notification
        // new Notification('Hi there!');
      }

      // Otherwise, we need to ask the user for permission
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            dispatch(setIsNotificationsAvailable(true));
          }
        });
      }
      if (Notification.permission === "denied") {
        // console.log("denied", Notification.permission);
        dispatch(setIsNotificationsAvailable(false));
      }
    }
    // console.log("top_level_check true");
    // Let's check whether notification permissions have already been granted

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <DialogTitle id="alert-dialog-title">
        {" "}
        <Typography variant="subtitle1">Pay attention</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          To receive <strong>push notifications</strong> about new messages, you must give permission to this application to display notifications on
          your device.
        </DialogContentText>
      </DialogContent>

      <div style={{ padding: "0px 16px 16px 16px", display: "flex" }}>
        <div style={{ width: "50%", padding: "0 8px 0 0", marginRight: "32px" }}>
          <Button onClick={handleCancel} fullWidth variant="outlined" color="info" sx={{ height: "64px" }}>
            Later
          </Button>
        </div>
        <div style={{ width: "50%", padding: "0 0 0 0" }}>
          <Button onClick={handleConfirm} fullWidth variant="contained" color={"success"} sx={{ height: "64px" }}>
            Allow
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchFilterValueUsers } from "../../redux/users";
import { setSearchFilterValueMessages } from "../../redux/chat";
import SearchComponent from "../../Pages/UsersPage/SearchComponent";
import { appRoutes } from "../../utils/constants";
import { useEffect } from "react";

export const HeaderSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { search_filter_value_users } = useSelector((state) => state.users);
  const { search_filter_value_messages } = useSelector((state) => state.chat);

  const handleUsersSearch = (val) => {
    dispatch(setSearchFilterValueUsers(val));
  };

  const handleMessagesSearch = (val) => {
    dispatch(setSearchFilterValueMessages(val));
  };

  useEffect(() => {}, []);

  return (
    <>
      {location.pathname === appRoutes.users.path && (
        <SearchComponent fullWidth={true} filterTitle={search_filter_value_users} onFilterTitle={handleUsersSearch} />
      )}
      {location.pathname === appRoutes.chat.path && (
        <SearchComponent
          fullWidth={true}
          filterTitle={search_filter_value_messages}
          onFilterTitle={handleMessagesSearch}
        />
      )}
    </>
  );
};

import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserByUUID } from "../../redux/auth";
import { setDefaultAccount } from "../../redux/office";

import { List, ListItem, Button, ListItemAvatar, Avatar, Chip, ListItemIcon } from "@mui/material";
import { IconButton, Typography, Divider, ListItemButton, ListItemText } from "@mui/material";
import { DialogWindow } from "../DialogWindow/DialogWindow";

import StarRateIcon from "@mui/icons-material/StarRate";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PublicIcon from "@mui/icons-material/Public";
import ApartmentIcon from "@mui/icons-material/Apartment";

import { openNotify } from "../OpenNotify/openNotify";

const style = {
  position: "absolute",
  top: "10vh",
  left: "16px",
  width: "calc(100vw - 32px)",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 0
};

export const SELECT_OFFICE = "SELECT_OFFICE";
export const RESET_TOKEN = "RESET_TOKEN";
export const ROOT_APT = "ROOT_APT";

export const ModalOffices = (props) => {
  const dispatch = useDispatch();
  const { showModal, setShowModal, handleSession, handleRoot54, handleSelectOffice, current_url, type } = props;
  const { all_offices } = useSelector((state) => state.office);
  const { selected_user_info } = useSelector((state) => state.auth);
  const isSelectType = type === SELECT_OFFICE;

  const availableOffices = !isSelectType
    ? all_offices.filter((office) => office?.biom_uuid === (selected_user_info?.biom?.biom_uuid || selected_user_info?.biom?.uuid))
    : all_offices;

  const handleClickVariant = async (office) => {
    switch (type) {
      case SELECT_OFFICE: {
        await handleSelectOffice(office);
        break;
      }
      case RESET_TOKEN: {
        await handleSession(office);
        break;
      }
      case ROOT_APT: {
        await handleRoot54(office);
        break;
      }
    }
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setShowModal(false);
  };

  const content = (
    <>
      <div style={{ padding: "8px 16px 0 16px", display: "flex", justifyContent: "space-between" }}>
        <Typography variant="subtitle1">Available services</Typography>

        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List sx={{ maxHeight: "calc(80vh - 60px)", overflow: "auto", paddingBottom: "16px" }}>
        {availableOffices.map((office) => {
          const checkIsActiveOffice = isSelectType ? current_url === office?.address?.[0] : false;
          // console.log("checkIsActiveOffice", isSelectType ? current_url === office?.address[0] : false);
          // console.log("current_url", current_url === office?.address[0]);
          // console.log("office?.address[0]", office?.address[0]);
          return (
            <ListItemButton
              key={office?.service_uuid}
              onClick={() => handleClickVariant(office)}
              sx={{ background: checkIsActiveOffice ? "rgb(237, 247, 237)" : "transparent" }}
            >
              <ListItem disablePadding key={`item_${office?.service_uuid}`}>
                <ListItemAvatar>
                  <ApartmentIcon color="primary" />
                </ListItemAvatar>
                <ListItemText>{office?.name_comment || office?.name}</ListItemText>
              </ListItem>
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
  return <DialogWindow children={content} open={showModal} setOpen={setShowModal} styleProps={style} />;
};

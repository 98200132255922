export const app_version = "6.14.9.9";

export const scannerType = {
  reg: "reg",
  sign_in: "sign_in",
  office: "office",
  office_classic_reg: "office_classic_reg",
  classic_reg: "classic_reg",
  classic_sign_in: "classic_sign_in",
  restore_classic_account: "restore_classic_account"
};

export const appRoutes = {
  relays: {
    path: "/relays",
    title: "Communications",
    title2: "messages",
    second_group: "Relay doors"
  },
  users: {
    path: "/users",
    title: "Messages",
    title2: "Chat",
    second_group: "Relay doors"
  },
  chat: {
    path: "/chat",
    title: "Chat",
    title2: "Chat",
    second_group: "Relay doors"
  },
  planning: {
    path: "/planning",
    title: "Planning",
    title2: "Planning",
    second_group: "Week planning"
  },
  doors: {
    path: "/doors",
    title: "Relays",
    title2: "Doors",
    second_group: "Relay doors"
  },
  accounts: {
    path: "/accounts",
    title: "Accounts",
    title2: "Account",
    second_group: "Active account"
  },
  account: {
    path: "/account",
    title: "Account",
    title2: "Account",
    second_group: "Active account"
  },
  settings: {
    path: "/settings",
    title: "Settings"
  }
};

export const front_date_with_time = "DD-MM-YYYY HH:mm";

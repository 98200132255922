import { useSelector, useDispatch } from 'react-redux';
import { setDebugMode, setShowFooterInChat } from '../../redux/settings';
import { useEffect, useState, useRef } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { TextField, Alert, IconButton, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import './index.css';

import {
  setIsNotificationsAvailable,
  deleteService,
  setIsPushSubscribe,
  setPushSubscription,
} from '../../redux/settings';

import { NoOfficesSettings } from '../../components/NoOfficesSettings/NoOfficesSettings';
import { SubGroup } from '../../components/Subgroup/Subgroup';
import { BasicFiltrationBlock } from './BasicFiltrationBlock';
import LaunchIcon from '@mui/icons-material/Launch';

import { UrlCard } from './UrlCard';

import AddIcon from '@mui/icons-material/Add';

import BugReportIcon from '@mui/icons-material/BugReport';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import InfoIcon from '@mui/icons-material/Info';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { PageLoadingComponent } from '../../components/PageLoadingComponent/PageLoadingComponent';
import { PushUnavailableBlock } from './PushUnavailableBlock';
import { ModalSettings } from '../../components/ModalSettings/ModalSettings';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import { ModalAccountsBackup } from '../../components/ModalAccountsBackup/ModalAccountsBackup';
import { BackupCreateForm } from '../../components/BackupCreateForm/BackupCreateForm';
import FullScreenDialog from '../../components/FullScreenDialog/FullScreenDialog';
import { fetchWithTimeout } from '../../utils/fetchWithTimeout';
import { setCurrentUserByUUID } from '../../redux/auth';
import { openNotify } from '../../components/OpenNotify/openNotify';

import { checkToken } from '../../utils/checkToken';
import { handleToken } from '../../utils/handleToken';
import { notify_url, entity_url } from '../../config/url';

import { checkSubscription } from '../../serviceWorkerUtils/checkSubscription';
import { handleSubscribeForPush } from '../../serviceWorkerUtils/handleSubscribeForPush';
import { handleUnsubscribe } from '../../serviceWorkerUtils/handleUnsubscribeForPush';
import { ModalNotification } from '../../components/ModalNotification/ModalNotification';
import { initIsPushAvailableCheck } from '../../serviceWorkerUtils/initIsPushAvailableCheck';
import { app_version } from '../../utils/constants';

export const Settings = () => {
  const dispatch = useDispatch();
  const { all_users, current_user } = useSelector((state) => state.auth);
  const { mentioned_projects } = useSelector((state) => state.projects);
  const { loading } = useSelector((state) => state.pushSubscription);
  const { isDebugMode, is_notifications_available, is_push_available, is_push_subscribe, show_footer_in_chat } =
    useSelector((state) => state.settings);
  const [initialFetching, setInitialFetching] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const checkCurrentUserTokens = async () => {
    try {
      const user = current_user || all_users[0];
      // console.log("checkInitialState");
      if (user?.user_uuid) {
        let entity_token = checkToken(current_user?.user_uuid, 'entity');
        // console.log("entity_token", entity_token);
        if (!entity_token) {
          entity_token = await handleToken(user, 'entity', entity_url, true);
        }
        let notify_token = checkToken(current_user?.user_uuid, 'notify');
        if (!notify_token) {
          notify_token = await handleToken(user, 'notify', notify_url, true);
        }
        return { entity_token: entity_token, notify_token: notify_token };
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleOffDebug = () => {
    dispatch(setDebugMode(false));
  };

  const handleOnDebug = () => {
    dispatch(setDebugMode(true));
  };

  useEffect(() => {
    checkSubscription();
  }, []);

  const handleSubscribeFunc = async () => {
    if (!is_push_subscribe) {
      try {
        setFetching(true);
        const tokens = await checkCurrentUserTokens();
        await handleSubscribeForPush(tokens?.notify_token, setFetching, false, notify_url);
      } catch (e) {
        setFetching(false);
      }
    }
  };

  const handleUnsubscribeFunc = async () => {
    if (is_push_subscribe) {
      try {
        setFetching(true);
        const tokens = await checkCurrentUserTokens();
        await handleUnsubscribe(tokens?.notify_token, setFetching, false, notify_url);
      } catch (e) {
        setFetching(false);
      }
    }
  };

  const isDebugAvailable = !!all_users?.length ? all_users.some((user) => user?.is_admin || user?.root) : false;

  useEffect(() => {
    setTimeout(() => {
      // console.log("Notification" in window);
      // console.log("is_push_available", is_push_available);
      if (is_push_available) {
        // console.log("Notification?.permission", Notification?.permission);
        if (
          Notification?.permission !== 'denied' &&
          Notification?.permission !== 'granted' &&
          !!current_user?.user_uuid
        ) {
          // console.log("1");
          setShowNotifyModal(true);
        }
        if (Notification?.permission === 'granted') {
          // console.log("2");
          dispatch(setIsNotificationsAvailable(true));
        }
      }
      setInitialFetching(false);
    }, 500);
  }, []);

  useEffect(() => {
    // console.log(is_push_available, !!current_user?.user_uuid, is_notifications_available);
    if (is_notifications_available) {
      initIsPushAvailableCheck();
    }
  }, [is_notifications_available]);

  const handleShowFooterInChat = () => {
    dispatch(setShowFooterInChat(!show_footer_in_chat));
  };

  return (
    <>
      {initialFetching ? (
        <PageLoadingComponent />
      ) : (
        <>
          <div style={{ marginTop: '8px' }}>
            <SubGroup label={'Application'} isBold={true} />
            <ListItem>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText>
                Application version: <span style={{ color: '#1976d2', fontWeight: '600' }}>{app_version}</span>{' '}
              </ListItemText>
            </ListItem>
            {!is_push_available || !current_user?.user_uuid || !is_notifications_available ? (
              <PushUnavailableBlock />
            ) : (
              <ListItem sx={{ alignItems: 'center' }}>
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ paddingTop: '3px', maxWidth: '100px' }}>Push notifications:</div>

                    <div style={{ marginLeft: '8px' }}>
                      <LoadingButton
                        loading={fetching}
                        variant={!is_push_subscribe ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '16px 0 0 16px', padding: `${!is_push_subscribe ? '4px' : '3px'} 4px` }}
                        onClick={handleUnsubscribeFunc}
                        disabled={!is_notifications_available}
                      >
                        Off
                      </LoadingButton>
                      <LoadingButton
                        loading={fetching}
                        variant={is_push_subscribe ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '0 16px 16px 0', padding: `${is_push_subscribe ? '4px' : '3px'} 4px` }}
                        onClick={handleSubscribeFunc}
                        disabled={!is_notifications_available}
                      >
                        On
                      </LoadingButton>
                    </div>
                  </div>
                </ListItemText>
              </ListItem>
            )}
            <ListItem sx={{ alignItems: 'center' }}>
              <ListItemIcon>
                <ViewSidebarIcon sx={{ transform: 'rotate(90deg)' }} />
              </ListItemIcon>
              <ListItemText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ paddingTop: '3px', maxWidth: '100px' }}>Display navigation in the chat:</div>

                  <div style={{ marginLeft: '8px' }}>
                    <Button
                      variant={!show_footer_in_chat ? 'contained' : 'outlined'}
                      sx={{ borderRadius: '16px 0 0 16px', padding: `${!show_footer_in_chat ? '4px' : '3px'} 4px` }}
                      onClick={handleShowFooterInChat}
                    >
                      Off
                    </Button>
                    <Button
                      variant={show_footer_in_chat ? 'contained' : 'outlined'}
                      sx={{ borderRadius: '0 16px 16px 0', padding: `${show_footer_in_chat ? '4px' : '3px'} 4px` }}
                      onClick={handleShowFooterInChat}
                    >
                      On
                    </Button>
                  </div>
                </div>
              </ListItemText>
            </ListItem>

            {isDebugAvailable ? (
              <ListItem sx={{ alignItems: 'center' }}>
                <ListItemIcon>
                  <BugReportIcon />
                </ListItemIcon>
                <ListItemText>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ paddingTop: '3px', maxWidth: '100px' }}>Debug mode:</div>

                    <div style={{ marginLeft: '8px' }}>
                      <Button
                        variant={!isDebugMode ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '16px 0 0 16px', padding: `${!isDebugMode ? '4px' : '3px'} 4px` }}
                        onClick={handleOffDebug}
                      >
                        Off
                      </Button>
                      <Button
                        variant={isDebugMode ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '0 16px 16px 0', padding: `${isDebugMode ? '4px' : '3px'} 4px` }}
                        onClick={handleOnDebug}
                      >
                        On
                      </Button>
                    </div>
                  </div>
                </ListItemText>
              </ListItem>
            ) : (
              ''
            )}
            <div style={{ marginTop: '16px' }}></div>

            {current_user?.user_uuid && (
              <>
                <SubGroup
                  label={`Filter messages by project list ${
                    mentioned_projects?.length
                      ? `(${current_user?.basic_filter_project_uuids?.length || 0}/${
                          mentioned_projects?.length < 11 ? mentioned_projects?.length : '10'
                        })`
                      : ''
                  }`}
                  isBold={true}
                />
                <BasicFiltrationBlock />
              </>
            )}

            <ModalNotification open={showNotifyModal} setOpen={setShowNotifyModal} />
          </div>
        </>
      )}
    </>
  );
};

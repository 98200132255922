import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardContent, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import DescriptionIcon from "@mui/icons-material/Description";

import { AssignedIssue } from "./AssignedIssue";
import { AssignedProject } from "./AssignedProject";

export const AssignedEntityBlock = () => {
  const { project_filter, issue_filter } = useSelector((state) => state.comments);
  return (
    <>
      {project_filter?.uuid && !issue_filter?.uuid && <AssignedProject />}
      {issue_filter?.uuid && <AssignedIssue />}
    </>
  );
};

import { Typography } from "@mui/material";

export const MessageCard = (props) => {
  const { path, message } = props;
  return (
    <div style={{ position: "relative", margin: "0 0" }}>
      <div className="modal_accounts_backup_info_wrapper">
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "calc(100% - 2px)",
            overflow: "hidden"
          }}
        >
          {path}
        </Typography>
      </div>
      <div className="modal_accounts_backup_info_wrapper">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "calc(100% - 2px)",
            overflow: "hidden"
          }}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

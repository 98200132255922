import { createCookieName } from "./createCookieName";

function getCookie(name) {
  // console.log("name", name);
  var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : null;
}

export const checkToken = (user_uuid, service_uuid) => {
  const cookieName = createCookieName(user_uuid, service_uuid);
  // console.log("cookieName", cookieName);
  // console.log("getCookie", getCookie(cookieName));
  let token = getCookie(cookieName);
  // if (!token) {
  //   const current_user = store.getState().auth.current_user;
  //   if (service_uuid === "entity") {
  //     token = await handleToken(current_user, "entity", entity_url, true);
  //   }
  //   if (service_uuid === "notify") {
  //     token = await handleToken(current_user, "notify", notify_url, true);
  //   }
  // }
  return token;
};

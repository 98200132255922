const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: "0 0 16px 0", position: "relative" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const NavBtnContent = ({ idx, navArr }) => {
  return (
    <>
      <TabPanel value={1} index={1}>
        <div>{navArr[idx].icon}</div>
        <div style={{ marginTop: "-10px" }}>{navArr[idx].title}</div>
      </TabPanel>
    </>
  );
};

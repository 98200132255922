import { store } from "../redux/store";
import { getVAPIDPublicKey } from "../redux/pushSubscription";
import { setIsPushSubscribe } from "../redux/settings";
import { updateSubscriptionOnServer } from "./updateSubscriptionOnServer";
import { updateSubscriptionTime } from "./updateSubscriptionTime";

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const create_public_key = async (notify_token) => {
  try {
    const key = await getKey(notify_token);
    // alert(`key: ${key}`);
    // console.log("public_key", public_key);
    const applicationServerKey = urlB64ToUint8Array(key);
    // console.log("applicationServerKey", applicationServerKey);
    return applicationServerKey;
  } catch (e) {
    // alert("cannot uint8 error");
    console.log("error", e);
  }
};

const getKey = async (notify_token) => {
  const dispatch = store.dispatch;
  const data = { token: notify_token };
  const public_key = await dispatch(getVAPIDPublicKey(data)).unwrap();
  // console.log("public_key", public_key);
  return public_key;
  // console.log("public_key", public_key);
};

export const handleSubscribeForPush = async (notify_token, fetchingCallback, without_notify, url) => {
  if (!!fetchingCallback) {
    fetchingCallback(true);
  }
  const dispatch = store.dispatch;
  try {
    return await navigator.serviceWorker.ready
      .then(async (registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: await create_public_key(notify_token)
        };
        // console.log("subscribeOptions >>>>>>>>>>>>>>", subscribeOptions);
        return await registration.pushManager.subscribe(subscribeOptions);
      })
      .then(async (pushSubscription) => {
        const receivedPushSubscription = JSON.stringify(pushSubscription);
        // console.log("receivedPushSubscription", receivedPushSubscription);
        // alert(`handleSubscribe type of pushSubscription: ${typeof pushSubscription}`);
        return await updateSubscriptionOnServer(receivedPushSubscription, notify_token, without_notify, url)
          .then(() => {
            dispatch(setIsPushSubscribe(true));
            updateSubscriptionTime();
            return pushSubscription;
          })
          .catch((e) => console.log("handleSubscribeForPush cant update subscription", e));
      })
      .catch((e) => {
        console.log(`subscribeUserToPush error`, e);
        // alert(`subscribeUserToPush error ${JSON.stringify(e)}`)
      });
  } catch (e) {
    console.log("e", e);
  } finally {
    if (!!fetchingCallback) {
      fetchingCallback(false);
    }
  }
};

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "./btns_css.css";
import { setIsFiltersDialog, setFiltersDialogType, filterDialogTypesArr } from "../../../redux/chat";

export const IssueFilterButton = ({}) => {
  const dispatch = useDispatch();
  const { project_filter: chat_project_filter, issue_filter: chat_issue_filter } = useSelector((state) => state.chat);
  const {
    project_filter: comments_project_filter,
    issue_filter: comments_issue_filter,
    is_open_comment_form
  } = useSelector((state) => state.comments);
  const project_filter = is_open_comment_form ? comments_project_filter : chat_project_filter;
  const issue_filter = is_open_comment_form ? comments_issue_filter : chat_issue_filter;

  const handleOpenDialog = () => {
    dispatch(setIsFiltersDialog(true));
    dispatch(setFiltersDialogType(filterDialogTypesArr[1]));
  };
  return (
    <Button
      sx={{ minWidth: "64px", height: "54px", borderRadius: "16px 0 0 16px" }}
      variant={issue_filter?.uuid ? "contained" : "outlined"}
      color="info"
      onClick={handleOpenDialog}
    >
      <AssignmentIcon />
    </Button>
  );
};

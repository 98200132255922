import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { entity_url } from "../config/url";

export const getAllEntityCommentsUuids = createAsyncThunk("comments/getAllEntityCommentsUuids", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/affix/list`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("response?.data?.affix_uuids", response?.data?.affix_uuids);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getAllEntityComments = createAsyncThunk("comments/getAllEntityComments", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/affix/read`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("getAllEntityComments", response);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const getAssignedEntityFullData = createAsyncThunk("comments/getAssignedEntityFullData", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  const { partition_uuid, req_body, token } = props;
  try {
    // const token = getCookie("service_token");

    // console.log("token", token);

    const response = await createAxios().post(`${entity_url}/entity/read`, req_body, {
      headers: {
        "Session-token": token,
        "Partition-Uuid": partition_uuid
      }
    });
    // console.log("getAllEntityComments", response);
    return response?.data[0];
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    is_open_comment_form: false,
    entity_data: null,
    entity_full_data: null,
    comments_uuids: [],
    comments: [],
    project_filter: null,
    issue_filter: null,
    newMessageBlockHeight: null,
    loading: false
  },
  reducers: {
    setIsOpenCommentForm: (state, action) => {
      state.is_open_comment_form = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setEntityData: (state, action) => {
      state.entity_data = action.payload;
    },
    setProjectFilter: (state, action) => {
      state.project_filter = action.payload;
    },
    setIssueFilter: (state, action) => {
      state.issue_filter = action.payload;
    },
    setNewMessageBlockHeight: (state, action) => {
      state.newMessageBlockHeight = action.payload;
    }
    // setBiomUsersWithMessages: (state, action) => {
    //   // console.log(action.payload?.uuid);
    //   state.current_chat_user = action.payload;
    // }
  },
  extraReducers: {
    // ---------- getAllEntityCommentsUuids -----------------
    [getAllEntityCommentsUuids.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllEntityCommentsUuids.fulfilled]: (state, action) => {
      state.loading = false;
      state.comments_uuids = Array.isArray(action?.payload?.affix_uuids) ? action?.payload?.affix_uuids : [];
    },
    [getAllEntityCommentsUuids.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getAllEntityComments -----------------
    [getAllEntityComments.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllEntityComments.fulfilled]: (state, action) => {
      state.loading = false;
      const dataArr = Array.isArray(action?.payload) ? action?.payload : [];
      const modifiedData = dataArr.map((comment) => ({
        ...comment,
        created_at: comment?.created,
        message: comment?.params?.comment
      }));
      state.comments = modifiedData;
    },
    [getAllEntityComments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- getAssignedEntityFullData -----------------
    [getAssignedEntityFullData.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssignedEntityFullData.fulfilled]: (state, action) => {
      state.loading = false;
      state.entity_full_data = action?.payload;
    },
    [getAssignedEntityFullData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const { setComments, setEntityData, setIsOpenCommentForm, setProjectFilter, setIssueFilter, setNewMessageBlockHeight } = commentsSlice.actions;
export default commentsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAxios } from "../Axios/API_AXIOS";
import { checkToken } from "../utils/checkToken";
import { deleteServiceTokens } from "../utils/deleteServiceTokens";

// const https = require("https");

export const checkPins = createAsyncThunk("auth/checkPins", async (props, { rejectWithValue }) => {
  // console.log("adminLogin", data);
  // console.log("createNewUser", props);
  const { url, user_uuid, service_uuid, pub_ssl, isInternal } = props;
  // const httpsAgent = new https.Agent({ ca: pub_ssl });
  const checkPinsUrl = `${url}/test_connection/`;
  try {
    const response = await createAxios({
      "Session-token": checkToken(user_uuid, service_uuid)
    }).post(checkPinsUrl, null);
    return response?.data;
  } catch (error) {
    // console.log(error);
    return rejectWithValue(error?.response?.data);
  }
});

export const openPin = createAsyncThunk("auth/openPin", async (props, { rejectWithValue }) => {
  try {
    // console.log("adminLogin", data);
    // console.log("createNewUser", props);
    const { url, token, pub_ssl } = props;
    // const httpsAgent = new https.Agent({ ca: pub_ssl });
    const response = await createAxios({ "Session-token": token }).post(url, null);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const officeSlice = createSlice({
  name: "office",
  initialState: {
    current_office: null,
    all_offices: !!localStorage.getItem("all_offices") ? JSON.parse(localStorage.getItem("all_offices")) : [],
    selected_office_info: !!localStorage.getItem("current_office")
      ? JSON.parse(localStorage.getItem("current_office"))
      : null,
    loading: false
  },
  reducers: {
    // --------------- office ----------------------
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOfficeCommentData: (state, action) => {
      const uuid = action.payload?.service_uuid;
      const newData = action.payload?.data;
      const newOfficeData = { ...state.all_offices.find((office) => office?.service_uuid === uuid), ...newData };
      const newAllOfficesData = state.all_offices
        .slice(0)
        .map((office) => (office?.service_uuid === uuid ? newOfficeData : office));
      state.all_offices = newAllOfficesData;
      localStorage.setItem("all_offices", JSON.stringify(newAllOfficesData));
      // console.log("newOfficeData", newOfficeData);
      // console.log("newAllOfficesData", newAllOfficesData);
      // state.selected_office_info = newCurrentOffice;
      // localStorage.setItem("current_office", JSON.stringify(newCurrentOffice));
    },
    setDefaultAccount: (state, action) => {
      const user_uuid = action.payload?.user_uuid;
      const office_uuid = action.payload?.service_uuid;
      const newOfficeData = {
        ...state.all_offices.find((office) => office?.service_uuid === office_uuid),
        default_account: user_uuid
      };
      state.current_office = newOfficeData;
      state.selected_office_info = newOfficeData;
      const newAllOffices = state.all_offices
        .slice(0)
        .map((office) => (office?.service_uuid === office_uuid ? newOfficeData : office));

      state.all_offices = newAllOffices;
      // console.log("user_uuid office_uuid", user_uuid, office_uuid);
      localStorage.setItem("current_office", JSON.stringify(newOfficeData));
      localStorage.setItem("all_offices", JSON.stringify(newAllOffices));
    },
    setCurrentOffice: (state, action) => {
      state.current_office = action.payload;
      if (!!action?.payload?.service_uuid) {
        state.selected_office_info = action.payload;
      }
      localStorage.setItem("current_office", JSON.stringify(action.payload));
    },
    setCurrentOfficeByUUID: (state, action) => {
      const uuid = action.payload;
      if (!!uuid) {
        const newCurrentOffice = state.all_offices.find((office) => office?.service_uuid === uuid);
        state.current_office = newCurrentOffice;
        state.selected_office_info = newCurrentOffice;
        localStorage.setItem("current_office", JSON.stringify(newCurrentOffice));
      } else {
        state.current_office = action.payload;
        localStorage.setItem("current_office", JSON.stringify(action.payload));
      }
    },
    setSelectedOfficeInfo: (state, action) => {
      const uuid = action.payload;
      const office = state.all_offices.slice(0).find((office) => office?.service_uuid === uuid);
      // console.log("office", current(office));
      state.selected_office_info = office;
    },
    addNewOffice: (state, action) => {
      const checkIsAlreadyInApp = state.all_offices
        .slice(0)
        .some((office) => office?.service_uuid === action.payload?.service_uuid);
      if (!checkIsAlreadyInApp) {
        const newOfficesData = [...state.all_offices, action.payload];
        const checkIsOtherOffices = !!state.all_offices?.length;

        if (!checkIsOtherOffices) {
          state.current_office = action.payload;
          localStorage.setItem("current_office", JSON.stringify(action.payload));
        }
        state.all_offices = newOfficesData;
        localStorage.setItem("all_offices", JSON.stringify(newOfficesData));
      }
    },
    deleteOffice: (state, action) => {
      const office_uuid = action?.payload;
      const newOfficesData = state.all_offices.slice(0).filter((office) => office?.service_uuid !== office_uuid);
      state.all_offices = newOfficesData;
      deleteServiceTokens(office_uuid);
      if (!!newOfficesData?.length) {
        state.current_office = newOfficesData[0];
        localStorage.setItem("all_offices", JSON.stringify(newOfficesData));
        localStorage.setItem("current_office", JSON.stringify(newOfficesData[0]));
      } else {
        state.current_office = null;
        localStorage.removeItem("all_offices");
        localStorage.removeItem("current_office");
      }
    }
  },
  extraReducers: {
    // ---------- checkPins -----------------
    [checkPins.pending]: (state, action) => {
      state.loading = true;
    },
    [checkPins.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [checkPins.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    // ---------- openPin -----------------
    [openPin.pending]: (state, action) => {
      state.loading = true;
    },
    [openPin.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [openPin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});
export const {
  setCurrentOffice,
  addNewOffice,
  deleteOffice,
  setSelectedOfficeInfo,
  setCurrentOfficeByUUID,
  setDefaultAccount,
  setOfficeCommentData,
  setLoading
} = officeSlice.actions;
export default officeSlice.reducer;

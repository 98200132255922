export const deleteServiceTokens = (uuid) => {
  try {
    const allServiceTokensNames = document.cookie
      .split(";")
      .map((item) => item?.trim())
      .filter((string) => string.includes(uuid))
      .map((string) => string.split("=")[0]);
    allServiceTokensNames.map((cookieName) => {
      // console.log("cookieName", cookieName);
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    });
  } catch (e) {}
};

// ---- react, lib -------------
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
// import { useMediaQuery } from "@mui/material";
// import axios from "axios";

// ---- redux -----------------
// import { setIsOpen } from "./redux/backHandler";
// import { setAllUnreadNotify } from "./redux/chatNotify";
// import { setIsTokenError } from "./redux/notify";
import { getPartitions } from './redux/settings';
// import { getChatUserMessages } from "./redux/chat";
import { setResetComponent } from './redux/chat';
import { setCurrentUser, updateAccountInfo } from './redux/auth';
import { setSyncData } from './redux/weekPlanning';

// ---- pages -----------------
import { ChatPage } from './Pages/ChatPage/ChatPage';
import { Account } from './Pages/Account/Account';
import { AccountsPage } from './Pages/AccountsPage/AccountsPage';
import { InstallPage } from './Pages/Install/InstallPage';
import { UsersPage } from './Pages/UsersPage/UsersPage';
import { WeekPlanningPage } from './Pages/WeekPlanningPage/WeekPlanningPage';

// ---- icons -----------------
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import CloseIcon from "@mui/icons-material/Close";

// ---- components -----------
// import { NewMessageBlock } from "./Pages/ChatPage/Blocks/NewMessageBlock";
import { UpdateNotify } from './components/UpdateNotify/UpdateNotify';
import { DebugComponent } from './components/DebugComponent/DebugComponent';
import { Settings } from './Pages/Settings/Settings';
// import { ModalPasswordError } from "./components/ModalPasswordError/ModalPasswordError";
import { ModalTrustedUrl } from './components/ModalTrustedUrl/ModalTrustedUrl';
import { Header } from './components/Header/Header';
import { Container } from '@mui/material';
import { Notistack } from './components/Notistack/Notistack';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { FooterNavigation } from './components/FooterNavigation/FooterNavigation';
import { Alert, CircularProgress } from '@mui/material';
import { useAddToHomeScreenPrompt } from './hooks/useAddToHomescreenPrompt';
import { appRoutes } from './utils/constants';
import { CommentForm } from './Pages/UsersPage/CommentForm/CommentForm';

import { isIOS } from 'react-device-detect';
import { Socket } from './Socket/socket';
import { notify_url } from './config/url';
import { getMentionedProjects } from './redux/projects';

// ---- utils -----------
import { initIsPushAvailableCheck } from './serviceWorkerUtils/initIsPushAvailableCheck';
import { checkSubscription } from './serviceWorkerUtils/checkSubscription';
import { checkCurrentUserTokens } from './utils/checkCurrentUserTokens';
import { checkUpdateTime } from './serviceWorkerUtils/checkUpdateTime';
import { handleSubscribeForPush } from './serviceWorkerUtils/handleSubscribeForPush';
import { currentUserUrls } from './utils/currentUserUrls';
import { handleResetWmsTokens } from './utils/handleResetWmsTokens';
import { checkToken } from './utils/checkToken';

export default function App() {
  const [promptable, promptToInstall, isInstalled] = useAddToHomeScreenPrompt();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstRenderRef = useRef(true);
  const [initialFetching, setInitialFetching] = useState(true);

  const { current_user } = useSelector((state) => state.auth);
  const { isConnection } = useSelector((state) => state.notify);
  const { is_push_subscribe } = useSelector((state) => state.settings);
  const { sync_data } = useSelector((state) => state.weekPlanning);
  const { pub_partition } = useSelector((state) => state.settings);

  const getPM_Partition = async (entity_token, resetTokens) => {
    const partitions_data = {
      req_body: null,
      token: entity_token,
    };
    try {
      const resp = await dispatch(getPartitions(partitions_data)).unwrap();
      return resp;
    } catch (e) {
      // console.log('getPartitions e', e);
      const stringError = JSON.stringify(e);
      const checkFor401 = stringError.includes('unauthorized') || stringError.includes('401');
      if (checkFor401 && !resetTokens) {
        const tokens = await handleResetWmsTokens(current_user);
        if (tokens) {
          // console.log('getPartitions tokens', tokens);
          return await getPM_Partition(tokens[0], true);
        }
      }
    }
    // console.log("resp_part_uuids", resp_part_uuids);
  };

  // useEffect(() => {
  //   console.log('pub_partition', pub_partition);
  // }, [pub_partition]);

  const initialFunction = async () => {
    try {
      // console.log("initialFunction");
      let prevPath = localStorage.getItem('currentRoute');
      const checkIsInstall = location.pathname === '/install';

      if (current_user?.user_uuid) {
        const tokens = await checkCurrentUserTokens(current_user, 'initialFunction');
        const entity_token = tokens?.entity_token;
        const notify_token = tokens?.notify_token;
        const respPart = await getPM_Partition(entity_token);
        if (!respPart) {
          let entity_token = checkToken(current_user?.user_uuid, 'entity');
          await getPM_Partition(entity_token);
        }
        const urls = currentUserUrls(current_user);
        initSocketFunc(notify_token, urls?.notification);
        initIsPushAvailableCheck();
        if (!checkIsInstall) {
          navigate(prevPath || appRoutes.accounts.path);
        }
        // initSocketFunc(notify_token);
      } else {
        if (!checkIsInstall) {
          navigate(appRoutes.accounts.path);
        }
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setTimeout(() => {
        setInitialFetching(false);
        firstRenderRef.current = false;
      }, 270);
    }
  };

  const initSocketFunc = async (notify_token, notify_url) => {
    Socket.disconnect();
    const url = `${notify_url}/notification`;
    await Socket.connect(url);
    await Socket.subscribe('connect', () => console.log('connect'));
    await Socket.subscribe('notification_authorization', () => {
      // console.log("notification_authorization", notify_token);
      Socket.send('notification_authorization', notify_token);
    });
    await Socket.subscribe('get_new_notification', () => {
      // console.log("get_new_notification");
      dispatch(setResetComponent(true));
    });
    await Socket.subscribe('pwa_message', () => {
      // console.log("pwa_message");
      dispatch(setResetComponent(true));
    });
    // console.log("Socket.subscribe update_vector_activities");
    await Socket.subscribe('update_vector_activities', (msg) => {
      // msg - is object already, don't parse!
      console.log('update_vector_activities', msg?.params);
      dispatch(setSyncData(msg?.params));
    });
    Socket.subscribe('connect_error', (err) => {
      // console.log("connect_error");
      console.log(`connect_error due to ${err?.message}`);
    });
  };

  useEffect(() => {
    if (location.pathname !== appRoutes.planning.path) {
      dispatch(setSyncData(null));
    }
  }, [sync_data]);

  const updateCurrentUserPushSubscription = async () => {
    const top_level_check = await initIsPushAvailableCheck();
    if (top_level_check && current_user?.user_uuid) {
      const subscription = await checkSubscription();
      if (!!subscription) {
        const checkIsNeedToUpdate = checkUpdateTime();
        if (checkIsNeedToUpdate) {
          const tokens = await checkCurrentUserTokens(current_user, 'updateCurrentUserPushSubscription');
          await handleSubscribeForPush(tokens?.notify_token, false, true, notify_url);
        }
      }
    }
  };

  const checkPath = location.pathname !== '/install';
  const windowInnerHeight = window.innerHeight;
  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;

  useEffect(() => {
    checkUpdateTime();
    setInitialFetching(true);
    setTimeout(() => {
      initialFunction();
    }, 200);
    updateCurrentUserPushSubscription();
  }, []);

  const checkBasicFilterUuids = async (notify_token) => {
    const checkIsBasicFilter = Array.isArray(current_user?.basic_filter_project_uuids);
    if (!checkIsBasicFilter) {
      const data = { token: notify_token };
      dispatch(getMentionedProjects(data));
      const resp = await dispatch(getMentionedProjects(data)).unwrap();
      const basic_filter_project_uuids = Array.isArray(resp) ? resp.slice(0, 10).map((item) => item?.project_uuid) : [];
      const currentUserData = {
        ...current_user,
        basic_filter_project_uuids: basic_filter_project_uuids,
      };
      // console.log("currentUserData", currentUserData);
      dispatch(updateAccountInfo(currentUserData));
      dispatch(setCurrentUser(currentUserData));
    }
  };

  const handleChangeCurrentUser = async () => {
    // console.log("handleChangeCurrentUser");
    if (current_user?.user_uuid) {
      Socket.disconnect();
      const tokens = await checkCurrentUserTokens(current_user, 'handleChangeCurrentUser');
      if (!!tokens?.entity_token && !!tokens?.notify_token) {
        const checkIsAvailable = await initIsPushAvailableCheck();
        const urls = currentUserUrls(current_user);
        if (is_push_subscribe && checkIsAvailable) {
          setTimeout(() => {
            // !!!!!!!
            // important setTimeout:
            // ServiceWorker does not have time to unsubscribe the old account
            // by the time you apply for a subscription to a new account
            // !!!!!!!
            handleSubscribeForPush(tokens?.notify_token, false, true, notify_url);
          }, 1500);
        }
        getPM_Partition(tokens?.entity_token);
        initSocketFunc(tokens?.notify_token, urls?.notification);
        checkBasicFilterUuids(tokens?.notify_token);
      }
    }
  };

  useEffect(() => {
    // console.log("app useEffect current_user >>>>>>>>>>>>>>.", current_user?.biom?.biom_domain);
    if (!firstRenderRef.current) {
      handleChangeCurrentUser();
    }
    // return () => Socket.disconnect();
  }, [current_user?.user_uuid]);

  return (
    <>
      {!initialFetching && (
        <>
          <ModalTrustedUrl />
          <DebugComponent />
          <Notistack />
          <UpdateNotify />
          <CommentForm />
        </>
      )}
      <Container maxWidth='sm' disableGutters sx={{ transform: 'rotate(0deg)' }}>
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            zIndex: initialFetching ? '20000' : '-2',
            background: 'white',
          }}
        >
          {initialFetching && <CircularProgress />}
        </div>
        {checkPath && <Header />}
        {!isConnection && (
          <Alert
            severity='warning'
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: checkIsTallFooter ? '0' : '64px',
              left: '0',
              zIndex: '10',
            }}
          >
            No internet connection!
          </Alert>
        )}
        <Routes>
          <Route path={'/'} element={<Navigate to={appRoutes.accounts.path} replace={true} />} />
          <Route
            path='/install'
            element={
              <InstallPage promptable={promptable} promptToInstall={promptToInstall} isInstalled={isInstalled} />
            }
          />

          <Route path={appRoutes.chat.path} element={<PageWrapper page={<ChatPage />} />} />
          <Route path={appRoutes.planning.path} element={<PageWrapper page={<WeekPlanningPage />} />} />
          <Route path={appRoutes.users.path} element={<PageWrapper page={<UsersPage />} />} />
          <Route path={appRoutes.accounts.path} element={<PageWrapper page={<AccountsPage />} />} />
          <Route path={appRoutes.account.path} element={<PageWrapper page={<Account />} />} />
          <Route path={appRoutes.settings.path} element={<PageWrapper page={<Settings />} />} />
        </Routes>

        {checkPath && !initialFetching && <FooterNavigation />}
      </Container>
    </>
  );
}

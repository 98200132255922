import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { sendMessagesViewed, setReplyData } from "../../redux/chat";
import { setIsOpenCommentForm, setProjectFilter, setIssueFilter } from "../../redux/comments";

import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplyIcon from "@mui/icons-material/Reply";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";

import { checkToken } from "../../utils/checkToken";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

export const MessageActions = ({ message, setIsViewed, isViewed }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);

  const isInbox = message?.message_type === "inbox";

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMarkViewed = async () => {
    setLoading(true);
    try {
      const notify_token = checkToken(current_user?.user_uuid, "notify");
      const data = {
        token: notify_token,
        req_data: { messages: [message?.uuid] }
      };
      const resp = await dispatch(sendMessagesViewed(data)).unwrap();
      setIsViewed(true);
      handleClose();
    } catch (e) {
      console.log("handleMarkViewed error", e);
    } finally {
      setLoading(false);
    }
  };
  const open = Boolean(anchorEl);

  const id = !!anchorEl ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // console.log("handleClose");
    setAnchorEl(null);
  };

  const handleReply = () => {
    dispatch(setReplyData(message));
    handleClose();
  };

  const entity_type = message?.params?.entity?.assigned_entity_type;
  const handleOpenEntityCommentModal = () => {
    // console.log("message", message);
    dispatch(setIsOpenCommentForm(true));
    const entity_data = message?.params?.entity;
    const entity_type = entity_data?.assigned_entity_type;
    if (entity_type === "issue") {
      const data = {
        partition: entity_data?.partition,
        uuid: entity_data?.assigned_entity_uuid,
        id: entity_data?.assigned_entity_id,
        title: entity_data?.assigned_entity_title,
        project_data: {
          partition: entity_data?.partition,
          uuid: entity_data?.project_uuid,
          id: entity_data?.project_id,
          title: entity_data?.project_title
        }
      };
      dispatch(setIssueFilter(data));
    }
    if (entity_type === "project") {
      const data = {
        partition: entity_data?.partition,
        uuid: entity_data?.project_uuid,
        id: entity_data?.project_id,
        title: entity_data?.project_title
      };
      dispatch(setProjectFilter(data));
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        sx={{ position: "absolute", top: "5px", right: isInbox ? "-6px" : "6px" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {!isViewed && isInbox && (
          <MenuItem onClick={handleMarkViewed}>
            <ListItemIcon>
              <MarkChatReadIcon />
            </ListItemIcon>
            Mark as viewed
          </MenuItem>
        )}
        {isInbox && (
          <MenuItem onClick={handleReply}>
            <ListItemIcon>
              <ReplyIcon />
            </ListItemIcon>
            Reply
          </MenuItem>
        )}

        {message?.params?.entity && (entity_type === "issue" || entity_type === "project") && (
          <MenuItem onClick={handleOpenEntityCommentModal} sx={{ marginTop: "0", maxWidth: "calc(100% - 16px)" }}>
            <ListItemIcon>
              <ReplyIcon sx={{ marginLeft: "3px", position: "relative", transform: "scale(-1, 1)" }} />
            </ListItemIcon>

            <ListItemText sx={{ position: "relative", top: "4px" }}>
              To{" "}
              <EntityTitleComponent
                type={message?.params?.entity?.assigned_entity_type}
                id={message?.params?.entity?.assigned_entity_id}
                title={message?.params?.entity?.assigned_entity_title}
              />
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

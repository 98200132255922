import { useEffect, useState } from "react";
import { Alert, Snackbar, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setType, setIsOpen } from "../../redux/notify";

export const Notistack = () => {
  const dispatch = useDispatch();
  const { type, message, isOpen } = useSelector((state) => state.notify);

  useEffect(() => {
    // console.log("snack open");
    if (isOpen) {
      setTimeout(
        () => {
          handleClose();
        },
        type === "error" ? 4000 : 2000
      );
    }
  }, [isOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setIsOpen(false));
    setTimeout(() => {
      dispatch(setMessage(""));
      dispatch(setType(""));
    }, 100);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ zIndex: 15000 }}
      onClick={handleClose}
    >
      <Container maxWidth="sm" disableGutters>
        <Alert onClose={handleClose} severity={type || "error"} sx={{ width: "100%", display: "flex", alignItems: "center", fontSize: "16px" }}>
          {message || ""}
        </Alert>
      </Container>
    </Snackbar>
  );
};

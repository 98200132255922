import { useSelector, useDispatch } from "react-redux";
import { setProjectFilter } from "../../redux/chat";
import { setProjectFilter as setProjectFilterComments } from "../../redux/comments";
import { Chip } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export const ProjectResetChip = ({ isFilters }) => {
  const dispatch = useDispatch();

  const { project_filter: chat_project_filter, issue_filter: chat_issue_filter } = useSelector((state) => state.chat);
  const {
    project_filter: comments_project_filter,
    issue_filter: comments_issue_filter,
    is_open_comment_form
  } = useSelector((state) => state.comments);
  const project_filter = is_open_comment_form ? comments_project_filter : chat_project_filter;
  const issue_filter = is_open_comment_form ? comments_issue_filter : chat_issue_filter;

  const handleResetProjectFilter = () => {
    // console.log("handleResetProjectFilter");
    if (is_open_comment_form) {
      dispatch(setProjectFilterComments(null));
    } else {
      dispatch(setProjectFilter(null));
    }
  };

  return (
    <div style={{ display: "inline-block", padding: isFilters ? "0 8px" : "0 0", background: "white", zIndex: "15" }}>
      <Chip
        variant="filled"
        icon={<AccountTreeIcon sx={{ fontSize: "16px" }} />}
        label={
          <div className="ellipsys_title" style={{ maxWidth: "150px" }}>{`${project_filter?.id || "t_id"} ${
            project_filter?.title || "t_issue"
          }`}</div>
        }
        sx={{ margin: isFilters ? "0 0" : "0 6px 6px 0", fontSize: "12px" }}
        onClick={handleResetProjectFilter}
        onDelete={handleResetProjectFilter}
        color={"primary"}
      />
    </div>
  );
};

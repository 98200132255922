import { useDispatch, useSelector } from "react-redux";
import { setIsOpenCommentForm, setProjectFilter, setIssueFilter } from "../../../redux/comments";

import FullScreenDialog from "../../../components/FullScreenDialog/FullScreenDialog";
import { FormContent } from "./FormContent";

// children, open, setOpen;
export const CommentForm = () => {
  const dispatch = useDispatch();
  const { is_open_comment_form } = useSelector((state) => state.comments);

  const handleOpenCommentForm = (bool) => {
    dispatch(setIsOpenCommentForm(bool));
    if (!bool) {
      dispatch(setProjectFilter(null));
      dispatch(setIssueFilter(null));
    }
  };

  return <FullScreenDialog open={is_open_comment_form} setOpen={handleOpenCommentForm} children={<FormContent setOpen={handleOpenCommentForm} />} />;
};

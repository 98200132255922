import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { deleteDateIssue, setPeriodDaysData, updateDateIssue, createDateIssue, setPeriodDateActivities } from "../../../redux/weekPlanning";

import { IconButton, TextField, Collapse } from "@mui/material";
import { ActivityActions } from "../../../components/ActivityActions/ActivityActions";
import { Checkbox, Button, Divider, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { checkToken } from "../../../utils/checkToken";
import { weekPlanningActions } from "../../../Socket/weekPlanningActions";

export const DayCardIssue = ({ activity, handleUpdateParentState, parentFetching, setActivitiesArr, syncChanges, setSomeIssueEditing }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { pub_partition, week_planning_unique_uuid } = useSelector((state) => state.settings);
  const { selected_day, period_days_data } = useSelector((state) => state.weekPlanning);

  const [isFetching, setIsFetching] = useState(true);
  const [isUpdateFetching, setIsUpdateFetching] = useState(false);
  const [isCompleted, setIsCompleted] = useState(activity?.completed);



  const defaultvalues = {
    title: activity?.title,
    weight: activity?.weight
  };

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isDirty }
  } = useForm({
    defaultValues: defaultvalues
  });

  useEffect(() => {
    setSomeIssueEditing(isDirty);
  }, [isDirty])

  const token = checkToken(current_user?.user_uuid, "entity");

  const handleChangeCompleted = async () => {
    // console.log("update 1");
    const values = getValues();
    const req_body = {
      entity_type: "issue",
      entity_uuid: activity?.uuid,
      params: { ...activity, ...values, completed: !isCompleted }
    };
    const data = {
      req_body: req_body,
      token: token,
      partition_uuid: pub_partition?.header_uuid
    };
    await dispatch(updateDateIssue(data)).then(async (resp) => {
      // console.log("resp", resp?.payload?.params);
      const { completed, weight, title, date } = resp?.payload?.params;
      const newActivity = { uuid: resp?.payload?.uuid, completed, weight, title, date };
      // if (!!newIssueBeforeOldUpdating?.current) {
      //   setActivitiesArr((prev) => {
      //     const newArr = [...prev?.map((issue) => (issue?.uuid === activity?.uuid ? newActivity : issue)), newIssueBeforeOldUpdating.current];
      //     console.log("newArr", newArr);
      //     return newArr;
      //   });
      //   newIssueBeforeOldUpdating.current = null;
      // } else {
      //   setActivitiesArr((prev) => prev?.map((issue) => (issue?.uuid === activity?.uuid ? newActivity : issue)));
      // }
      setActivitiesArr((prev) => prev?.map((issue) => (issue?.uuid === activity?.uuid ? newActivity : issue)));
      const req_data = { action_type: weekPlanningActions.update, parent_date: newActivity?.date, data: [newActivity] };
      syncChanges(req_data);
      setIsCompleted(!isCompleted);
      // setSomeIssueEditing(false);
    });
  };

  const onSubmit = async (val) => {
    // console.log("update 2");
    // console.log('onSubmit', val)
    // console.log('activity', activity)
    if (isDirty) {
      try {
        setIsUpdateFetching(true);
        const req_body = {
          entity_type: "issue",
          entity_uuid: activity?.uuid,
          params: val
        };
        const data = {
          req_body: req_body,
          token: token,
          partition_uuid: pub_partition?.header_uuid
        };
        await dispatch(updateDateIssue(data)).then(async (resp) => {
          const { completed, weight, title, date } = resp?.payload?.params;
          const newActivity = { uuid: resp?.payload?.uuid, completed, weight, title, date };
          // if (!!newIssueBeforeOldUpdating?.current) {
          //   setActivitiesArr((prev) => {
          //     const newArr = [...prev?.map((issue) => (issue?.uuid === activity?.uuid ? newActivity : issue)), newIssueBeforeOldUpdating.current];
          //     console.log("newArr", newArr);
          //     return newArr;
          //   });
          //   newIssueBeforeOldUpdating.current = null;
          // } else {

          // }
          // console.log("resp 23123", { ...issue, ...val });
          // await handleUpdateParentState();
          setActivitiesArr((prev) => prev?.map((issue) => (issue?.uuid === activity?.uuid ? newActivity : issue)));
          const req_data = { action_type: weekPlanningActions.update, parent_date: newActivity?.date, data: [newActivity] };
          syncChanges(req_data);
        });
        reset(val);
      } catch (e) {
      } finally {
        setIsUpdateFetching(false);
      }
    }
  };

  const handleDeleteIssue = async () => {
    const req_body = {
      entity_type: "issue",
      entity_uuid: activity?.uuid
    };
    const data = {
      date_string: activity?.date,
      req_body: req_body,
      token: token,
      partition_uuid: pub_partition?.header_uuid
    };
    await dispatch(deleteDateIssue(data)).then(async () => {
      setActivitiesArr((prev) => prev?.filter((issue) => issue?.uuid !== activity?.uuid));
      const req_data = {
        action_type: weekPlanningActions.delete,
        parent_date: activity?.date,
        data: [{ uuid: activity?.uuid, date: activity?.date }]
      };
      syncChanges(req_data);
      // return await handleUpdateParentState();
    });
  };

  useLayoutEffect(() => {
    setIsFetching(true);
    reset(defaultvalues);
    setIsCompleted(activity?.completed);
    setTimeout(() => {
      setIsFetching(false);
    }, 150);
  }, [JSON.stringify(activity)]);

  const calculateActivityWeight = (activitiesArr) => {
    if (!activitiesArr?.length) {
      return (activitiesArr?.length + 1) * 1000;
    }
    const weightArr = activitiesArr.map((item) => item?.weight);
    const maxWeight = Math.max.apply(null, weightArr);

    return maxWeight + 1000;
  };

  // const handleNewPeriodDaysData = (newDaysDataArr) => {
  //   const newArr = period_days_data.map(dayOptions => )
  // }

  const handleAddActivityToOtherDays = async (daysDataArr, sliceIndex, isTillWeekEnd) => {
    // console.log("daysDataArr", daysDataArr);
    try {
      const newActivitiesArrForSync = [];
      const newDaysDataArr = await Promise.all(
        daysDataArr.map(async (dayOptions) => {
          const req_body = {
            entity_type: "issue",
            parent: dayOptions?.uuid,
            params: {
              date: dayOptions?.date?.format("YYYY-MM-DD"),
              title: activity?.title,
              completed: false,
              weight: calculateActivityWeight(dayOptions?.issues),
              typeForReport: "activity",
              status: "created",
              tracker: "ticket",
              priority: "low",
              project: week_planning_unique_uuid
            }
          };
          const data = {
            req_body: req_body,
            token: token,
            partition_uuid: pub_partition?.header_uuid
          };
          // return { ...dayOptions, issues: [...dayOptions?.issues, req_body] };
          const resp = await dispatch(createDateIssue(data)).unwrap();

          const newData = {
            uuid: resp?.uuid,
            date: req_body?.params.date,
            title: resp?.params?.title,
            completed: resp?.params?.completed,
            weight: resp?.params?.weight
          };
          newActivitiesArrForSync.push(newData);
          const newActivitiesArr = [...dayOptions?.issues, newData];
          // console.log("newActivitiesArr", newActivitiesArr);
          // console.log("dayOptions?.date", dayOptions?.date?.format("YYYY-MM-DD"));
          // console.log("newActivitiesArr", newActivitiesArr);
          const newDayOptions = { ...dayOptions, issues: newActivitiesArr };
          return newDayOptions;
        })
      );
      let newArr;
      if (isTillWeekEnd) {
        // console.log("period_days_data", period_days_data);
        const old_days_data = period_days_data?.slice(0, sliceIndex);
        const weekEnds = period_days_data.slice(5);
        newArr = [...old_days_data, ...newDaysDataArr, ...weekEnds];
        // console.log("old_days_data", old_days_data);
        // console.log("newDaysDataArr", newDaysDataArr);
        // console.log("weekEnds", weekEnds);
      } else {
        newArr = period_days_data?.slice(0).map((item, idx) => (sliceIndex === idx ? newDaysDataArr[0] : item));
      }

      // console.log("newActivitiesArrForSync", newActivitiesArrForSync);

      const req_data = {
        action_type: weekPlanningActions.duplicate,
        data: newActivitiesArrForSync
      };
      syncChanges(req_data);
      dispatch(setPeriodDaysData(newArr));
    } catch (e) {
      console.log("e", e);
    } finally {
      // setFetching(false);
    }
  };

  const duplicateForTomorrow = async () => {
    // console.log("selected_day", selected_day);
    const nextDayDate = selected_day?.add(1, "day");
    let nextDayIdx;
    period_days_data.forEach((dayOptions, idx) =>
      dayOptions?.date?.format("DD-MM-YYYY") === nextDayDate?.format("DD-MM-YYYY") ? (nextDayIdx = idx) : ""
    );

    await handleAddActivityToOtherDays([period_days_data[nextDayIdx]], nextDayIdx, false);
    // console.log("nextDayData", nextDayData);
    // dispatch(setDuplicateForTomorrow(dayObj));
  };

  const duplicateTillWeekEnd = async () => {
    // console.log("selected_day", selected_day);
    const nextDayDate = selected_day?.add(1, "day");
    let nextDayIdx;
    const workDays = period_days_data.slice(0, 5);
    workDays.forEach((dayOptions, idx) => (dayOptions?.date?.format("DD-MM-YYYY") === nextDayDate?.format("DD-MM-YYYY") ? (nextDayIdx = idx) : ""));
    const tillEndArr = workDays.slice(nextDayIdx);
    await handleAddActivityToOtherDays(tillEndArr, nextDayIdx, true);
    // console.log("nextDayData", nextDayData);
    // dispatch(setDuplicateTillWeekEnd(dayObj));
  };

  const isShowTitleLength = (isDirty || watch("title").trim()?.length === 200) && !isCompleted;

  // useEffect(() => {
  //   if (isDirty) {
  //     setSomeIssueEditing(true);
  //   }
  // }, [isDirty]);

  return (
    <div
      key={activity?.uuid}
      style={{
        marginBottom: "16px",
        paddingBottom: isShowTitleLength ? "16px" : "0",
        display: "flex",
        alignItems: "center",
        transition: ".3s all ease-in-out"
      }}
    >
      <IconButton onClick={handleChangeCompleted} disabled={parentFetching || isDirty}>
        {isCompleted ? <CheckBoxIcon color={parentFetching ? "disabled" : "primary"} /> : <CheckBoxOutlineBlankIcon />}
      </IconButton>
      <div style={{ width: "100%", position: "relative" }}>
        {/* <textarea type="text" rows="10" cols="10" maxlength="200" {...register("title")} /> */}
        {/* {!isFetching && (
          
        )} */}        
        <TextField
          multiline={true}
          minRows={1}
          maxRows={undefined}
          autoComplete="off"
          fullWidth
          {...register("title")}
          onBlur={handleSubmit(onSubmit)}
          disabled={isCompleted || parentFetching || isUpdateFetching}
          InputProps={{
            endAdornment: isUpdateFetching ? <LoadingButton loading={true} sx={{ minWidth: "22px", height: "22px" }} /> : ""
          }}
          inputProps={{
            maxLength: 200
          }}
          size="small"
          sx={{ width: "100%" }}
        />
        <Collapse in={isShowTitleLength} timeout="auto" sx={{ position: "absolute", bottom: "-24px", right: "0", transition: ".3s all ease-in-out" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "8px",
              height: "24px",
              // backgroundColor: "whitesmoke",
              color: "#1976d2"
            }}
          >
            {watch("title").trim()?.length} / 200
          </div>
        </Collapse>

        <Divider
          sx={{
            display: isCompleted ? "block" : "none",
            marginLeft: "-5px",
            position: "absolute",
            width: "calc(100% + 10px)",
            top: "50%",
            color: "black"
          }}
        />
      </div>
      {/* 
      <IconButton onClick={handleDeleteIssue} disabled={parentFetching || isDirty}>
        <ClearIcon />
      </IconButton> */}

      <ActivityActions
        duplicateForTomorrow={duplicateForTomorrow}
        duplicateTillWeekEnd={duplicateTillWeekEnd}
        handleDeleteIssue={handleDeleteIssue}
      />
    </div>
  );
};

import { checkToken } from "./checkToken";
import { handleToken } from "./handleToken";
// import { entity_url, notify_url } from "../config/url";
import { currentUserUrls } from "./currentUserUrls";

export const checkCurrentUserTokens = async (current_user, callPlace) => {
  // console.log('checkCurrentUserTokens', callPlace)

  try {

    const urls = currentUserUrls(current_user);
    if (current_user?.user_uuid) {
      let entity_token = checkToken(current_user?.user_uuid, "entity");
      // console.log("entity_token", entity_token);
      if (!entity_token) {
        // console.log("checkInitialState");
        entity_token = await handleToken(current_user, "entity", urls?.entity, true);
      }
      let notify_token = checkToken(current_user?.user_uuid, "notify");
      if (!notify_token) {
        notify_token = await handleToken(current_user, "notify", urls?.notification, true);
      }
      return { entity_token: entity_token, notify_token: notify_token };
    }
  } catch (e) {
    console.log("e", e);
  }
};

import { entity_url, notify_url } from "../config/url";
import { handleToken } from "./handleToken";


export const handleResetWmsTokens = async (current_user) => {
  try {
    // setLoadingReset(true);
    const entity = { url: entity_url, name: "entity" };
    const notify = { url: notify_url, name: "notify" };

    const respTokens = await Promise.all(
      [entity, notify].map(async (item) => await handleToken(current_user, item?.name, item?.url, true))
    );
    // console.log("respTokens", respTokens);
    return respTokens;
  } catch (e) {
    console.log("e", e);
  } finally {
    // setLoadingReset(false);
  }
};

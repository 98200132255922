import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, TextField, ListItem, Collapse, ListItemText, Divider } from "@mui/material";
import { Button, Typography, Alert } from "@mui/material";

import { ListItemAvatar, Avatar } from "@mui/material";
import { CloseButton } from "../CloseButton/CloseButton";
import { FormTextField } from "../FormTextField/FormTextField";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PublicIcon from "@mui/icons-material/Public";

import { openNotify } from "../OpenNotify/openNotify";

import { encryptBackup } from "../../utils/encryptBackup";
import { appRoutes } from "../../utils/constants";

export const BackupCreateForm = ({ callbackClose, uuid, first_name, last_name, isAccountBackup }) => {
  const { all_users, current_user } = useSelector((state) => state.auth);
  const { all_offices } = useSelector((state) => state.office);
  const { all_services } = useSelector((state) => state.settings);
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [showPass, setShowPass] = useState(false);
  const [showPassRepeat, setShowPassRepeat] = useState(false);

  const [successBackup, setSuccessBackup] = useState(false);

  const defaultValues = {
    name: "",
    password: "",
    repeat_password: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required field"),
    password: Yup.string()
      .test("is_min", "At least 4 characters", (val, values) => val?.length >= 4)
      .required("Required field"),
    repeat_password: Yup.string()

      .test("is_pass_equal", "Passwords don't match", (val, values) => val === values?.parent?.password)
      .required("Required field")
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  const {
    watch,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    if (isAccountBackup) {
      reset({ ...defaultValues, name: `${first_name}_${last_name}` });
    } else {
      reset({ ...defaultValues, name: `${first_name}_${last_name}_full_backup` });
    }
  }, [first_name, last_name, isAccountBackup]);

  const fieldLabel = (fieldName) => {
    switch (fieldName) {
      case "name":
        return "Backup name";
      case "password":
        return "Password";
      case "repeat_password":
        return "Repeat password";
    }
  };

  const onSubmit = async (values) => {
    try {
      await downloadFile(values?.password, values?.name);
      setSuccessBackup(true);
      setTimeout(() => {
        callbackClose(false);
        setSuccessBackup(false);
      }, 1500);
    } catch (e) {
      console.log("e", e);
      setSuccessBackup(false);
      openNotify("error", "Error when creating a backup");
    }
  };

  const downloadFile = async (pass, fileName) => {
    const accountToBackup = all_users.slice(0).find((account) => account?.user_uuid === uuid);
    const officesToBackup = all_offices.slice(0).filter((office) => office?.biom_uuid === accountToBackup?.biom?.biom_uuid);

    // !!!!minimum set of fields for account recovery !!!!!
    // account: {
    //   user_uuid: accountToBackup?.user_uuid,
    //   user_priv_key: accountToBackup?.user_priv_key,
    //   biom: { ...accountToBackup?.biom }
    // },
    const data = {
      account: accountToBackup,
      offices: !!officesToBackup?.length && !isAccountBackup ? officesToBackup : [],
      services: !!all_services?.length && !isAccountBackup ? all_services : []
    };
    // console.log("data", data);
    const dataToEncrypt = JSON.stringify(data);
    const encryptedContent = await encryptBackup(dataToEncrypt, pass);
    const file = new Blob([encryptedContent], { type: "text/plain" });
    fileRef.current.href = URL.createObjectURL(file);
    fileRef.current.download = `${fileName}.esx54.txt`;
    fileRef.current.click();
  };

  const handleNavigate = (path) => {
    callbackClose(false);
    navigate(path);
  };

  return (
    <div style={{ padding: "16px 16px", height: "100vh", overflow: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle1">Create {!isAccountBackup ? "a full" : "an account"} backup</Typography>
        <CloseButton handleCallback={callbackClose} />
      </div>
      <Divider sx={{ margin: "0 -16px 16px -16px" }} />
      <Card elevation={0} sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "16px" }}>
        <Alert severity="info" sx={{ alignItems: "center" }} icon={<SaveIcon />}>
          {!isAccountBackup ? (
            <>
              You create <strong>a full backup</strong> containing the:
              <li>data of the selected account,</li>
              <li>all relay offices of its biome,</li>
              <li>services.</li>
            </>
          ) : (
            <>
              Your <strong>account</strong> will be saved locally on your device. The backup can be transferred to your other device.
            </>
          )}
        </Alert>
        <Alert severity="info" sx={{ alignItems: "center" }}>
          {!isAccountBackup ? (
            <>
              Creating an account-only backup is available on the{" "}
              <a onClick={() => handleNavigate(appRoutes?.accounts?.path)} className="link_style">
                Accounts page
              </a>
              .
            </>
          ) : (
            <>
              To create <strong>a full backup</strong> (account + all relay offices of its biome + services) go to the{" "}
              <a onClick={() => handleNavigate(appRoutes?.settings?.path)} className="link_style">
                Settings page
              </a>
              .
            </>
          )}
        </Alert>
        {!isAccountBackup && (
          <>
            <Typography variant="subtitle2" sx={{ marginTop: "16px", paddingLeft: "16px" }}>
              Selected account
            </Typography>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ background: "rgb(25, 118, 210)" }}>
                  {first_name?.[0] || ""}
                  {last_name?.[0] || ""}
                </Avatar>
              </ListItemAvatar>
              <div>
                <div className="modal_accounts_backup_info_wrapper">
                  <AlternateEmailIcon color="neutral" sx={{ marginRight: "6px" }} />
                  <div className="modal_accounts_backup_text_wrapper">{current_user?.email}</div>
                </div>
                <div className="modal_accounts_backup_info_wrapper">
                  <PublicIcon color="neutral" sx={{ marginRight: "6px" }} />
                  <div className="modal_accounts_backup_text_wrapper"> {current_user?.biom?.biom_name}</div>
                </div>
              </div>
            </ListItem>
          </>
        )}

        <FormProvider {...methods}>
          {Object.keys(defaultValues).map((item) => {
            const isPassword = item.includes("password");
            const maxLength = isPassword ? 16 : 64;
            const isVisible = !(
              !!(item === "password" && !showPass && "password") ||
              !!(item === "repeat_password" && !showPassRepeat && "password") ||
              false
            );
            const callbackVisibility = (item === "password" && setShowPass) || (item === "repeat_password" && setShowPassRepeat) || null;
            return (
              <>
                <div style={{ padding: "16px 16px 32px 16px" }}>
                  <FormTextField
                    isAuto={item === "password"}
                    fieldLabel={fieldLabel(item)}
                    fieldName={item}
                    maxLength={maxLength}
                    isVisible={isVisible}
                    callbackVisibility={callbackVisibility}
                    isDisabled={successBackup}
                  />
                </div>

                {item === "name" && (
                  <Alert severity="info" sx={{ alignItems: "center" }} icon={<LockIcon />}>
                    Enter the password for the backup. Password will protect your backup from unauthorized access.
                  </Alert>
                )}
              </>
            );
          })}
        </FormProvider>

        <ListItem>
          <a hidden ref={fileRef}></a>
          <Button fullWidth variant="outlined" onClick={handleSubmit(onSubmit)} disabled={successBackup}>
            Create a backup
          </Button>
        </ListItem>
        <Collapse in={successBackup} timeout="auto" sx={{ transition: ".3s" }}>
          <Alert severity="success">The backup was successfully created</Alert>
        </Collapse>
      </Card>
    </div>
  );
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedUserInfo } from "../../redux/auth";
import { setSelectedOfficeInfo } from "../../redux/office";
import { setShowAllAccounts, setShowAllOffices } from "../../redux/switcher";
import { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, LinearProgress, Divider, Container } from "@mui/material";
import { NavBtnContent } from "./NavBtnContent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import SmsIcon from "@mui/icons-material/Sms";
import TodayIcon from "@mui/icons-material/Today";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import "./index.css";
import { appRoutes } from "../../utils/constants";
import { setCurrentPage } from "../../redux/switcher";
import { NewMessageBlock } from "../../Pages/ChatPage/Blocks/NewMessageBlock";

import { isIOS } from "react-device-detect";

export const FooterNavigation = () => {
  const dispatch = useDispatch();
  const { current_user, loading: authLoading } = useSelector((state) => state.auth);
  const { current_office, loading: officeLoading } = useSelector((state) => state.office);
  const { showAllAccounts, showAllOffices, lockDoorsPage } = useSelector((state) => state.switcher);
  const { all_unread_notify } = useSelector((state) => state.chatNotify);
  const { show_footer_in_chat } = useSelector((state) => state.settings);

  const navigate = useNavigate();
  const location = useLocation();

  const windowInnerHeight = window.innerHeight;

  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;

  // console.log("windowInnerHeight", windowInnerHeight);

  const navArr = [
    {
      path: appRoutes.users.path,
      path2: appRoutes.chat.path,
      title: appRoutes.users.title,
      icon: (
        <div style={{ position: "relative" }}>
          <SmsIcon />
          {!!all_unread_notify?.length && (
            <div
              style={{
                position: "absolute",
                top: "-3px",
                right: all_unread_notify?.length > 100 ? "-6px" : "0px",
                fontSize: "12px",
                fontWeight: "600"
              }}
            >
              {all_unread_notify?.length > 100 ? "99+" : all_unread_notify?.length}
            </div>
          )}
        </div>
      ),
      title2: appRoutes.users.title2,
      icon2: <MeetingRoomIcon />,
      isDisabled: false
    },
    {
      path: appRoutes.planning.path,
      title: appRoutes.planning.title,
      icon: <DateRangeOutlinedIcon />
    },
    {
      path: appRoutes.accounts.path,
      path2: appRoutes.account.path,
      title: appRoutes.accounts.title,
      icon: <PeopleAltIcon />,
      title2: appRoutes.accounts.title2,
      icon2: <PersonIcon />
    },
    {
      path: appRoutes.settings.path,
      title: appRoutes.settings.title,
      icon: <SettingsIcon />
    }
  ].slice(current_user?.user_uuid ? 0 : 2);

  const backButtonsLogic = (route) => {
    if (location.pathname === appRoutes.relays.path) {
      if (showAllOffices) {
        dispatch(setSelectedOfficeInfo(current_office?.user_uuid));
      }
      dispatch(setShowAllOffices(!showAllOffices));
    } else {
      if (showAllAccounts) {
        dispatch(setSelectedUserInfo(current_user?.user_uuid));
      }
      dispatch(setShowAllAccounts(!showAllAccounts));

      // if (!showAllAccounts) {
      //   dispatch(setShowAllAccounts(true));
      // }
    }
  };

  const changeButtonsContent = (route) => {
    if (location.pathname === appRoutes.relays.path) {
      if (lockDoorsPage) {
        dispatch(setShowAllOffices(false));
      } else {
        dispatch(setShowAllOffices(true));
      }
    } else {
      dispatch(setShowAllAccounts(true));
    }
  };

  const handleClickNav = async (route) => {
    dispatch(setCurrentPage(route.slice(1)));
    localStorage.setItem("prevPath", location.pathname);
    localStorage.setItem("currentRoute", route);
    changeButtonsContent(route);
    navigate(route);

    //  else {
    //   backButtonsLogic(route);
    // }
    // if (route === appRoutes.accounts.path) {
    //   setTimeout(() => {
    //     dispatch(setSelectedOfficeInfo(current_office?.service_uuid));
    //   }, 300);
    // }
  };

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/install") localStorage.setItem("prevPath", location.pathname);
  }, []);

  return (
    <div style={{ position: "fixed", bottom: "0", left: "0", width: "100%" }}>
      <Container maxWidth="sm" disableGutters>
        <LinearProgress
          sx={{
            opacity: authLoading || officeLoading ? "1" : "0",
            visibility: authLoading || officeLoading ? "visible" : "hidden",
            transition: "opacity .3s ease-in-out"
          }}
        />

        <Divider />
        <div style={{ background: "white" }}>{location.pathname === "/chat" && <NewMessageBlock />}</div>
        <div style={{ display: "flex", background: "white" }}>
          {(location.pathname !== "/chat" || (location.pathname === "/chat" && show_footer_in_chat)) && (
            <>
              {navArr.map((item, idx) => {
                const checkClassname = location?.pathname === item.path || location?.pathname === item.path2;
                const checkPrevPath = localStorage.getItem("prevPath") === item.path || localStorage.getItem("prevPath") === item.path2;
                return (
                  <Button
                    key={item.path}
                    fullWidth
                    sx={{
                      position: "relative",
                      display: "block",
                      height: isIOS && location.pathname !== "/chat" ? "80px" : "64px",
                      borderRadius: "0 0",
                      color: checkClassname ? "#1976d2" : "gray",
                      transition: ".3s",
                      fontSize: "12px"
                    }}
                    onClick={() => handleClickNav(item.path)}
                    disabled={item?.isDisabled}
                  >
                    <div className="nav_btn_content_wrapper">
                      <div className={(checkClassname && "nav_btn_text") || (checkPrevPath && "nav_btn_active_to_hide") || "nav_btn_text_none"}>
                        <NavBtnContent idx={idx} navArr={navArr} />
                      </div>
                    </div>
                  </Button>
                );
              })}
            </>
          )}
        </div>
        {checkIsTallFooter && <div style={{ height: "36px", backgroundColor: "whitesmoke" }}></div>}
      </Container>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setProjectFilter, setIssueFilter } from "../../../redux/chat";
import { setProjectFilter as setProjectFilterComments, setIssueFilter as setIssueFilterComments } from "../../../redux/comments";

// ------------ validation --------------
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// ------------ component ---------------
import { SubGroup } from "../../Subgroup/Subgroup";
import { ItemComponent } from "../ItemComponent";
import { useForm, FormProvider } from "react-hook-form";
import { DialogTitle, DialogContent, IconButton, Divider } from "@mui/material";

import SearchComponent from "../../../Pages/UsersPage/SearchComponent";

import { NoData, NO_PROJECTS } from "../NoData";

// ------------ icons ------------------
import CloseIcon from "@mui/icons-material/Close";

import { orderBy } from "lodash";

export const ProjectFilter = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { project_filter: chat_project_filter, issue_filter: chat_issue_filter } = useSelector((state) => state.chat);
  const {
    project_filter: comments_project_filter,
    issue_filter: comments_issue_filter,
    is_open_comment_form
  } = useSelector((state) => state.comments);
  const project_filter = is_open_comment_form ? comments_project_filter : chat_project_filter;
  const issue_filter = is_open_comment_form ? comments_issue_filter : chat_issue_filter;
  const { projects, mentioned_projects } = useSelector((state) => state.projects);
  const { issues } = useSelector((state) => state.issues);

  const filterTypeArr = ["project", "issue"];
  const [filterType, setFilterType] = useState(filterTypeArr[0]);

  const [projectsData, setProjectsData] = useState([]);
  // const [issuesData, setIssuesData] = useState([]);

  const [projectSearchFilterTitle, setProjectSearchFilterTitle] = useState("");
  const [issueSearchFilterTitle, setIssueSearchFilterTitle] = useState("");

  // useEffect(() => {
  //   const newData = issues
  //     .filter((issue) => (project_filter?.uuid ? issue?.project_uuid === project_filter?.uuid : issue))
  //     .filter((issue) => (!!issueSearchFilterTitle ? issue?.title.includes(issueSearchFilterTitle) : issue));
  //   setIssuesData(newData);
  // }, [issues?.length, project_filter?.uuid, issueSearchFilterTitle]);

  useEffect(() => {
    const availableProjects = current_user?.basic_filter_project_uuids?.length
      ? mentioned_projects.filter((project) => current_user?.basic_filter_project_uuids.some((uuid) => uuid === project?.uuid))
      : [];
    // console.log("current_user?.basic_filter_project_uuids?.length", current_user?.basic_filter_project_uuids);
    // console.log("mentioned_projects", mentioned_projects);
    const newData = availableProjects.filter((project) =>
      !!projectSearchFilterTitle ? project?.title.includes(projectSearchFilterTitle) : project?.uuid
    );
    // console.log("newData", newData);
    const finalData = orderBy(newData, ["partition", "id"], ["asc", "asc"]);
    setProjectsData(finalData);
  }, [mentioned_projects?.length, projectSearchFilterTitle]);

  const defaultValues = {
    project: project_filter || "default",
    issueType: "default",
    vector: "default"
  };

  const validationSchema = yup.object({
    project: yup.string().required("Required"),
    issueType: yup.string().test("check_activity", "Required", (val) => val !== "default"),
    vector: yup.string()
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    mode: "onBlur",
    defaultValues: {
      ...defaultValues
    }
  });

  const { setValue } = methods;
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSelectProj = (project) => {
    if (project?.uuid !== project_filter?.uuid) {
      // console.log("variant", variant);
      // console.log("project_filter", project_filter);
      // const checkCurrentIssueFilter = issue_filter?.project_uuid ? issue_filter?.project_uuid === proj?.uuid : true;
      // if (!checkCurrentIssueFilter) {
      //   dispatch(setIssueFilter(null));
      // }

      if (is_open_comment_form) {
        dispatch(setIssueFilterComments(null));
        dispatch(setProjectFilterComments(project));
      } else {
        dispatch(setIssueFilter(null));
        dispatch(setProjectFilter(project));
      }
      // setValue("project", proj, { shouldDirty: true, shouldValidate: true });
    } else {
      // console.log("else");
      // setValue("project", "default", { shouldDirty: true, shouldValidate: true });
      if (is_open_comment_form) {
        dispatch(setProjectFilterComments(null));
      } else {
        dispatch(setProjectFilter(null));
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <DialogTitle sx={{ padding: "16px 0 0 0" }}>
          <div style={{ padding: "0 16px 0 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>Filter by project</div>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ transition: "all 0.5s ease-in-out" }}>
          <div
            style={{
              padding: project_filter?.uuid && filterType === filterTypeArr[1] ? "12px 0 32px 0" : "12px 0 0 0",
              width: "100%",
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: "10"
            }}
          >
            <SearchComponent
              fullWidth={true}
              variant={"outlined"}
              filterTitle={projectSearchFilterTitle}
              onFilterTitle={setProjectSearchFilterTitle}
            />
            <Divider sx={{ marginTop: project_filter?.uuid && filterType === filterTypeArr[1] ? "32px" : "12px" }} />
          </div>
          <div style={{ height: "12px" }}></div>
          {projectsData?.length ? (
            projectsData.map((proj, idx) => {
              const checkIsNewPartition = projectsData[idx - 1]?.partition !== proj?.partition;
              // if (checkIsNewPartition) {
              //   console.log(`current proj ${idx}`, proj?.partition);
              //   console.log(`prev proj ${idx - 1}`, projectsData[idx - 1]?.partition);
              // }

              return (
                <div key={proj?.uuid}>
                  {checkIsNewPartition && (
                    <SubGroup
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="ellipsys_title" style={{ maxWidth: "200px", fontWeight: "600" }}>
                            {proj?.partition === "PM" ? "General projects" : "Personal projects"}
                          </div>
                        </div>
                      }
                    />
                  )}
                  <ItemComponent
                    icon={proj?.id || `P_${idx}`}
                    isSelected={(proj?.uuid || proj) === project_filter?.uuid}
                    label={proj?.title || `proj_${idx}`}
                    val={proj}
                    callback={handleSelectProj}
                  />
                </div>
              );
            })
          ) : (
            <NoData type={NO_PROJECTS} />
          )}
          {/* <div style={{ height: "100vh" }}></div> */}
        </DialogContent>
      </FormProvider>
    </>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import "./index.css";
import { setSwIsInit, setSwIsUpdated, setSwReg } from "./redux/notify";
import dayjs from "dayjs";
import { injectStore } from "./Axios/API_AXIOS";
injectStore(store);

const isBetween = require("dayjs/plugin/isBetween");
const updateLocale = require("dayjs/plugin/updateLocale");
const localeData = require("dayjs/plugin/localeData");

dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(localeData);

dayjs.updateLocale("en", {
  weekStart: 1
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register({
  onSuccess: (reg) => {
    store.dispatch(setSwReg(reg));
    store.dispatch(setSwIsInit(true));
  },
  onUpdate: (reg) => {
    store.dispatch(setSwIsUpdated(true));
    store.dispatch(setSwReg(reg));
  }
});

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: "",
  reg_data: null
};

export const scanerSlice = createSlice({
  name: "scaner",
  initialState: {
    ...initialState
  },
  reducers: {
    setData: (state, action) => {
      try {
        state.data = action.payload;
      } catch (e) {}
    },
    setRegData: (state, action) => {
      // console.log("setRegData", action.payload);
      state.reg_data = action.payload;
    }
  }
});

export const { setData, setRegData } = scanerSlice.actions;

export default scanerSlice.reducer;

export const handleAssignedEntityData = (entity_type, entity_data) => {
  if (entity_type === "issue") {
    const data = {
      partition: entity_data?.partition,
      assigned_entity_id: entity_data?.id,
      assigned_entity_title: entity_data?.title,
      assigned_entity_type: entity_type,
      assigned_entity_uuid: entity_data?.uuid,
      entity_type: entity_type,
      projectUUID: entity_data?.project_data?.uuid,
      project_id: entity_data?.project_data?.id,
      project_title: entity_data?.project_data?.title,
      project_uuid: entity_data?.project_data?.uuid,
      uuid: entity_data?.uuid
    };
    return data;
  } else {
    if (entity_type === "project") {
      const data = {
        partition: entity_data?.partition,
        assigned_entity_id: entity_data?.id,
        assigned_entity_title: entity_data?.title,
        assigned_entity_type: entity_type,
        assigned_entity_uuid: entity_data?.uuid,
        entity_type: entity_type,
        projectUUID: entity_data?.uuid,
        project_id: entity_data?.id,
        project_title: entity_data?.title,
        project_uuid: entity_data?.uuid,
        uuid: entity_data?.uuid
      };
      return data;
    }
  }
};

import { store } from "../redux/store";
import { setIsPushSubscribe } from "../redux/settings";

export const checkSubscription = async () => {
  // console.log("checkSubscription", navigator.serviceWorker);
  const dispatch = store.dispatch;
  try {
    if (navigator.serviceWorker) {
      // console.log("navigator.serviceWorker");
      return await navigator.serviceWorker.ready
        .then(async (registration) => {
          return await registration.pushManager
            .getSubscription()
            .then(function (subscription) {
              // console.log("checkSubscription 1", subscription);
              // console.log("checkSubscription 1.1", JSON.stringify(subscription));
              const checkIsEmptySubscr = JSON.stringify(subscription)?.length > 2;
              if (!!subscription && checkIsEmptySubscr) {
                // console.log("checkSubscription 2", subscription);
                dispatch(setIsPushSubscribe(true));
                return subscription;
              } else {
                dispatch(setIsPushSubscribe(false));
                console.log("no subscription");
                return false;
              }
            })
            .catch(function (error) {
              console.log("Error unsubscribing", error);
              return false;
            });
          // console.log("serviceWorker.ready");
          // return await Notification.requestPermission().then(
          //   async () =>

          // );
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } else {
      console.log("no serviceWorker");
      return false;
    }
  } catch (e) {
    console.log("error, no serviceWorker");
  }
};

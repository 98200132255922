export const weekPlanningActions = {
  create: "create",
  update: "update",
  delete: "delete",
  duplicate: "duplicate"
};

// export const weekPlanningActions = {
//   create: {

//   },
//   update: {

//   },
//   delete: {

//   },
//   duplicate: {

//   },
// }

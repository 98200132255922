import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setSelectedUserInfo, setCurrentUserByUUID } from "../../../redux/auth";
import { resetPartitions } from "../../../redux/settings";
import { setSelectedDayUuid } from "../../../redux/weekPlanning";
import { Divider, Chip, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Card, CardContent, CardActions, ListItemButton, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import BadgeIcon from "@mui/icons-material/Badge";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import "./index.css";
import { scannerType } from "../../../utils/constants";
import { openNotify } from "../../../components/OpenNotify/openNotify";
import { appRoutes } from "../../../utils/constants";

import { initIsPushAvailableCheck } from "../../../serviceWorkerUtils/initIsPushAvailableCheck";
import { handleUnsubscribe } from "../../../serviceWorkerUtils/handleUnsubscribeForPush";
import { checkCurrentUserTokens } from "../../../utils/checkCurrentUserTokens";
import { currentUserUrls } from "../../../utils/currentUserUrls";

export const AccountCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { current_user } = useSelector((state) => state.auth);

  const { first_name, last_name, email, user_uuid, handleShowAllAccounts, handleOpenSignInDialog, biom, actor_type, selectedUserData } = props;
  const { is_push_subscribe } = useSelector((state) => state.settings);
  const [fetching, setFetching] = useState(false);

  const checkIsActive = current_user?.user_uuid === user_uuid;
  const checkIsClassicUser = actor_type === "classic_user";

  const handleAccountToShow = () => {
    dispatch(setSelectedUserInfo(user_uuid));
    handleShowAllAccounts(user_uuid);
    navigate(appRoutes.account.path);
  };

  const handleBtnClick = () => {
    if (checkIsActive) {
      handleSignIn();
    } else {
      handleMakeActive();
    }
  };

  const handleSignIn = () => {
    if (!checkIsClassicUser) {
      dispatch(setSelectedUserInfo(user_uuid));
      handleOpenSignInDialog(scannerType.sign_in);
    }
  };

  const unsubscribeOldUser = async () => {
    const checkIsAvailable = initIsPushAvailableCheck();
    if (is_push_subscribe && checkIsAvailable) {
      // console.log("current_user", current_user);
      const tokens = await checkCurrentUserTokens(current_user);
      const urls = currentUserUrls(current_user);
      return await handleUnsubscribe(tokens?.notify_token, false, true, urls?.notification);
    }
  };

  const handleMakeActive = async () => {
    try {
      setFetching(true);
      dispatch(resetPartitions());
      dispatch(setSelectedDayUuid(null));
      dispatch(setCurrentUserByUUID(user_uuid));
      await unsubscribeOldUser().then(() => {
        dispatch(setCurrentUserByUUID(user_uuid));
        openNotify("success", `Account ${first_name} ${last_name} (${email}) is selected as active`);
      });
    } catch (e) {
    } finally {
      setFetching(false);
    }
  };

  const textCss = checkIsActive ? "info_field_name" : "info_field_name not_active";
  // const textCss = "info_field_name";

  return (
    <Card
      elevation={0}
      sx={{
        marginBottom: "12px",
        position: "relative",
        border: `1px solid ${checkIsActive ? "#1976d2" : "gray"}`,
        // border: `1px solid #1976d2`,

        borderRadius: "16px",
        // "& .MuiCard-root": {
        //   marginBottom: "8px"
        // },
        "& .MuiCardContent-root": { padding: "2px 16px" }
      }}
    >
      <CardContent sx={{ paddingBottom: "6px" }}>
        <div
          className="info_field_wrapper"
          style={{
            marginTop: props?.is_admin || props?.root ? "28px" : "0",
            // marginTop: "28px",
            display: "flex",
            alignItems: "center",
            height: "24px"
          }}
        >
          <div className={textCss} style={{ fontSize: "16px" }}>
            {first_name} {last_name}
          </div>
          {props?.root && <Chip label={<strong>Root</strong>} color="primary" size="small" sx={{ position: "absolute", top: "4px", left: "16px" }} />}
          {props?.is_admin && (
            <Chip
              label={<strong>Admin</strong>}
              color="info"
              size="small"
              sx={{ position: "absolute", top: "4px", left: props?.root ? "64px" : "16px" }}
            />
          )}
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>{email}</div>
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>Account type</div> {checkIsClassicUser ? "Classic user" : "Ecosystem54"}
        </div>
        <div className="info_field_wrapper">
          <div className={textCss}>Biom name</div> {biom?.biom_name || biom?.name || <> &ndash;</>}
        </div>
      </CardContent>
      <Divider />
      <div className="acc_card_btn_wrapper">
        <LoadingButton
          loading={fetching}
          variant={checkIsActive ? "contained" : "outlined"}
          size="round"
          onClick={handleBtnClick}
          disabled={checkIsActive ? checkIsClassicUser : false}
        >
          {checkIsActive ? <QrCodeScannerIcon /> : <BookmarkIcon />}
        </LoadingButton>
      </div>
      <ListItem
        disablePadding
        secondaryAction={<ChevronRightIcon color={"primary"} sx={{ position: "relative", top: "2px" }} />}
        onClick={handleAccountToShow}
      >
        <ListItemButton sx={{ padding: "4px 16px" }}>
          <ListItemIcon>
            <BadgeIcon color={checkIsActive ? "primary" : "neutral"} />
            {/* <BadgeIcon color={"primary"} /> */}
          </ListItemIcon>
          <ListItemText sx={{ position: "relative", top: "2px", color: checkIsActive ? "#1976d2" : "gray", fontWeight: "600" }}>
            Open dashboard
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Card>
  );
};

import { store } from "../redux/store";
import { sendPushSubscription } from "../redux/pushSubscription";
import { setPushSubscription } from "../redux/settings";
import { openNotify } from "../components/OpenNotify/openNotify";

const sendSubscriptionToServer = async (subscription, notify_token, url) => {
  const dispatch = store.dispatch;
  const data = { url: url, token: notify_token, subscription_data: subscription };
  // console.log("sendSubscriptionToServer >>>>>>>>>>>>>>>>", data);
  return await dispatch(sendPushSubscription(data)).unwrap();
};

export const updateSubscriptionOnServer = async (subscription, notify_token, without_notify, url) => {
  // console.log(`updateSubscriptionOnServer url`, url);
  const dispatch = store.dispatch;
  // console.log("updateSubscriptionOnServer", subscription);
  const is_subscribe_now = typeof subscription === "string" ? true : !!Object.keys(subscription)?.length;
  // alert(`updateSubscriptionOnServer ${!!Object.keys(subscription)?.length}`);
  dispatch(setPushSubscription(subscription));
  if (!without_notify) {
    if (is_subscribe_now) {
      openNotify("success", "Subscribed to push notifications");
      // localStorage.setItem(`${current_user?.user_uuid}_push`, JSON.stringify(subscription));
    } else {
      openNotify("success", "Unsubscribed from push notifications");
      // localStorage.removeItem(`${current_user?.user_uuid}_push`);
    }
  }

  return await sendSubscriptionToServer(typeof subscription === "string" ? JSON.parse(subscription) : subscription, notify_token, url);
};

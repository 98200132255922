import React from "react";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedDay, setPeriodSelectedDay } from "../../redux/weekPlanning";
import { setIsTokenError } from "../../redux/notify";
import { getMentionedProjects } from "../../redux/projects";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { Card, CardHeader, CardContent, Divider, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isIOS } from "react-device-detect";
import { appRoutes } from "../../utils/constants";
import { entity_url, notify_url } from "../../config/url";
import { checkToken } from "../../utils/checkToken";
import { handleToken } from "../../utils/handleToken";
import { PageLoadingComponent } from "../PageLoadingComponent/PageLoadingComponent";
import { WrongAccountBiom } from "../WrongAccountBiom/WrongAccountBiom";

import dayjs from "dayjs";

export const PageWrapper = ({ page }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { current_user } = useSelector((state) => state.auth);
  const { selected_day, period_selected_day, loading } = useSelector((state) => state.weekPlanning);
  const { isTokenError } = useSelector((state) => state.notify);
  const { replyData, newMessageBlockHeight } = useSelector((state) => state.chat);
  const { show_footer_in_chat } = useSelector((state) => state.settings);
  const { is_open_comment_form } = useSelector((state) => state.comments);
  // const { newMessageBlockHeight: newCommentBlockHeight } = useSelector((state) => state.comments);
  const iosBlockHeight = newMessageBlockHeight ? 36 : 0;
  const windowInnerHeight = !isIOS ? window.innerHeight : document.documentElement.scrollHeight + iosBlockHeight;
  const checkIsTallFooter = windowInnerHeight > 680 && isIOS;
  const isChat = location.pathname === appRoutes.chat.path;
  const isPlanning = location.pathname === appRoutes.planning.path;

  const [leftSwipe, setLeftSwipe] = useState(false);
  const [rightSwipe, setRightSwipe] = useState(false);
  const [animationState, setAnimationState] = useState(false);
  const [wrongAccountBiom, setWrongAccountBiom] = useState(false);

  const wrapperRef = useRef(null);

  const handleUpdateTokens = async () => {
    let isAlreadyToken = true;
    if (current_user?.user_uuid) {
      const entity_token = checkToken(current_user?.user_uuid, "entity");
      // console.log("entity_token", entity_token);
      if (!entity_token) {
        isAlreadyToken = false;
        await handleToken(current_user, "entity", entity_url, true);
      }
      let notify_token = checkToken(current_user?.user_uuid, "notify");
      if (!notify_token) {
        isAlreadyToken = false;
        notify_token = await handleToken(current_user, "notify", notify_url, true);
      }
      if (!isAlreadyToken) {
        dispatch(setIsTokenError(false));
      } else {
        setWrongAccountBiom(true);
      }
    }
  };

  useEffect(() => {
    if (isTokenError) {
      // alert("isTokenError");
      handleUpdateTokens();
    }
  }, [isTokenError]);

  // const [touchStart, setTouchStart] = useState(null);
  // const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 100;

  // const onTouchStart = (e) => {
  //   if (isPlanning) {
  //     setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
  //     setTouchStart(e.targetTouches[0].clientX);
  //   }
  // };

  // const onTouchMove = (e) => {
  //   if (isPlanning) {
  //     setTouchEnd(e.targetTouches[0].clientX);
  //   }
  // };

  // const onTouchEnd = () => {
  //   const checkIsPreviousSwipeEnd = !loading && !leftSwipe && !rightSwipe;
  //   if (isPlanning && checkIsPreviousSwipeEnd) {
  //     const today = dayjs();
  //     if (!touchStart || !touchEnd) return;
  //     const distance = touchStart - touchEnd;
  //     const isLeftSwipe = distance > minSwipeDistance;
  //     const isRightSwipe = distance < -minSwipeDistance;
  //     let newDate;
  //     if (isLeftSwipe) {
  //       newDate = selected_day ? selected_day.add(1, "day") : today.add(1, "day");
  //       // console.log(newDate.format("DD-MM-YYYY"));
  //       dispatch(setSelectedDay(dayjs(newDate)));
  //       setLeftSwipe(true);
  //     }
  //     if (isRightSwipe) {
  //       newDate = selected_day ? selected_day.subtract(1, "day") : today.subtract(1, "day");
  //       // console.log(newDate.format("DD-MM-YYYY"));
  //       dispatch(setSelectedDay(dayjs(newDate)));
  //       setRightSwipe(true);
  //     }
  //     // add your conditional logic here
  //     // console.log("period_selected_day", period_selected_day);
  //     // console.log("newDate", newDate);
  //     if (period_selected_day) {
  //       const checkIsWeekNotChange = (newDate || dayjs()).isBetween(period_selected_day.day(1), period_selected_day.day(7));

  //       if (!checkIsWeekNotChange) {
  //         const check = (newDate || dayjs()).day() !== 0;
  //         dispatch(setPeriodSelectedDay(check ? newDate : dayjs().date(newDate.date() - 1)));
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if ((leftSwipe || rightSwipe) && isPlanning && !loading) {
      setTimeout(() => {
        setAnimationState(true);
      }, 240);
      setTimeout(() => {
        setLeftSwipe(false);
        setRightSwipe(false);
      }, 300);
      setTimeout(() => {
        setAnimationState(false);
      }, 600);
    }
  }, [leftSwipe, rightSwipe]);

  const isChatCssHeight = isChat
    ? `${isIOS ? "+ 31px" : "+ 16px"} ${replyData ? "- 79px" : ""} ${newMessageBlockHeight ? `- ${newMessageBlockHeight}px` : ""} ${
        show_footer_in_chat ? "- 64px" : ""
      }`
    : "";
  const tallFooterCss = `${checkIsTallFooter ? (location.pathname === "/chat" ? "- 37px" : "- 53px") : ""}`;

  // useEffect(() => {
  //   console.log(`calc(${windowInnerHeight}px - 137px ${tallFooterCss} ${isChatCssHeight} ${isIOS ? "- 16px" : ""})`);
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  // }, [isChatCssHeight, tallFooterCss, windowInnerHeight, isIOS]);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          marginTop: "57px",
          position: "relative",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: !isChat ? "16px" : "0"
          },
          overflow: !animationState ? "auto !important" : "hidden"
        }}
      >
        <CardContent
          ref={wrapperRef}
          id="scrollableDiv"
          className="main_page_wrapper"
          // onTouchStart={onTouchStart}
          // onTouchMove={onTouchMove}
          // onTouchEnd={onTouchEnd}
          sx={{
            display: location?.pathname !== "/chat" ? "block" : "flex",
            flexDirection: "column-reverse",
            padding: "0 16px 0 16px",
            overflow: animationState ? "hidden" : "auto !important",
            height: `calc(${windowInnerHeight}px - 139px ${tallFooterCss} ${isChatCssHeight} ${isIOS ? "- 16px" : ""})`
          }}
        >
          {!isTokenError ? page : !wrongAccountBiom ? <PageLoadingComponent /> : <WrongAccountBiom setWrongAccountBiom={setWrongAccountBiom} />}
          {/* {!isTokenError ? (
            isPlanning ? (
              <>
                {animationState && <PageLoadingComponent />}
                <div
                  style={{
                    position: !animationState ? "relative" : "absolute",
                    top: "0",
                    left: !animationState ? (leftSwipe && "-120%") || (rightSwipe && "120%") || "0" : "0",
                    // width: "calc(100% - 16px)",
                    display: "flex",
                    opacity: !animationState ? "1" : "0",
                    transition: "all .3s ease-in-out",
                    overflow: !animationState ? "auto !important" : "hidden"
                  }}
                >
                  {page}
                </div>
              </>
            ) : (
              <>{page}</>
            )
          ) : (
            <>{!wrongAccountBiom ? <PageLoadingComponent /> : <WrongAccountBiom setWrongAccountBiom={setWrongAccountBiom} />}</>
          )} */}
        </CardContent>
      </Card>
    </>
  );
};

import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ControlHeader } from "./ControlHeader";
import { DayCard } from "../UsersPage/WeekPlanningForm/DayCard";
import { PageLoadingComponent } from "../../components/PageLoadingComponent/PageLoadingComponent";
import { CircularProgress, Typography } from "@mui/material";
import FastForwardIcon from "@mui/icons-material/FastForward";
import dayjs from "dayjs";

import { checkToken } from "../../utils/checkToken";
import { front_date_with_time } from "../../utils/constants";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/virtual";
import "./index.css";

export const Page = ({ initialSlideNumber, setSwiper, handleSwipe, endSlidesOptions, fetching, handleWeekPlanningSync }) => {
  // const dispatch = useDispatch();
  const { selected_day, period_days_data, current_week_day_options } = useSelector((state) => state.weekPlanning);

  // const { current_user } = useSelector((state) => state.auth);
  // const { pub_partition, week_planning_unique_uuid } = useSelector((state) => state.settings);
  const { openPeriodModal } = useSelector((state) => state.weekPlanning);

  // useEffect(() => {
  //   console.log("period_days_data", period_days_data);
  // }, [period_days_data]);

  return (
    <div style={{ marginTop: "16px", width: "100%" }}>
      <ControlHeader fetching={fetching} />
      {!!period_days_data?.length ? (
        <Swiper
          modules={[Navigation, Virtual]}
          spaceBetween={50}
          slidesPerView={1}
          speed={200}
          initialSlide={initialSlideNumber}
          onSwiper={(inst) => setSwiper(inst)}
          onSlideChange={(e) => (!openPeriodModal ? handleSwipe(e) : "")}
          preventInteractionOnTransition={true}
          observer={true}
          navigation={{
            prevEl: "#swiper-button-prev",
            nextEl: "#swiper-button-next"
          }}
        >
          <SwiperSlide key={"prev week"}>
            <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <FastForwardIcon color="primary" sx={{ marginRight: "6px", transform: "rotate(180deg)" }} />{" "}
              <Typography variant="subtitle2">
                Previous week <br /> ({!!endSlidesOptions ? `${endSlidesOptions?.prevWeekEnd}` : ""})
              </Typography>
            </div>
          </SwiperSlide>
          {period_days_data.map((slideContent, index) => (
            <SwiperSlide key={slideContent?.uuid} virtualIndex={index}>
              <DayCard currentDayData={slideContent} handleWeekPlanningSync={handleWeekPlanningSync} />
            </SwiperSlide>
          ))}
          <SwiperSlide key={"next week"}>
            <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Typography variant="subtitle2">
                Next week <br /> ({!!endSlidesOptions ? `${endSlidesOptions?.nextWeekStart}` : ""})
              </Typography>{" "}
              <FastForwardIcon color="primary" sx={{ marginLeft: "6px" }} />
            </div>
          </SwiperSlide>
        </Swiper>
      ) : (
        <DayCard currentDayData={current_week_day_options} onlyFetching={true} />
      )}
    </div>
  );
};

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProjectFilter, setIssueFilter, setAssingedEntityType } from "../../redux/chat";
import { setProjectFilter as setProjectFilterComments, setIssueFilter as setIssueFilterComments } from "../../redux/comments";

// ------------ component --------------
import { ProjectFilter } from "../ChatFiltersDialog/FilteBlocks/ProjectFilter";
import { IssueFilter } from "../ChatFiltersDialog/FilteBlocks/IssueFilter";
import { ListItem, IconButton, Button } from "@mui/material";
import { EntityTitleComponent } from "../EntityTitleComponent/EntityTitleComponent";

// ------------ icons -------------------
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FilterListIcon from "@mui/icons-material/FilterList";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";

// ------------ const -------------------
import { assignedEntityTypes } from "../../redux/chat";

export const newMessageModalTypes = {
  project: "project",
  // entityType: "entityType",
  issue: "issue"
};

export const ModalBlock = ({ type, setCurrentContent, handleOpenModal }) => {
  const dispatch = useDispatch();

  const { project_filter: chat_project_filter, issue_filter: chat_issue_filter, search_filter } = useSelector((state) => state.chat);
  const {
    project_filter: comments_project_filter,
    issue_filter: comments_issue_filter,
    is_open_comment_form
  } = useSelector((state) => state.comments);
  const project_filter = is_open_comment_form ? comments_project_filter : chat_project_filter;
  const issue_filter = is_open_comment_form ? comments_issue_filter : chat_issue_filter;
  // const handleAssignedTypeIcon = (assigned_entity_type) =>{
  //   switch (assigned_entity_type) {
  //     case
  //   }
  // }

  const projectOptions = {
    filter: project_filter,
    filter_uuid: project_filter?.uuid,
    setFilter: () => (is_open_comment_form ? dispatch(setProjectFilterComments(null)) : dispatch(setProjectFilter(null))),
    icon: <AccountTreeIcon />,
    defaultText: "Select a project",
    modalContent: <ProjectFilter setOpen={handleOpenModal} />
  };

  // const entityTypeOptions = {
  //   filter: assigned_entity_type,
  //   filter_uuid: assigned_entity_type,
  //   setFilter: () => dispatch(setAssingedEntityType(null)),
  //   icon: !assigned_entity_type ? <FilterListIcon /> : ,
  //   defaultText: "Select the type of entity",
  //   modalContent: <ProjectFilter setOpen={handleOpenModal} />
  // };

  const issueOptions = {
    filter: issue_filter,
    filter_uuid: issue_filter?.uuid,
    setFilter: () => (is_open_comment_form ? dispatch(setIssueFilterComments(null)) : dispatch(setIssueFilter(null))),
    icon: <AssignmentIcon />,
    defaultText: "Select an issue",
    modalContent: <IssueFilter setOpen={handleOpenModal} />
  };

  const [currentOptions, setCurrentOptions] = useState(projectOptions);

  const handleOpenFilterModal = () => {
    setCurrentContent(currentOptions?.modalContent);
    handleOpenModal(true);
  };

  const handleCurrentOptions = () => {
    switch (type) {
      case newMessageModalTypes.project: {
        setCurrentOptions(projectOptions);
        break;
      }
      case newMessageModalTypes.issue: {
        setCurrentOptions(issueOptions);
        break;
      }
    }
  };

  const handleResetFilter = () => {
    dispatch(currentOptions.setFilter(null));
  };

  useEffect(() => {
    handleCurrentOptions();
  }, [type, project_filter?.uuid, issue_filter?.uuid]);

  // useEffect(() => {
  //   console.log("comments_project_filter, comments_issue_filter", comments_project_filter, comments_issue_filter);
  // }, [comments_project_filter, comments_issue_filter]);

  return (
    <ListItem
      secondaryAction={
        currentOptions?.filter_uuid ? (
          <Button sx={{ padding: "0 0", minWidth: "40px", height: "40px", borderRadius: "20px" }} onClick={handleResetFilter} variant="outlined">
            <CloseIcon />
          </Button>
        ) : (
          ""
        )
      }
    >
      <Button
        startIcon={currentOptions?.icon}
        variant={currentOptions?.filter_uuid ? "contained" : "outlined"}
        onClick={handleOpenFilterModal}
        sx={{
          width: currentOptions?.filter_uuid ? "calc(100% - 16px)" : "100%",
          height: "40px"
        }}
      >
        {currentOptions?.filter_uuid ? (
          <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
            {currentOptions?.filter?.id} {currentOptions?.filter?.title}
          </div>
        ) : (
          currentOptions?.defaultText
        )}
      </Button>
    </ListItem>
  );
};

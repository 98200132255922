import { useState } from "react";
import { Card, CardHeader, CardContent, Typography, Collapse } from "@mui/material";
import { ExpandTitle } from "../../../components/ExpandTitle/ExpandTitle";
import dayjs from "dayjs";
import { createAtTimeParse } from "../../../utils/createAtTimeParse";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const InfoBlock = (props) => {
  const { user_uuid, created, biom, last_update, actor_type } = props;
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const checkIsClassicUser = actor_type === "classic_user";

  return (
    <Card
      elevation={0}
      sx={{
        overflow: "visible",
        background: "rgba(0, 0, 0, 0.06)",
        "& .MuiCardHeader-root": {
          padding: "8px 16px"
        }
      }}
    >
      <CardHeader title={<Typography variant="title1">Account information</Typography>} />
      <CardContent sx={{ padding: "0px 16px" }}>
        <div className="info_field_wrapper">
          <div className="info_field_name">Account type</div> {checkIsClassicUser ? "Classic user" : "Ecosystem54"}
        </div>
        <div className="info_field_wrapper">
          <div className="info_field_name">Biom name</div> {biom?.biom_name || biom?.name}
        </div>
        {/* <div className="info_field_wrapper">
          <div className="info_field_name">Comment</div> None/StandAlone
        </div> */}
        <div style={{ margin: "16px 0 0 0" }}>
          <ExpandTitle label={"More info"} labelHide={"Hide info"} isOpen={showMore} handleShowMore={handleShowMore} />
        </div>
        <Collapse in={showMore} timeout="auto" sx={{ transition: ".3s" }}>
          <div className="info_field_wrapper">
            <div className="info_field_name">UUID</div>
            <div>{user_uuid}</div>
          </div>
          <div className="info_field_wrapper">
            <div className="info_field_name">Created at</div>
            <div>{createAtTimeParse(created)}</div>
          </div>
          <div className="info_field_wrapper">
            <div className="info_field_name">Last update</div>
            <div>{last_update || "-"}</div>
          </div>
        </Collapse>
      </CardContent>
    </Card>
  );
};

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendNewMessage, getChatUserMessages } from "../../../redux/chat";
import { Divider, TextField, IconButton, Chip, Typography, DialogActions } from "@mui/material";
import { createEntityAffix } from "../../../redux/chat";
import { setNewMessageBlockHeight } from "../../../redux/comments";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { checkToken } from "../../../utils/checkToken";
import { MentionsInput, Mention } from "react-mentions";
import { handleAssignedEntityData } from "../../../utils/handleAssignedEntityData";
import "./index.css";

export const NewCommentBlock = ({ initCommentsFunction }) => {
  const dispatch = useDispatch();
  const { current_user } = useSelector((state) => state.auth);
  const { pm_partition, pub_partition } = useSelector((state) => state.settings);
  const { current_chat_user } = useSelector((state) => state.users);
  const { current_comments_project_users } = useSelector((state) => state.projects);

  const { project_filter, issue_filter, loading } = useSelector((state) => state.comments);

  const [fetch, setFetch] = useState(false);
  const [val, setVal] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);

  const fieldRef = useRef(null);

  const handleVal = (e) => {
    setVal(e.target.value);
  };

  const updateMessages = async () => {
    const data = {
      token: checkToken(current_user?.user_uuid, "notify"),
      sender_uuid: current_user?.user_uuid
    };

    // dispatch(getChatUserMessages(data));
  };

  const handleClick = (current_uuid) => {
    const selectedUser = assignedUsers?.slice(0).find((user) => user?.uuid === current_uuid);
    // console.log("selectedUser", selectedUser?.label);
    if (selectedUser?.isSelected) {
      setVal((prev) => prev.slice(0).replace(`@${selectedUser?.label} `, ""));
    } else {
      setVal((prev) => prev.slice(0) + `@${selectedUser?.label} `);
    }
    setAssignedUsers((prev) => prev.slice(0).map((user) => (user?.uuid === current_uuid ? { ...user, isSelected: !user?.isSelected } : user)));
  };

  useEffect(() => {
    const finalData = current_comments_project_users.map((user) => ({ ...user, isSelected: false }));
    setAssignedUsers(finalData);
  }, [current_comments_project_users]);

  // {
  //   "entity_uuid": "e74b16f3-0bc6-447d-82f5-637bec1ae2df",
  //   "affix_type": "comment",
  //   "params": {
  //     "system": false,
  //     "comment": "@test_wms test_wms 234",
  //     "entity": "e74b16f3-0bc6-447d-82f5-637bec1ae2df"
  //   }
  // }

  const createEntityComment = async (entity_uuid, partition_name) => {
    const req_data = {
      entity_uuid: entity_uuid,
      affix_type: "comment",
      params: {
        system: false,
        comment: val,
        entity: entity_uuid
      }
    };
    // console.log("createEntityComment", req_data);
    const entity_data = new FormData();
    entity_data.append("data", JSON.stringify(req_data));
    const data_entity = {
      partition_uuid: partition_name === "PUBLIC" ? pub_partition?.header_uuid : pm_partition?.header_uuid,
      token: checkToken(current_user?.user_uuid, "entity"),
      req_data: entity_data
    };
    return await dispatch(createEntityAffix(data_entity))
      .unwrap()
      .then(() => {
        setAssignedUsers((prev) => prev.map((user) => ({ ...user, isSelected: false })));
        return true;
      });
  };

  const createNotificationMessage = async (receivers_uuid_arr, params) => {
    const req_data = {
      event_name: "mention",
      title: "mention",
      message: val,
      params: {
        ...params
      },
      sender: current_user?.user_uuid,
      receivers: receivers_uuid_arr
    };
    // console.log("createNotificationMessage data", req_data);
    const data_notify = {
      partition_uuid: params?.entity?.partition === "PM" ? pm_partition?.header_uuid : pub_partition?.header_uuid,
      token: checkToken(current_user?.user_uuid, "notify"),
      req_data: req_data
    };
    return await dispatch(sendNewMessage(data_notify)).then(() => {
      setVal("");
      updateMessages();
      dispatch(setNewMessageBlockHeight(null));
      return true;
      // dispatch(setReplyData(null));
      // dispatch(setNewMessageBlockHeight(null));
      // dispatch(setIsNeedToScrollToStart(true));
    });
  };

  const onSubmit = async () => {
    // console.log(project_filter, issue_filter);
    try {
      setFetch(true);
      const notifiable_users = assignedUsers?.filter((user) => user?.isSelected);
      const notifiable_users_uuids = notifiable_users.map((user) => user?.uuid);
      const entity_type = issue_filter?.uuid ? "issue" : project_filter?.uuid ? "project" : "";
      const params = { entity: handleAssignedEntityData(entity_type, issue_filter || project_filter) };
      const req_arr = [createEntityComment, createNotificationMessage];

      const resp = await Promise.allSettled(
        req_arr.map(async (req, idx) => {
          if (idx === 1) {
            return await createNotificationMessage(notifiable_users_uuids, params);
          } else {
            return await createEntityComment(params?.entity?.assigned_entity_uuid, params?.entity?.partition);
          }
        })
      );
      // console.log("resp", resp);
      await initCommentsFunction(true);
    } catch (e) {
      console.log("e", e);
    } finally {
      setFetch(false);
    }
  };

  const handleChangeFieldHeight = () => {
    if (!!fieldRef?.current?.offsetHeight) {
      const height = fieldRef?.current?.offsetHeight - 33;
      if (height) {
        dispatch(setNewMessageBlockHeight(height));
      } else {
        dispatch(setNewMessageBlockHeight(null));
      }
    }
  };

  const handleSubmit = (e) => {
    const checkVal = !!e?.target?.value?.trim()?.length;
    if (e.code === "Enter" && checkVal) {
      onSubmit();
    }
    handleChangeFieldHeight();
  };

  const handleOnPaste = (e) => {
    // console.log("handleOnPaste");
    handleChangeFieldHeight();
  };

  useEffect(() => {
    dispatch(setNewMessageBlockHeight(null));
  }, []);

  return (
    <>
      <DialogActions sx={{ display: "block", padding: "0 0" }}>
        <Divider sx={{ width: "100%", marginBottom: "6px" }} />
        <div style={{ padding: "0 8px" }}>
          <Typography variant="subtitle2">
            Notify project members ({assignedUsers?.filter((user) => user?.isSelected)?.length} /{" "}
            {assignedUsers?.filter((user) => user?.uuid !== current_user?.user_uuid).length}):
          </Typography>
        </div>
        <div
          style={{
            marginLeft: "-8px",
            padding: "0 8px",
            height: "80px",
            overflow: "auto",
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center"
          }}
        >
          {assignedUsers
            ?.filter((user) => user?.uuid !== current_user?.user_uuid)
            .map((user) => (
              <Chip
                label={user?.label}
                sx={{ margin: "4px 4px", maxWidth: "150px" }}
                color={user?.isSelected ? "primary" : "default"}
                variant={user?.isSelected ? "contained" : "outlined"}
                onClick={() => handleClick(user?.uuid)}
                key={`${user?.uuid}_chip`}
              />
            ))}
        </div>
        <Divider sx={{ margin: "0 0 6px -16px !important" }} />
        <div style={{ marginLeft: "-8px", padding: "0 16px 6px 16px" }}>
          {" "}
          <TextField
            // onFocus={handleShowKeyboard}
            // onBlur={handleHideKeyboard}
            // onPaste={handleOnPaste}
            // onInput={handleOnInput}
            onPaste={handleOnPaste}
            ref={fieldRef}
            multiline={true}
            maxRows={4}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder="New comment"
            inputProps={{
              maxLength: 400,
              style: {
                fontSize: "20px"
              }
            }}
            onKeyUp={handleSubmit}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <LoadingButton
                  loading={fetch || loading}
                  disabled={!val?.trim() || fetch || loading}
                  sx={{ marginLeft: "6px", minWidth: "24px", padding: "0 0" }}
                  onClick={onSubmit}
                >
                  <SendIcon />
                </LoadingButton>
              )
            }}
            // sx={{ height: "48px" }}
            value={val}
            onChange={handleVal}
          />
        </div>
        <div
          style={{
            marginLeft: "-8px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "8px",
            height: "24px",
            backgroundColor: "whitesmoke",
            color: "#1976d2"
          }}
        >
          {val.trim()?.length} / 400
        </div>{" "}
      </DialogActions>
    </>
  );
};

{
  /* <div className="send_message_block" style={{ border: "1px solid green" }}></div> */
}
{
  /* <MentionsInput value={val} onChange={handleVal} className="mention_input" minLength={1} maxLength={4} placeholder="New comment">
          <Mention
            type="user"
            trigger="@"
            displayTransform={(_id, display) => `@${display}`}
            appendSpaceOnAdd
            data={current_comments_project_users || []}
            allowSuggestionsAboveCursor
          />
        </MentionsInput> */
}

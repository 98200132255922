import { useDispatch, useSelector } from "react-redux";
import { setOpenPeriodModal } from "../../redux/weekPlanning";
import { ModalContent } from "./ModalContent";
import FullScreenDialog from "../../components/FullScreenDialog/FullScreenDialog";

export const Modal = ({
  currentWeekDatesOptions,
  dateButtonsOptions,
  handlePeriodSelectedDate,
  handlePrevWeek,
  handleNextWeek,
  handleSwipeToSlide
}) => {
  const dispatch = useDispatch();
  const { openPeriodModal } = useSelector((state) => state.weekPlanning);
  const handleOpen = (bool) => {
    dispatch(setOpenPeriodModal(bool));
  };
  return (
    <>
      <FullScreenDialog
        open={openPeriodModal}
        setOpen={handleOpen}
        children={
          <ModalContent
            currentWeekDatesOptions={currentWeekDatesOptions}
            dateButtonsOptions={dateButtonsOptions}
            handlePeriodSelectedDate={handlePeriodSelectedDate}
            handlePrevWeek={handlePrevWeek}
            handleNextWeek={handleNextWeek}
            handleSwipeToSlide={handleSwipeToSlide}
          />
        }
      />
    </>
  );
};

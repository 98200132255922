import { useState } from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const ActivityAction = ({ checkIsWeekEnd, callback, icon, text, disabled, isSomeFetching }) => {
  const [loading, setLoading] = useState(false);

  const handleCallback = async () => {
    try {
      setLoading(true);
      await callback();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <MenuItem disabled={disabled} onClick={handleCallback}>
      <ListItemIcon>
        <LoadingButton sx={{ minWidth: "24px", padding: "0 0" }} loading={loading} disabled={isSomeFetching}>
          {icon}
        </LoadingButton>
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};
